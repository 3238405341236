import { rem } from 'polished'
import React from 'react'
import { Box } from 'components/Layout'
import { WidgetSubtitle } from 'components/Widgets/WidgetSubtitle'
import { WidgetTitle } from 'components/Widgets/WidgetTitle'
import { SCHEMA_PROPS } from 'constants/common'
import { SPACE } from 'Theme'

const MAX_CONTENT_WIDTH = rem(752)

interface ItemHeadlineProps {
  headline: string
  subheadline?: string
}

export const ItemHeadline: React.FC<React.PWC<ItemHeadlineProps>> = ({
  headline,
  subheadline,
}) => (
  <Box pb={SPACE.PX_16} mx="auto" maxWidth={MAX_CONTENT_WIDTH}>
    <WidgetTitle textAlign="center" itemProp={SCHEMA_PROPS.NAME}>
      {headline}
    </WidgetTitle>
    {subheadline && (
      <WidgetSubtitle textAlign="center">{subheadline}</WidgetSubtitle>
    )}
  </Box>
)
