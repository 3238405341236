import { rem } from 'polished'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import ReactSlider from 'react-slider'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { COLOR } from 'Theme'
import { useMedia } from 'useMedia'

const THUMB_SIZE = rem(16)
const THUMB_HANDLE_SIZE = rem(32)

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: ${THUMB_SIZE};
`

const Thumb = styled.div`
  height: ${THUMB_SIZE};
  line-height: ${THUMB_SIZE};
  width: ${THUMB_SIZE};
  text-align: center;
  background-color: ${COLOR.PRIMARY_2};
  border-radius: ${THUMB_SIZE};
  border: 2.5px solid ${COLOR.PRIMARY_1};
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ThumbHandle = styled.div`
  width: ${THUMB_HANDLE_SIZE};
  height: ${THUMB_HANDLE_SIZE};
  position: absolute;
`

const Track = styled.div`
  top: 5px;
  bottom: 0;
  height: 6px;
  background: ${(props) =>
    props.index === 1 ? COLOR.PRIMARY_1 : COLOR.PRIMARY_2};
  border-radius: ${THUMB_SIZE};
`
// This function helps soften sliding and ensures that the slider step will always be a fraction of the max value.
export const getSliderStepByMaxValue = (value) =>
  Math.max(Math.ceil(value / 100), 1)

const Slider = ({
  min,
  max,
  defaultValue,
  onChange,
  value,
  minDistance,
  step,
  ...rest
}) => {
  const sliderRef = useRef()
  const media = useMedia()
  // We need this to prevent inaccurate thumb position on resize and mobile design
  useEffect(() => {
    if (media.MOBILE) {
      sliderRef.current?.resize()
    }
  }, [media.MOBILE])
  return (
    <Flex width="100%" {...rest}>
      <StyledSlider
        step={step}
        min={min}
        onChange={onChange}
        defaultValue={defaultValue}
        renderTrack={(props, state) => <Track {...props} index={state.index} />}
        renderThumb={(props) => (
          <Thumb {...props}>
            <ThumbHandle data-test-class={DATA_TEST_CLASS.SLIDER_THUMB} />
          </Thumb>
        )}
        max={max}
        pearling
        minDistance={minDistance}
        value={value}
        ref={sliderRef}
      />
    </Flex>
  )
}

Slider.defaultValue = {
  defaultValue: [0, 100],
  minDistance: 50,
  min: 0,
  max: 100,
  step: 1,
}

Slider.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  minDistance: PropTypes.number,
  step: PropTypes.number,
}

export default Slider
