import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useCouponsSettingsQuery } from '@public/hooks/useCouponsSettingsQuery'
import { Flex, Container } from 'components/Layout'
import { AlphabeticalIndexWidget } from './AlphabeticalIndexWidget'
import { AltExpertsListingWidget } from './AltExpertsListingWidget'
import { AltValuePropositionWidget } from './AltValuePropositionWidget'
import { ArticleHeadingWidget } from './ArticleHeadingWidget'
import { ArticlesWidget } from './ArticlesWidget'
import { BannerWidget } from './BannerWidget'
import { CardNavigationWidget } from './CardNavigationWidget'
import { CategoryHeaderWidget } from './CategoryHeaderWidget'
import { CollectionHeaderWidget } from './CollectionHeaderWidget'
import { CollectionsCarouselWidget } from './CollectionsCarouselWidget'
import { ConciergeWidget } from './ConciergeWidget'
import { DoubleImageWidget } from './DoubleImageWidget'
import { ExpertCollectionsWidget } from './ExpertCollectionsWidget'
import { ExpertsListingWidget } from './ExpertListingWidget'
import { ExpertsWidget } from './ExpertsWidget'
import { FeaturedInWidget } from './FeaturedInWidget'
import { GalleryWidget } from './GalleryWidget'
import { GiftCardWidget } from './GiftCardWidget'
import { HeroCarouselWidget } from './HeroCarouselWidget'
import { HeroMainWidget } from './HeroMainWidget'
import { HeroWidget } from './HeroWidget'
import { InfoCardsWidget } from './InfoCardsWidget'
import { IntroductionWidget } from './IntroductionWidget'
import { MultiCollectionWidget } from './MultiCollectionWidget'
import { ProductsWidget } from './ProductsWidget'
import { QAWidget } from './QAWidget'
import { RichTextWidget } from './RichTextWidget'
import { SectionWidget } from './SectionWidget'
import { SingleImageWidget } from './SingleImageWidget'
import { SpotlightWidget } from './SpotlightWidget'
import { TestimonialsWidget } from './TestimonialsWidget'
import { ValuePropositionWidget } from './ValuePropositionWidget'
import { VideoSectionWidget } from './VideoSectionWidget'
import { VideoWidget } from './VideoWidget'

const withContainer = (Comp, props) => (
  <Container>
    <Comp {...props} />
  </Container>
)

const components = {
  CMSGalleryWidget: GalleryWidget,
  CMSHeroCarouselWidget: HeroCarouselWidget,
  CMSHeroWidget: HeroWidget,
  CMSHeroMainWidget: HeroMainWidget,
  CMSFeaturedInWidget: FeaturedInWidget,
  CMSTestimonialsWidget: TestimonialsWidget,
  CMSSpotlightWidget: SpotlightWidget,
  CMSArticlesWidget: ArticlesWidget,
  CMSRichTextWidget: (props) => withContainer(RichTextWidget, props),
  CMSArticleHeadingWidget: (props) =>
    withContainer(ArticleHeadingWidget, props),
  CMSCategoryHeaderWidget: CategoryHeaderWidget,
  CMSExpertsWidget: (props) => withContainer(ExpertsWidget, props),
  CMSInfoCardsWidget: (props) => withContainer(InfoCardsWidget, props),
  CMSVideoWidget: (props) => withContainer(VideoWidget, props),
  CMSVideoSectionWidget: VideoSectionWidget,
  CMSBannerWidget: BannerWidget,
  CMSSingleImageWidget: (props) => withContainer(SingleImageWidget, props),
  CMSProductsWidget: (props) => withContainer(ProductsWidget, props),
  CMSDoubleImageWidget: (props) => withContainer(DoubleImageWidget, props),
  CMSQAWidget: (props) => withContainer(QAWidget, props),
  CMSGiftCardWidget: (props) => withContainer(GiftCardWidget, props),
  CMSMultiCollectionWidget: MultiCollectionWidget,
  CMSExpertCollectionsWidget: ExpertCollectionsWidget,
  CMSCollectionsCarouselWidget: CollectionsCarouselWidget,
  CMSIntroductionWidget: IntroductionWidget,
  CMSCollectionHeaderWidget: CollectionHeaderWidget,
  CMSValuePropositionWidget: (props) =>
    withContainer(ValuePropositionWidget, props),
  CMSExpertsListingWidget: (props) =>
    withContainer(ExpertsListingWidget, props),
  CMSAlphabeticalIndexWidget: AlphabeticalIndexWidget,
  CMSCardNavigationWidget: CardNavigationWidget,
  CMSAltExpertsListingWidget: AltExpertsListingWidget,
  CMSAltValuePropositionWidget: AltValuePropositionWidget,
  CMSConciergeWidget: ConciergeWidget,
  CMSSectionWidget: SectionWidget,
}

export const WidgetsWrapper = ({ widgets, slug, maxWidgetWidth, ...props }) => {
  const { couponsSettings } = useCouponsSettingsQuery()

  if (!widgets || widgets.length === 0) {
    return null
  }
  const isVideoElement =
    widgets.filter((widget) => {
      const type = get(widget, ['content', 'typename'])
      return [components.CMSVideoWidget, components.CMSVideoSectionWidget].some(
        (widgetItem) => widgetItem === components[type]
      )
    }).length > 0

  return (
    <Flex flex="1" flexDirection="column" {...props}>
      {widgets.map(({ id: widgetId, content }) => {
        const type = get(content, '__typename')
        const CMSWidget = components[type]
        if (!CMSWidget) {
          return null
        }

        return (
          <CMSWidget
            cmsPageSlug={slug}
            key={widgetId}
            widgetId={widgetId}
            maxWidgetWidth={maxWidgetWidth}
            {...content}
            {...(components[type] === components.CMSHeroWidget
              ? { isVideoElement }
              : {})}
            {...(components[type] === components.CMSMultiCollectionWidget
              ? { isSearchFilterParamsMode: props.isSearchFilterParamsMode }
              : {})}
            isCouponDiscountEnabled={couponsSettings?.isDiscountEnabled}
          />
        )
      })}
    </Flex>
  )
}

WidgetsWrapper.propTypes = {
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.object.isRequired,
    })
  ).isRequired,
  slug: PropTypes.string.isRequired,
  maxWidgetWidth: PropTypes.number,
  isSearchFilterParamsMode: PropTypes.bool,
}
