import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_ID, DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { ArticleCard } from 'components/ArticleCard'
import { SHARP_BUTTON_INTENT, SharpButton } from 'components/Button'
import { Container, Flex, Grid } from 'components/Layout'
import { Loader, LOADER_SIZE } from 'components/Loader'
import { ARTICLES_WIDGET_LIMIT } from 'constants/common'
import {
  useSharedOptions,
  setInputToCorrectPosition,
} from 'providers/sharedOptions'
import { COLOR, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { useFetchMoreByWidgetId } from '../hooks'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const Background = styled(Flex)`
  background-color: ${COLOR.PRIMARY_2};
`

export const ArticlesWidget = ({ title, subtitle, cmsPageSlug, widgetId }) => {
  const [widgetInputs, setWidgetInputs] = useSharedOptions()

  const media = useMedia()

  const pageSize =
    media.MOBILE || media.TABLET
      ? ARTICLES_WIDGET_LIMIT.MOBILE
      : ARTICLES_WIDGET_LIMIT.DESKTOP

  const page = get(widgetInputs, [
    'cmsInputs',
    cmsPageSlug,
    widgetId,
    'cmsArticlesInput',
    'input',
    'page',
  ])

  const {
    data,
    fetchNextPage,
    loading: isLoadingMore,
  } = useFetchMoreByWidgetId({
    pageSize,
    currentPage: page,
    variablesFactory: ({ page: newPage, limit }) => ({
      input: {
        widgetId,
        cmsArticlesInput: {
          input: {
            page: newPage,
            limit,
          },
        },
      },
    }),
  })

  const articlesList =
    data?.cmsWidgetById?.content?.cmsArticlesWidgetItems?.data ?? []
  const { nextPage, totalCount } =
    data?.cmsWidgetById?.content?.cmsArticlesWidgetItems?.paginationInfo ?? {}

  const isFindMoreArticlesButton =
    totalCount === articlesList.length || !nextPage

  return (
    <Container px={{ MOBILE: 0, DESKTOP: SPACE.PX_15 }}>
      <Flex my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}>
        {articlesList.length > 0 && (
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            position="relative"
            pt={SPACE.PX_30}
            px={{ TABLET: SPACE.PX_15, DESKTOP: 0 }}
            pb={{ MOBILE: SPACE.PX_30, DESKTOP: SPACE.PX_60 }}
            data-test-class={DATA_TEST_CLASS.ARTICLES_WIDGET}
          >
            <Background
              width={{ MOBILE: '100%', DESKTOP: '75%' }}
              height="100%"
              position="absolute"
              zIndex="-1"
              top={0}
            />
            {(title || subtitle) && (
              <Flex
                alignItems="center"
                flexDirection="column"
                mb={SPACE.PX_40}
                width="100%"
                px={SPACE.PX_15}
              >
                {title && (
                  <WidgetTitle m={0} textAlign="center">
                    {title}
                  </WidgetTitle>
                )}
                {subtitle && (
                  <WidgetSubtitle textAlign="center">{subtitle}</WidgetSubtitle>
                )}
              </Flex>
            )}
            <Grid
              width="100%"
              px={{ MOBILE: SPACE.PX_5, TABLET: 0 }}
              mb={SPACE.PX_40}
              gridGap={SPACE.PX_40}
              gridAutoRows={{ MOBILE: 'auto', TABLET: '1fr' }}
              gridTemplateColumns={{
                MOBILE: '1fr',
                TABLET: '1fr 1fr 1fr',
              }}
            >
              {articlesList.map(
                ({
                  id,
                  article: {
                    cmsPage: { title: articleTitle, slug },
                    previewText,
                    previewAttachment,
                    tag,
                  },
                }) => (
                  <ArticleCard
                    key={id}
                    slug={slug}
                    title={articleTitle}
                    tag={tag}
                    description={previewText}
                    image={previewAttachment}
                  />
                )
              )}
            </Grid>
            {isLoadingMore && (
              <Flex justifyContent="center">
                <Loader size={LOADER_SIZE.S} />
              </Flex>
            )}
            {!isFindMoreArticlesButton && !isLoadingMore && (
              <Flex m="auto">
                <SharpButton
                  isLoading={isLoadingMore}
                  onClick={async () => {
                    await fetchNextPage()
                    setWidgetInputs((oldWidgetsInput) =>
                      setInputToCorrectPosition({
                        oldWidgetsInput,
                        cmsPageSlug,
                        widgetId,
                        input: {
                          cmsArticlesInput: {
                            input: {
                              page: page ? page + 1 : 2,
                              limit: pageSize,
                            },
                          },
                        },
                      })
                    )
                  }}
                  intent={SHARP_BUTTON_INTENT.PRIMARY}
                  data-test-id={DATA_TEST_ID.ARTICLES_WIDGET_SEE_MORE_BTN}
                >
                  See more
                </SharpButton>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Container>
  )
}

ArticlesWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  cmsPageSlug: PropTypes.string.isRequired,
  widgetId: PropTypes.number.isRequired,
}
