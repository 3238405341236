import React from 'react'
import {
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
} from 'styled-system'
import { Text } from 'components/Typography'
import { WIDGET_PLACEMENT } from 'constants/common'
import { FONT_STACK, SPACE } from 'Theme'

interface WidgetSubtitleProps extends TypographyProps, ColorProps, SpaceProps {
  placement?: (typeof WIDGET_PLACEMENT)[keyof typeof WIDGET_PLACEMENT]
}

export const WidgetSubtitle: React.FC<React.PWC<WidgetSubtitleProps>> = ({
  placement = WIDGET_PLACEMENT.SECTION,
  children,
  ...rest
}) => (
  <Text
    fontFamily={FONT_STACK.SERIF}
    as="p"
    {...rest}
    mt={{
      MOBILE: SPACE.PX_8,
      TABLET: placement === WIDGET_PLACEMENT.SECTION ? SPACE.PX_8 : SPACE.PX_12,
    }}
  >
    {children}
  </Text>
)
