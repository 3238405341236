import { rem } from 'polished'
import React from 'react'
import { BUTTON_VARIANT } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { H2, Text } from 'components/Typography'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  LINE_HEIGHT,
  SPACE,
} from 'Theme'

const CONTENT_WIDTH = {
  MOBILE: rem(160),
  TABLET: rem(230),
  DESKTOP: rem(380),
} as const

const CONTENT_HEIGHT = {
  MOBILE: rem(220),
  DESKTOP: rem(320),
} as const

interface ValuePropositionItemProps {
  // We need to support React.ReactNode here because we need to control where the text wrap happens
  // For reference, see the title prop in AltValuePropositionWidget
  title: string | React.ReactNode
  subtitle?: string
  buttonText: string
  link: string
  image: {
    src: string
    alt: string
    baseWidth: number
    baseHeight: number
    aspectRatio: number
    objectPosition?: string | number
  }
  position?: number
}

export const ValuePropositionItem: React.FC<
  React.PWC<ValuePropositionItemProps>
> = ({ title, subtitle, buttonText, link, image, position }) => (
  <article itemScope itemType={SCHEMA_TYPES.LIST_ITEM}>
    <Flex position="relative" width="100%" height={CONTENT_HEIGHT}>
      <Image
        {...image}
        layout={IMAGE_LAYOUT.FILL}
        objectFit="cover"
        itemProp={SCHEMA_PROPS.IMAGE}
      />
      <Flex zIndex={1} width="100%" justifyContent="center" alignItems="center">
        <Flex flexDirection="column" alignItems="center" width={CONTENT_WIDTH}>
          <H2
            fontWeight={FONT_WEIGHT.NORMAL}
            fontSize={{
              MOBILE: FONT_SIZE.PX_24,
              DESKTOP: FONT_SIZE.PX_36,
            }}
            mb={SPACE.PX_8}
            fontFamily={FONT_STACK.SERIF}
            color={COLOR_INTENT.WIDGETS.VALUE_PROPOSITION_ITEM.HEADING}
            textAlign="center"
            lineHeight={{
              MOBILE: LINE_HEIGHT.S,
              DESKTOP: LINE_HEIGHT.XXS,
            }}
            itemProp={SCHEMA_PROPS.NAME}
          >
            {title}
          </H2>
          <Box display={{ MOBILE: 'none', DESKTOP: 'block' }}>
            <Text
              textAlign="center"
              fontSize={FONT_SIZE.PX_16}
              color={COLOR_INTENT.WIDGETS.VALUE_PROPOSITION_ITEM.TEXT}
            >
              {subtitle}
            </Text>
          </Box>
          <Box mt={{ MOBILE: SPACE.PX_20, TABLET: SPACE.PX_38 }}>
            <StyledLink href={link} variant={BUTTON_VARIANT.OUTLINE}>
              {buttonText}
            </StyledLink>
          </Box>
        </Flex>
      </Flex>
    </Flex>
    {position && (
      <meta itemProp={SCHEMA_PROPS.POSITION} content={String(position)} />
    )}
  </article>
)
