import React from 'react'
import styled, { css } from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { BORDER_WIDTH, COLOR_INTENT, RADIUS, SPACE, Z_INDEX } from 'Theme'
import { EXPERT_IMAGE_SIZE } from '../constants'

const ExpertImageWrapper = styled(Box)`
  width: ${EXPERT_IMAGE_SIZE.TABLET.WIDTH};
  height: ${EXPERT_IMAGE_SIZE.TABLET.HEIGHT};
  border-radius: ${RADIUS.CIRCLE};
  border: ${BORDER_WIDTH.PX_2} solid
    ${COLOR_INTENT.WIDGETS.CATEGORY_HEADER.EXPERT_BORDER};
  overflow: hidden;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: absolute;
      top: -${SPACE.PX_10};
      z-index: ${Z_INDEX.CATEGORY_HEADER_EXPERT_IMAGE};
      width: ${EXPERT_IMAGE_SIZE.MOBILE.WIDTH};
      height: ${EXPERT_IMAGE_SIZE.MOBILE.HEIGHT};
    `}
`

interface ExpertImageProps {
  isMobile: boolean
  src: string
  alt?: string
}

export const ExpertImage: React.FC<React.PWC<ExpertImageProps>> = ({
  isMobile,
  src,
  alt,
}) => (
  <ExpertImageWrapper
    mt={{ MOBILE: 0, TABLET: SPACE.PX_8 }}
    isMobile={isMobile}
  >
    <Image
      alt={alt || 'Expert image'}
      src={src}
      layout={IMAGE_LAYOUT.RESPONSIVE}
      aspectRatio={ASPECT_RATIO.PROFILE}
      sizes={`(max-width: 768px) ${EXPERT_IMAGE_SIZE.MOBILE.WIDTH}, ${EXPERT_IMAGE_SIZE.TABLET.WIDTH}`}
    />
  </ExpertImageWrapper>
)
