import { useState } from 'react'
import { useDebounce } from 'react-use'

export const usePriceFilterStateManagement = ({
  onChange,
  initialPriceFrom,
  initialPriceTo,
  debounceTimeout = 300,
}) => {
  const [priceFrom, setPriceFrom] = useState(initialPriceFrom)
  const [priceTo, setPriceTo] = useState(initialPriceTo)
  useDebounce(
    () => {
      onChange({ priceFrom, priceTo })
    },
    debounceTimeout,
    [priceFrom, priceTo]
  )
  return { priceFrom, priceTo, setPriceFrom, setPriceTo }
}
