import { useRouter } from 'next/router'
import { rem } from 'polished'
import queryString from 'query-string'
import React from 'react'
import { useLocation } from 'react-use'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { ExpertsList } from 'components/ExpertsList'
import { Box, Flex } from 'components/Layout'
import NoData from 'components/NoData'
import {
  getMaxPriceWithLimit,
  getMinPriceWithLimit,
} from 'components/PriceFilter'
import { ROUTE } from 'constants/routes'
import { withGA4Provider } from 'hoc/withGoogleAnalytics'
import { useCurrency } from 'providers/currency'
import {
  GA4_AREAS,
  GA4_EVENTS,
  GA4_ITEM_LIST_NAME,
  GA4_PAGE_AREAS,
  useGoogleAnalytics,
} from 'providers/googleAnalytics'
import { SPACE } from 'Theme'
import { ExpertsFilter } from './ExpertsFilter'
import { FILTER_MAX_PRICE, useExpertsQuery } from './hooks'
import { getTagFilterValueFromParams, silentPushExpertsUrl } from './utils'

const SEE_MORE = 'See more'

export interface ExpertsListingWidgetProps {
  isFilterEnabled: boolean
  widgetId: number
}

const ExpertsListingWidgetContent: React.FC<
  React.PWC<ExpertsListingWidgetProps>
> = ({ isFilterEnabled, widgetId }) => {
  const router = useRouter()
  const location = useLocation()
  const { currencyIsoCode } = useCurrency()
  const { isReady } = router
  const searchParams = queryString.parse(location.search)
  const { executeDataToDataLayer } = useGoogleAnalytics()

  const pageParam = Number(searchParams?.page ?? 1)
  const sessionLengthParam = searchParams?.sessionLength ?? []
  const priceFromParam = getMinPriceWithLimit({
    limit: FILTER_MAX_PRICE,
    currentValue: searchParams?.priceFrom ?? 0,
  })
  const priceToParam = getMaxPriceWithLimit({
    limit: FILTER_MAX_PRICE,
    currentValue: searchParams?.priceTo ?? FILTER_MAX_PRICE,
  })
  const [availabilityFromParam] = getTagFilterValueFromParams(
    searchParams?.availabilityFrom
  )
  const [availabilityToParam] = getTagFilterValueFromParams(
    searchParams?.availabilityTo
  )
  const tagsParam = searchParams?.tags ?? []
  const regionsParam = searchParams?.regions ?? []

  const { experts, nextPage, isLoading, fetchNextPage } = useExpertsQuery({
    widgetId,
    priceFrom: priceFromParam,
    priceTo: priceToParam,
    availabilityFrom: availabilityFromParam,
    availabilityTo: availabilityToParam,
    page: pageParam,
    sessionLength: sessionLengthParam,
    tags: tagsParam,
    regions: regionsParam,
    isRouterReady: isReady,
    currencyIsoCode,
  })

  const isSomeQueryLoading = isLoading || !isReady

  return (
    <Box>
      {isFilterEnabled && (
        <ExpertsFilter
          expertsParams={{
            priceTo: priceToParam,
            priceFrom: priceFromParam,
            availabilityFrom: availabilityFromParam,
            availabilityTo: availabilityToParam,
            sessionLength: sessionLengthParam,
            tags: tagsParam,
            regions: regionsParam,
          }}
        />
      )}
      {experts.length === 0 && !isSomeQueryLoading && (
        <Flex p={SPACE.PX_40} minHeight={rem(500)} alignItems="center">
          <NoData
            header="No experts found"
            content="Try to edit your filter"
            width="100%"
            minHeight="100%"
          />
        </Flex>
      )}
      <ExpertsList
        route={ROUTE.EXPERTS()}
        experts={experts}
        isLoading={isSomeQueryLoading}
      >
        {nextPage && (
          <Flex width={rem(120)} m="auto" mt={SPACE.PX_16}>
            <Button
              data-test-id={DATA_TEST_ID.PUBLIC_EXPERTS_SEE_MORE_BTN}
              isLoading={isSomeQueryLoading}
              onClick={async () => {
                executeDataToDataLayer({
                  event: GA4_EVENTS.CLICK,
                  overrideData: {
                    text: SEE_MORE,
                    name: 'See more experts on consultations detail page',
                  },
                })
                await fetchNextPage()
                silentPushExpertsUrl({
                  router,
                  route: location.pathname,
                  params: {
                    priceFrom: priceFromParam,
                    priceTo: priceToParam,
                    availabilityFrom: availabilityFromParam,
                    availabilityTo: availabilityToParam,
                    page: pageParam + 1,
                    sessionLength: sessionLengthParam,
                    tags: tagsParam,
                    regions: regionsParam,
                  },
                })
              }}
              variant={BUTTON_VARIANT.OUTLINE}
            >
              {SEE_MORE}
            </Button>
          </Flex>
        )}
      </ExpertsList>
    </Box>
  )
}

export const ExpertsListingWidget = withGA4Provider({
  area: GA4_AREAS.CONSULTATIONS,
  page_area: GA4_PAGE_AREAS.CCP,
  item_list_name: `${GA4_ITEM_LIST_NAME.CATEGORY_PAGE} / ${GA4_ITEM_LIST_NAME.CONSULTATIONS}`,
})(ExpertsListingWidgetContent)
