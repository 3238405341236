import React from 'react'
import { AlphabeticalIndex } from 'components/AlphabeticalIndex'
import { type CmsAlphabeticalIndexWidget } from 'types/graphql-generated'

interface AlphabeticalIndexWidgetProps
  extends Pick<CmsAlphabeticalIndexWidget, 'data'> {
  cmsAlphabeticalIndexWidgetTitle: CmsAlphabeticalIndexWidget['title']
  // TODO: Remove after types will be implemented
  // eslint-disable-next-line react/no-unused-prop-types
  alphabeticalIndexWidgetType: CmsAlphabeticalIndexWidget['type']
}

export const AlphabeticalIndexWidget: React.FC<
  React.PWC<AlphabeticalIndexWidgetProps>
> = ({ data, cmsAlphabeticalIndexWidgetTitle }) => (
  <AlphabeticalIndex title={cmsAlphabeticalIndexWidgetTitle} data={data} />
)
