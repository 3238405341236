import { rem } from 'polished'
import React from 'react'
import { WIDGET_NAMES } from '@admin/routes/Cms/components/Widgets/constants'
import { BUTTON_VARIANT } from 'components/Button'
import { Box, Flex, Grid } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { SCHEMA_PROPS, SCHEMA_TYPES, WIDGET_PLACEMENT } from 'constants/common'
import { ROUTE } from 'constants/routes'
import {
  COLOR_INTENT,
  LINE_HEIGHT,
  PAGE_MAX_WIDTH,
  FONT_SIZE,
  SPACE,
} from 'Theme'
import { useMedia } from 'useMedia'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'
import { AltExpertsListingCarousel } from './AltExpertsListingCarousel'
import { experts } from './experts'

const WIDGET_CONTENT_SPACING_LEFT = `calc((100% - ${PAGE_MAX_WIDTH.LAYOUT}) / 2)`

export const AltExpertsListingWidget: React.FC<React.PWC<unknown>> = () => {
  const { MOBILE: isMobile } = useMedia()
  return (
    <Box
      backgroundColor={COLOR_INTENT.WIDGETS.ALT_EXPERT_LISTING.BG_COLOR}
      py={{ MOBILE: SPACE.PX_32, TABLET: SPACE.PX_64 }}
      itemScope
      itemType={SCHEMA_TYPES.THING}
    >
      <meta
        itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
        content={WIDGET_NAMES.ALT_EXPERTS_LISTING.name}
      />
      <Box>
        <Grid
          // Set of minmax is workaround for issue with swiper width ( when swiper is not full width )
          gridTemplateColumns={{
            MOBILE: 'minmax(0, 1fr)',
            TABLET: 'auto minmax(0, 1fr)',
          }}
          pl={{
            TABLET: WIDGET_CONTENT_SPACING_LEFT,
          }}
        >
          <Box
            width="100%"
            maxWidth={{ MOBILE: '100%', TABLET: rem(360) }}
            ml={{ MOBILE: 0, TABLET: SPACE.PX_15 }}
            mr={{ TABLET: SPACE.PX_32 }}
            px={{ MOBILE: SPACE.PX_16, TABLET: 0 }}
          >
            <Flex
              flexDirection="column"
              alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
              pr={{ MOBILE: 0, TABLET: SPACE.PX_15 }}
              mt={{ TABLET: SPACE.PX_48 }}
              height="100%"
            >
              <WidgetTitle
                placement={WIDGET_PLACEMENT.SECTION}
                lineHeight={LINE_HEIGHT.XXXS}
                textAlign={{ MOBILE: 'center', TABLET: 'left' }}
                itemProp={SCHEMA_PROPS.NAME}
              >
                {isMobile
                  ? 'Consult with top designers'
                  : 'Consult with top interior designers'}
              </WidgetTitle>
              <WidgetSubtitle
                textAlign={{ MOBILE: 'center', TABLET: 'left' }}
                fontSize={FONT_SIZE.PX_16}
                mt={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_8 }}
                mb="0"
                aria-label="Book one on one online video consultations with our most loved
                designers."
              >
                Book 1:1 online video consultations with our most loved
                designers.
              </WidgetSubtitle>
              <Flex
                flexDirection="row"
                mt={SPACE.PX_32}
                display={{ MOBILE: 'none', TABLET: 'flex' }}
              >
                <StyledLink
                  href={ROUTE.EXPERTS()}
                  aria-label="Browse all experts"
                >
                  Browse all Experts
                </StyledLink>
                <StyledLink
                  href={ROUTE.CONSULTATIONS()}
                  variant={BUTTON_VARIANT.OUTLINE}
                  aria-label="How it works"
                  ml={SPACE.PX_8}
                >
                  How it works
                </StyledLink>
              </Flex>
            </Flex>
          </Box>
          <Box
            mt={{ MOBILE: SPACE.PX_32, TABLET: 0 }}
            itemScope
            itemType={SCHEMA_TYPES.ITEM_LIST}
          >
            <AltExpertsListingCarousel items={experts} />
          </Box>
        </Grid>
        <Flex
          flexDirection="row"
          justifyContent="center"
          mt={SPACE.PX_24}
          display={{ MOBILE: 'flex', TABLET: 'none' }}
        >
          <StyledLink href={ROUTE.EXPERTS()} aria-label="Browse all experts">
            Browse all Experts
          </StyledLink>
          <StyledLink
            href={ROUTE.CONSULTATIONS()}
            variant={BUTTON_VARIANT.OUTLINE}
            aria-label="How it works"
            ml={SPACE.PX_8}
          >
            How it works
          </StyledLink>
        </Flex>
      </Box>
    </Box>
  )
}
