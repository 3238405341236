import { useRouter } from 'next/router'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import isRequiredIf from 'react-proptype-conditional-require'
import { DATA_TEST_ID, DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { SHARP_BUTTON_INTENT, SharpButton } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex, Container } from 'components/Layout'
import { ROUTE } from 'constants/routes'
import { SPACE, COLOR } from 'Theme'
import { useMedia } from 'useMedia'
import { THUMBNAIL_ELEMENT_NAME } from '../VideoWidget'
import { WidgetTitle } from '../WidgetTitle'

const HERO_IMAGE_NAME = 'Hero image'

const HERO_HEIGHT = rem(600)

export const HeroWidget = ({
  title,
  attachment,
  hasLandingPageButtons,
  isVideoElement,
}) => {
  const router = useRouter()
  const media = useMedia()

  return (
    <Flex
      position="relative"
      height={HERO_HEIGHT}
      justifyContent="center"
      data-test-class={DATA_TEST_CLASS.HERO_WIDGET_LEGACY}
    >
      <Image
        alt={attachment.alt || HERO_IMAGE_NAME}
        src={attachment.url}
        layout={IMAGE_LAYOUT.FILL}
        objectFit="cover"
      />
      {hasLandingPageButtons && (
        <Container zIndex={1}>
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
          >
            <Flex maxWidth="500px">
              <WidgetTitle
                color={COLOR.WHITE}
                textAlign={{
                  MOBILE: 'center',
                  TABLET: 'left',
                }}
              >
                {title}
              </WidgetTitle>
            </Flex>
            <Flex
              flexDirection={{ MOBILE: 'column', TABLET: 'row' }}
              alignItems="center"
            >
              <SharpButton
                data-test-id={DATA_TEST_ID.HERO_WIDGET_LEGACY_FIND_EXPERT_BTN}
                width="fit-content"
                onClick={() => {
                  router.push(ROUTE.EXPERTS())
                }}
              >
                Find an Expert
              </SharpButton>
              {isVideoElement && (
                <Flex
                  ml={{ MOBILE: 0, TABLET: SPACE.PX_10 }}
                  mt={{ MOBILE: SPACE.PX_10, TABLET: 0 }}
                  width={{ MOBILE: '100%' }}
                >
                  <SharpButton
                    intent={SHARP_BUTTON_INTENT.SECONDARY}
                    width={media.MOBILE ? '100%' : 'fit-content'}
                    onClick={() => {
                      const element = document.getElementsByName(
                        THUMBNAIL_ELEMENT_NAME
                      )[0]
                      if (element) {
                        element.click()
                      }
                    }}
                  >
                    watch video
                  </SharpButton>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Container>
      )}
    </Flex>
  )
}

HeroWidget.defaultProp = {
  hasLandingPageButtons: false,
  isVideoElement: false,
}

HeroWidget.propTypes = {
  title: isRequiredIf(PropTypes.string, (props) => props.hasLandingPageButtons),
  attachment: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  hasLandingPageButtons: PropTypes.bool,
  isVideoElement: PropTypes.bool,
}
