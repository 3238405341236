import { compact } from 'lodash'
import React from 'react'
import { type CmsListingWidget } from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { ListingWidget } from './ListingWidget'

const MAX_POSITION_OF_2ND_LISTING_WIDGET = 28

const LISTING_WIDGET_POSITION = [
  {
    MOBILE: 10,
    TABLET: 10,
    DESKTOP: 14,
    LIMITED: 2,
    gridColumn: { DESKTOP: '3/5', TABLET: '2/4', MOBILE: '1/3' },
  },
  {
    MOBILE: 30,
    TABLET: 20,
    DESKTOP: 28,
    LIMITED: MAX_POSITION_OF_2ND_LISTING_WIDGET,
    gridColumn: { MOBILE: '1/3', TABLET: 'span 2' },
  },
]

const getCorrectPosition = ({
  isMobile,
  mobilePosition,
  isTablet,
  tabletPosition,
  desktopPosition,
}: {
  isMobile: boolean
  mobilePosition: number
  isTablet: boolean
  tabletPosition: number
  desktopPosition: number
}) => {
  if (isMobile) {
    return mobilePosition
  }

  if (isTablet) {
    return tabletPosition
  }

  return desktopPosition
}

interface ListingWidgetLogicProps {
  isLoading: boolean
  position: number
  totalProducts: number
  cmsListingWidgets: CmsListingWidget[]
  isFilterApplied: boolean
}

export const ListingWidgetLogic: React.FC<
  React.PWC<ListingWidgetLogicProps>
> = ({
  isLoading,
  position,
  totalProducts,
  cmsListingWidgets,
  children,
  isFilterApplied,
}) => {
  const { MOBILE: isMobile, TABLET: isTablet } = useMedia()

  return (
    <>
      {!isFilterApplied &&
        compact(
          LISTING_WIDGET_POSITION.map(
            ({ MOBILE, TABLET, DESKTOP, LIMITED, gridColumn }, index) => {
              if (!cmsListingWidgets?.[index]) {
                return null
              }

              const wantedPosition = getCorrectPosition({
                isMobile,
                mobilePosition: MOBILE,
                isTablet,
                tabletPosition: TABLET,
                desktopPosition: DESKTOP,
              })

              if (
                (totalProducts <= wantedPosition && position === LIMITED) ||
                position === wantedPosition
              ) {
                return (
                  <ListingWidget
                    key={position}
                    isLoading={isLoading}
                    cmsListingWidget={cmsListingWidgets[index]}
                    gridColumn={gridColumn}
                    position={position + 1}
                  />
                )
              }
              return null
            }
          )
        )}
      {!isFilterApplied &&
        totalProducts < MAX_POSITION_OF_2ND_LISTING_WIDGET &&
        cmsListingWidgets?.[1] &&
        position + 1 === totalProducts && (
          <ListingWidget
            key={position}
            isLoading={isLoading}
            cmsListingWidget={cmsListingWidgets[1]}
            gridColumn={LISTING_WIDGET_POSITION[1].gridColumn}
            position={position + 1}
          />
        )}
      {children}
    </>
  )
}
