import React from 'react'
import type { PositionProps, WidthProps } from 'styled-system'
import { Box } from 'components/Layout'

export const TRANSITION_TIME = '250ms'

interface FadeEffectBoxProps {
  isMobile?: boolean
}

interface SwiperGalleryBoxProps
  extends FadeEffectBoxProps,
    PositionProps,
    WidthProps {}

export const SwiperGalleryBox: React.FC<React.PWC<SwiperGalleryBoxProps>> = ({
  children,
  isMobile,
  ...rest
}) => (
  <Box overflowX={isMobile ? undefined : 'hidden'}>
    <Box width={isMobile ? '100%' : '95%'} {...rest}>
      {children}
    </Box>
  </Box>
)
