import React, { useRef } from 'react'
import styled from 'styled-components'
import { A11y, Autoplay, EffectFade, Navigation } from 'swiper'
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  CarouselNavigationArrow,
  getArrowStickySide,
} from 'components/CarouselNavigation/CarouselNavigationArrow'
import { Flex } from 'components/Layout'
import { SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import {
  AltExpertListingItem,
  EXPERT_NAME_HEIGHT,
} from './AltExpertListingItem'
import { type ExpertListingItemType } from './experts'

const CAROUSEL_NAVIGATION_Z_INDEX = 1

const CAROUSEL_NAVIGATION_HEIGHT = `calc(100% - ${EXPERT_NAME_HEIGHT})`

const StyledSwiper = styled(Swiper)`
  height: 100%;
  user-select: none;
  .swiper-button-disabled {
    display: none;
  }
`

const ArrowWrapper = styled(Flex)`
  position: absolute;
  ${({ isStickyToLeft }) => getArrowStickySide({ isStickyToLeft })}
`

const NavigationWrapper = styled(Flex)`
  pointer-events: none;
`

interface AltExpertsListingCarouselProps {
  items: ExpertListingItemType[]
}

export const AltExpertsListingCarousel: React.FC<
  React.PWC<AltExpertsListingCarouselProps>
> = ({ items }) => {
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)
  const swiper = useRef(null)
  const { MOBILE: isMobile } = useMedia()

  return (
    <StyledSwiper
      ref={swiper}
      modules={[Autoplay, Navigation, EffectFade, A11y]}
      navigation={{
        prevEl: prevButtonRef.current,
        nextEl: nextButtonRef.current,
      }}
      slidesPerView="auto"
      spaceBetween={isMobile ? 8 : 16}
      slidesOffsetBefore={isMobile ? 16 : 0}
      slidesOffsetAfter={16}
      direction="horizontal"
      breakpoints={{
        320: {
          slidesPerView: 2.3,
          slidesPerGroup: 2,
        },
        992: {
          slidesPerView: 3.3,
          slidesPerGroup: 3,
        },
        1920: {
          slidesPerView: 5.4,
          slidesPerGroup: 5,
        },
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide key={item.name}>
          <AltExpertListingItem {...item} position={index + 1} />
        </SwiperSlide>
      ))}
      <NavigationWrapper
        position="absolute"
        top={0}
        height={CAROUSEL_NAVIGATION_HEIGHT}
        width="100%"
        px={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_16 }}
        justifyContent="space-between"
        alignItems="center"
        zIndex={CAROUSEL_NAVIGATION_Z_INDEX}
      >
        <ArrowWrapper isStickyToLeft>
          <CarouselNavigationArrow
            ref={prevButtonRef}
            hasBackground
            aria-label="previous arrow button"
            isDarkTheme
            isLtr={false}
          />
        </ArrowWrapper>
        <ArrowWrapper>
          <CarouselNavigationArrow
            ref={nextButtonRef}
            hasBackground
            aria-label="next arrow button"
            isDarkTheme
            isLtr
          />
        </ArrowWrapper>
      </NavigationWrapper>
    </StyledSwiper>
  )
}
