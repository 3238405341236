import { get } from 'lodash'
import { useMemo } from 'react'
import { useFetchNextPageQuery } from 'hooks'
import { CmsWidgetByIdDocument as CMS_WIDGET_BY_ID_QUERY } from 'types/graphql-generated'

const getDataContent = (data, field) =>
  get(data, ['cmsWidgetById', 'content', field], [])

export const useFetchMultiCollectionProducts = ({
  redundantItemsCount,
  ...options
}) => {
  const {
    loading: isLoading,
    data,
    previousData,
    fetchNextPage,
    refetch,
  } = useFetchNextPageQuery(CMS_WIDGET_BY_ID_QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  })

  const {
    productsList,
    nextPage,
    totalCount,
    categoriesOne,
    categoriesTwo,
    categoriesThree,
    brands,
  } = useMemo(() => {
    const currentData = isLoading ? previousData : data
    const { data: collectionItems = [], paginationInfo } = get(
      currentData,
      ['cmsWidgetById', 'content', 'items'],
      {
        data: [],
        paginationInfo: { nextPage: null, totalCount: 0 },
      }
    )

    const unifiedProductData = collectionItems.map(
      ({ product, productVariant, breakoutVariant }) =>
        breakoutVariant
          ? {
              ...breakoutVariant.product,
              images: [breakoutVariant.attachment],
              title: breakoutVariant.title,
              variants: [breakoutVariant],
              maxPrice: breakoutVariant.maxPrice,
              minPrice: breakoutVariant.minPrice,
              tradeMaxPrice: breakoutVariant.tradeMaxPrice,
              tradeMinPrice: breakoutVariant.tradeMinPrice,
              availability: breakoutVariant.availability,
            }
          : {
              ...product,
              ...(productVariant
                ? {
                    title: productVariant.title,
                    maxPrice: productVariant.price,
                    minPrice: productVariant.price,
                    tradeMaxPrice: productVariant.tradePrice,
                    tradeMinPrice: productVariant.tradePrice,
                    images: productVariant.images,
                    variants: [{ ...productVariant, product }], // Product object for analytics
                    availability: productVariant.availability,
                  }
                : {}),
            }
    )

    return {
      productsList: unifiedProductData,
      categoriesOne: getDataContent(currentData, 'categoriesOne'),
      categoriesTwo: getDataContent(currentData, 'categoriesTwo'),
      categoriesThree: getDataContent(currentData, 'categoriesThree'),
      brands: getDataContent(currentData, 'brands'),
      ...paginationInfo,
    }
  }, [isLoading, data, previousData])

  // Decreases number of total products to render, to not leave a row with products modulo
  const modifiedProductList =
    nextPage && redundantItemsCount
      ? productsList?.slice(0, productsList.length - redundantItemsCount)
      : productsList

  return {
    isLoading,
    isMoreButtonVisible: totalCount > productsList.length || Boolean(nextPage),
    totalProductCount: totalCount,
    categoriesOne,
    categoriesTwo,
    categoriesThree,
    productsList: modifiedProductList,
    brands,
    fetchNextPage,
    refetch,
  }
}
