import { rem } from 'polished'
import React, { useEffect, useRef } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import styled from 'styled-components'
import { Navigation, Pagination, EffectFade, Autoplay, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import {
  BULLET_WRAPPER_SIZE_PX,
  CarouselNavigation,
  CarouselPaginationDot,
} from 'components/CarouselNavigation'
import { Flex } from 'components/Layout'
import { SCHEMA_TYPES } from 'constants/common'
import { SPACE, Z_INDEX } from 'Theme'
import { type CmsHeroCarouselWidgetItem } from 'types/graphql-generated'
import { HeroCarouselSlide } from './HeroCarouselSlide'

const BULLET_THRESHOLD = 5

const StyledSwiper = styled(Swiper)`
  user-select: none;
  height: 100%;
  .swiper-slide {
    height: auto;
  }
  .swiper-pagination-bullet {
    transform: scale(1) !important;
  }

  .swiper-pagination-bullets {
    min-width: ${rem(
      BULLET_WRAPPER_SIZE_PX * BULLET_THRESHOLD
    )}; // bullet wrapper size*max number of bullets visible at once
    column-gap: 0;
    padding: 0;
    position: relative;
  }
`

const AUTOPLAY_DELAY = 5000
const BULLET_ACTIVE_CLASS = 'hero-carousel-pagination-bullet-active'

type HeroCarouselProps = {
  isAutoRotationEnabled: boolean
  items: CmsHeroCarouselWidgetItem[]
}

export const HeroCarousel: React.FC<React.PWC<HeroCarouselProps>> = ({
  isAutoRotationEnabled,
  items,
}) => {
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)
  const paginationRef = useRef(null)
  const swiper = useRef(null)

  useEffect(() => {
    const restartSwiperAutoplay = () => {
      if (document.visibilityState === 'visible' && isAutoRotationEnabled) {
        swiper.current.swiper.autoplay.start()
      }
    }
    document.addEventListener('visibilitychange', restartSwiperAutoplay)

    return () => {
      document.removeEventListener('visibilitychange', restartSwiperAutoplay)
    }
  }, [swiper, isAutoRotationEnabled])

  return (
    <StyledSwiper
      ref={swiper}
      modules={[Autoplay, Navigation, Pagination, EffectFade, A11y]}
      navigation={{
        prevEl: prevButtonRef.current,
        nextEl: nextButtonRef.current,
      }}
      speed={750}
      pagination={{
        el: paginationRef.current,
        clickable: true,
        bulletActiveClass: BULLET_ACTIVE_CLASS,
        renderBullet: (_, className) =>
          ReactDOMServer.renderToStaticMarkup(
            <CarouselPaginationDot
              className={className}
              isAutoRotationEnabled={isAutoRotationEnabled}
              animationDuration={AUTOPLAY_DELAY}
              bulletActiveClass={BULLET_ACTIVE_CLASS}
              slidesCount={items.length}
              isDarkTheme={false}
            />
          ),
        dynamicBullets: items.length > BULLET_THRESHOLD,
      }}
      slidesPerView={1}
      direction="horizontal"
      loop
      autoplay={
        isAutoRotationEnabled
          ? { delay: AUTOPLAY_DELAY, disableOnInteraction: false }
          : false
      }
      itemScope
      itemType={SCHEMA_TYPES.ITEM_LIST}
    >
      {items.map((item, index) => (
        <SwiperSlide key={item.id}>
          <HeroCarouselSlide {...item} position={index + 1} />
        </SwiperSlide>
      ))}
      <Flex
        position="absolute"
        bottom={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_24 }}
        width="100%"
        px={SPACE.PX_16}
        justifyContent="center"
        zIndex={Z_INDEX.CAROUSEL_NAVIGATION_WRAPPER}
      >
        <CarouselNavigation
          prevButtonRef={prevButtonRef}
          nextButtonRef={nextButtonRef}
          paginationRef={paginationRef}
          hasArrows={false}
        />
      </Flex>
    </StyledSwiper>
  )
}
