import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { DATA_TEST_CLASS, DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, Flex } from 'components/Layout'
import { SPACE, COLOR_INTENT, BORDER_WIDTH } from 'Theme'
import { useMedia } from 'useMedia'
import { WidgetTitle } from '../WidgetTitle'
import { Testimonial } from './Testimonial'
import 'swiper/css/bundle'

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  position: static;
  cursor: grab;

  .swiper-pagination-bullet {
    width: 15px;
    height: 3px;
    border-radius: 30%;
    opacity: 1;
    background: ${COLOR_INTENT.GRAY_20};
    ${({ isPaginationHidden }) => isPaginationHidden && `visibility: hidden`};
  }

  .swiper-pagination-bullet-active {
    background: ${COLOR_INTENT.BRAND};
  }

  .swiper-slide {
    height: auto;
    display: flex;
    position: relative;

    &:nth-child(odd) .swiper-slide-box {
      background-color: ${COLOR_INTENT.WIDGETS.TESTIMONIAL.ODD};
      border: ${BORDER_WIDTH.PX_1} solid transparent;
    }
  }

  &:active {
    cursor: grabbing;
  }

  &:active {
    cursor: grabbing;
  }
`

export const TestimonialsWidget = ({ title, testimonials }) => {
  const media = useMedia()
  const isMobile = media.MOBILE

  // Testimonials must be even number because we color them that every odd and every even has different color
  // But with loop, if you have odd number then first and last will have same color.
  const getTestimonialInEvenNumber = useCallback(() => {
    if (testimonials.length % 2 === 0) {
      return testimonials
    }

    return [...testimonials, ...testimonials]
  }, [testimonials])

  return (
    <Box
      position="relative"
      my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
      data-test-class={DATA_TEST_CLASS.TESTIMONIALS_WIDGET}
    >
      <Flex
        justifyContent="center"
        pb={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_32 }}
      >
        <WidgetTitle m="0" textAlign="center" px={SPACE.PX_16}>
          {title}
        </WidgetTitle>
      </Flex>
      <Flex>
        <StyledSwiper
          modules={[Pagination, A11y]}
          data-test-id={DATA_TEST_ID.TESTIMONIAL_CAROUSEL_WIDGET}
          isPaginationHidden
          slidesPerView={isMobile ? 1.2 : 2}
          centeredSlides={!isMobile}
          loop
        >
          {getTestimonialInEvenNumber().map(({ id, feedback, clientInfo }) => (
            <SwiperSlide key={id}>
              <Testimonial feedback={feedback} clientInfo={clientInfo} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </Flex>
    </Box>
  )
}

TestimonialsWidget.propTypes = {
  title: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      feedback: PropTypes.string,
      clientInfo: PropTypes.string,
    })
  ).isRequired,
}
