import { useRouter } from 'next/router'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/Button'
import Icon from 'components/Icon'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex, Grid } from 'components/Layout'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { H4, Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, SPACE } from 'Theme'
import { type CmsConsultationListingWidget } from 'types/graphql-generated'

const VERTICAL_DIVIDER_HEIGHT = rem(12)
const VERTICAL_DIVIDER_WIDTH = rem(1)

const IMAGE_WIDTH = { MOBILE: rem(100), TABLET: rem(80) }

const VerticalDivider = styled(Box)`
  height: ${VERTICAL_DIVIDER_HEIGHT};
  width: ${VERTICAL_DIVIDER_WIDTH};
  background-color: ${COLOR_INTENT.WIDGETS.LISTING_WIDGET.CONSULTATION.DIVIDER};
`

const RoundedImage = styled(Image)`
  border-radius: 50%;
`

interface ConsultationListingWidgetProps {
  isLoading: boolean
  content: CmsConsultationListingWidget
  position: number
}

export const ConsultationListingWidget: React.FC<
  React.PWC<ConsultationListingWidgetProps>
> = ({ isLoading, content, position }) => {
  const router = useRouter()

  return (
    <Grid
      gridTemplateColumns={{ TABLET: '55% 45%' }}
      height="100%"
      width="100%"
      itemScope
      itemType={SCHEMA_TYPES.CREATIVE_WORK}
    >
      <meta
        itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
        content="Consultation Listing Widget"
      />
      <meta itemProp={SCHEMA_PROPS.POSITION} content={String(position)} />
      <Flex
        flexDirection="column"
        alignItems={{ MOBILE: 'center', TABLET: 'start' }}
        m={SPACE.PX_40}
      >
        <Box width={IMAGE_WIDTH} mb={SPACE.PX_16}>
          <RoundedImage
            isLoading={isLoading}
            src={content.expert.headshotImageAttachment?.url}
            alt={content.expert.headshotImageAttachment?.alt}
            layout={IMAGE_LAYOUT.RESPONSIVE}
            aspectRatio={ASPECT_RATIO.PROFILE}
            sizes={`(max-width: 768px) ${IMAGE_WIDTH.MOBILE}, ${IMAGE_WIDTH.TABLET}`}
            itemProp={SCHEMA_PROPS.IMAGE}
          />
        </Box>
        <H4
          textAlign={{ MOBILE: 'center', TABLET: 'left' }}
          mb={SPACE.PX_12}
          isLoading={isLoading}
          itemProp={SCHEMA_PROPS.NAME}
        >
          {content.headline}
        </H4>
        <Flex mb={SPACE.PX_24} alignItems="center">
          <Text isLoading={isLoading}>{content.duration}</Text>
          <VerticalDivider mx={SPACE.PX_8} />
          <Text isLoading={isLoading}>{content.price}</Text>
        </Flex>
        <Box>
          <Button
            isLoading={isLoading}
            {...(content.isButtonIconShown ? { icon: Icon.Video } : {})}
            onClick={() => {
              router.push(ROUTE.EXPERT_PROFILE({ slug: content.expert.slug }))
            }}
            aria-label={`Link to profile of expert ${content.expert.profileName} - ${content.buttonCopy}`}
          >
            {content.buttonCopy}
          </Button>
        </Box>
      </Flex>
      <Box height="100%" width="100%" position="relative">
        <MediaQueryWrapper
          tablet={
            <Image
              isLoading={isLoading}
              src={content.previewAttachment.url}
              alt={content.previewAttachment.alt || content.headline}
              objectFit="cover"
              layout={IMAGE_LAYOUT.FILL}
              itemProp={SCHEMA_PROPS.IMAGE}
            />
          }
        />
      </Box>
    </Grid>
  )
}
