import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { EMAILS } from 'shared-constants'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Grid, Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  SPACE,
  COLOR,
  FONT_STACK,
  FONT_SIZE,
  FONT_WEIGHT,
  IMAGE_BASE_WIDTH,
} from 'Theme'

const CONTAINER_WIDTH = rem(220)

const StyledLinkWithText = styled(LinkWithText)`
  text-decoration: underline;
`

export const ProductsWidgetItem = ({
  attachment,
  title,
  posttitle,
  pretitle,
  description,
  href,
  index,
}) => (
  <Grid flexDirection="column" width={CONTAINER_WIDTH}>
    <Flex flexDirection="column">
      <Image
        src={attachment.url}
        alt={attachment.alt || `The image of an ${title} product`}
        layout={IMAGE_LAYOUT.RESPONSIVE}
        baseWidth={IMAGE_BASE_WIDTH.PX_320}
        aspectRatio={ASPECT_RATIO.PRODUCTS}
      />
      <Flex flexDirection="column" alignItems="center" p={SPACE.PX_5}>
        {pretitle && (
          <Text
            fontSize={FONT_SIZE.PX_12}
            color={COLOR.GRAYSCALE_3}
            textAlign="center"
          >
            {pretitle}
          </Text>
        )}
        <Text
          fontSize={FONT_SIZE.PX_12}
          fontWeight={FONT_WEIGHT.MEDIUM}
          color={COLOR.BLACK}
          textAlign="center"
        >
          {title}
        </Text>
        {posttitle && (
          <Text
            fontSize={FONT_SIZE.PX_12}
            color={COLOR.GRAYSCALE_3}
            textAlign="center"
          >
            {posttitle}
          </Text>
        )}
        <Text fontFamily={FONT_STACK.SERIF} textAlign="center" my={SPACE.PX_10}>
          {description}
        </Text>
        <StyledLinkWithText
          href={href || `mailto:${EMAILS.SALES}`}
          target="_blank"
          fontWeight={FONT_WEIGHT.MEDIUM}
          fontSize={FONT_SIZE.PX_12}
          color={COLOR.BLACK}
          textAlign="center"
          aria-label={`${href ? 'Shop now' : 'Inquire'} ${title}${
            index ? `- ${index}` : ''
          }`}
        >
          {href ? 'SHOP NOW' : 'INQUIRE'}
        </StyledLinkWithText>
      </Flex>
    </Flex>
  </Grid>
)

ProductsWidgetItem.propTypes = {
  title: PropTypes.string.isRequired,
  pretitle: PropTypes.string,
  posttitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  attachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
}
