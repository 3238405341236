import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { type LayoutProps } from 'styled-system'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  IMAGE_BASE_WIDTH,
  RADIUS,
  SPACE,
} from 'Theme'
import { type Attachment } from 'types/graphql-generated'

const PROFILE_IMAGE_BORDER_WIDTH = rem(3)
const PROFILE_IMAGE_WIDTH = rem(76)

const LinkWithProps = styled(Link)
  .attrs({
    itemScope: true,
    itemType: SCHEMA_TYPES.LIST_ITEM,
  })
  .withConfig({
    shouldForwardProp: (prop) => !['width', 'maxWidth'].includes(prop),
  })``

const ProfileImageWrapper = styled(Box)`
  border: ${PROFILE_IMAGE_BORDER_WIDTH} solid
    ${COLOR_INTENT.COLLECTIONS_SHOWROOM_PAGE_CARD.PROFILE_IMAGE_BORDER};
  border-radius: ${RADIUS.CIRCLE};
  flex: 0 0 ${PROFILE_IMAGE_WIDTH};
`

const ProfileImage = styled(Image)`
  border-radius: ${RADIUS.CIRCLE};
  width: ${PROFILE_IMAGE_WIDTH};
`

interface CollectionsShowroomPageCardProps extends LayoutProps {
  isLoading?: boolean
  href: string
  title: string
  previewImageAttachment: Attachment
  profileImageAttachment?: Attachment
  index?: number
}

export const CollectionsShowroomPageCard: React.FC<
  React.PWC<CollectionsShowroomPageCardProps>
> = ({
  isLoading = false,
  href,
  title,
  previewImageAttachment,
  profileImageAttachment,
  index,
  ...rest
}) => (
  <LinkWithProps href={href} {...rest}>
    <Flex flexDirection="column" height="auto" width="100%" flexShrink={0}>
      <Box width="100%" height="100%">
        <Image
          alt={previewImageAttachment?.alt || title}
          objectFit="cover"
          layout={IMAGE_LAYOUT.RESPONSIVE}
          aspectRatio={ASPECT_RATIO.PRODUCTS}
          baseWidth={IMAGE_BASE_WIDTH.PX_800}
          src={previewImageAttachment?.url}
          isLoading={isLoading}
          sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 33vw"
        />
      </Box>
      <Flex
        position="relative"
        mt={`-${SPACE.PX_10}`}
        alignItems="top"
        flexDirection="row"
      >
        {profileImageAttachment && (
          <ProfileImageWrapper
            width={PROFILE_IMAGE_WIDTH}
            height={PROFILE_IMAGE_WIDTH}
            mx={SPACE.PX_20}
          >
            <ProfileImage
              aspectRatio={ASPECT_RATIO.PROFILE}
              baseWidth={IMAGE_BASE_WIDTH.PX_80}
              src={profileImageAttachment?.url}
              isLoading={isLoading}
              alt={profileImageAttachment?.alt || `${title} profile`}
              itemProp={SCHEMA_PROPS.IMAGE}
            />
          </ProfileImageWrapper>
        )}
        <Box mt={SPACE.PX_20}>
          <Text
            fontSize={{ MOBILE: FONT_SIZE.PX_20, TABLET: FONT_SIZE.PX_24 }}
            fontFamily={FONT_STACK.SERIF}
            fontWeight={FONT_WEIGHT.MEDIUM}
            color={COLOR_INTENT.GRAY_90}
            isLoading={isLoading}
            itemProp={SCHEMA_PROPS.NAME}
          >
            {title}
          </Text>
        </Box>
      </Flex>
    </Flex>
    <meta itemProp={SCHEMA_PROPS.POSITION} content={String(index + 1)} />
  </LinkWithProps>
)
