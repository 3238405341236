import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Box, Container, Flex } from 'components/Layout'
import { SharpStyledLink } from 'components/Link'
import { Text } from 'components/Typography'
import { COLOR, FONT_SIZE, FONT_STACK, FONT_WEIGHT, SPACE } from 'Theme'

const Wrapper = styled(Box)`
  background-color: ${COLOR.PRIMARY_2};
`

export const BannerWidget = ({ title, buttonLink, buttonText }) => (
  <Wrapper my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}>
    <Container>
      <Flex
        flexDirection="column"
        alignItems="center"
        py={SPACE.PX_40}
        px={{ TABLET: SPACE.PX_150, MOBILE: 0 }}
      >
        <Flex mb={SPACE.PX_30}>
          <Text
            fontSize={{
              TABLET: FONT_SIZE.PX_32,
              MOBILE: FONT_SIZE.PX_24,
            }}
            fontWeight={FONT_WEIGHT.MEDIUM}
            fontFamily={FONT_STACK.SERIF}
            textAlign="center"
          >
            {title}
          </Text>
        </Flex>
        <SharpStyledLink href={buttonLink} px={SPACE.PX_60}>
          {buttonText}
        </SharpStyledLink>
      </Flex>
    </Container>
  </Wrapper>
)

BannerWidget.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
}
