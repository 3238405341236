import React from 'react'
import {
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
} from 'styled-system'
import { H2 } from 'components/Typography'
import { WIDGET_PLACEMENT } from 'constants/common'
import { FONT_SIZE, HEADING_VARIANT } from 'Theme'

interface WidgetTitleProps extends TypographyProps, ColorProps, SpaceProps {
  placement?: (typeof WIDGET_PLACEMENT)[keyof typeof WIDGET_PLACEMENT]
  itemProp?: string
}

const FONT_SIZES = {
  [WIDGET_PLACEMENT.HERO]: {
    MOBILE: FONT_SIZE.PX_36,
    TABLET: FONT_SIZE.PX_48,
  },
  [WIDGET_PLACEMENT.SECTION]: {
    MOBILE: FONT_SIZE.PX_32,
    TABLET: FONT_SIZE.PX_36,
  },
}

export const WidgetTitle: React.FC<React.PWC<WidgetTitleProps>> = ({
  placement = WIDGET_PLACEMENT.SECTION,
  children,
  ...rest
}) => (
  <H2
    mb={0}
    variant={HEADING_VARIANT.SERIF}
    fontSize={FONT_SIZES[placement]}
    {...rest}
  >
    {children}
  </H2>
)
