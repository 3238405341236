import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Grid } from 'components/Layout'
import { SPACE } from 'Theme'
import { ProductsWidgetItem } from './ProductsWidgetItem'

export const ProductsWidget = ({ cmsProductsWidgetItems }) => (
  <Grid
    my={SPACE.PX_15}
    justifyContent="center"
    justifyItems="center"
    {...(cmsProductsWidgetItems?.length > 1 && {
      gridGap: SPACE.PX_20,
    })}
    gridTemplateColumns={{
      MOBILE: '1fr',
      TABLET: `repeat(${
        cmsProductsWidgetItems?.length < 3 ? cmsProductsWidgetItems?.length : 3
      }, auto)`,
    }}
    data-test-class={DATA_TEST_CLASS.PRODUCTS_WIDGET}
  >
    {cmsProductsWidgetItems.map(({ id, ...restProps }, index) => (
      <ProductsWidgetItem key={id} index={index} {...restProps} />
    ))}
  </Grid>
)

ProductsWidget.propTypes = {
  cmsProductsWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      pretitle: PropTypes.string,
      posttitle: PropTypes.string,
      description: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      attachment: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
}
