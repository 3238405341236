import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex, Grid } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { H4, Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { useModal } from 'hooks'
import { SPACE } from 'Theme'

const MOBILE_IMAGE_HEIGHT = 200

const ContactUsModal = dynamic(
  () =>
    import('components/ContactUsModal/ContactUsModal').then(
      (module) => module.ContactUsModal
    ),
  {
    ssr: false,
  }
)

export const InfoListingWidget = ({ isLoading, content, position }) => {
  const { isOpen, closeModal, openModal } = useModal(false)

  return (
    <>
      <Grid
        gridTemplateColumns={{
          MOBILE: '1fr',
          TABLET: '1fr 45%',
        }}
        height="100%"
        width="100%"
        itemScope
        itemType={SCHEMA_TYPES.CREATIVE_WORK}
      >
        <meta
          itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
          content="Info Listing Widget"
        />
        <meta itemProp={SCHEMA_PROPS.POSITION} content={String(position)} />
        <Flex
          flexDirection="column"
          alignItems={{ MOBILE: 'center', TABLET: 'start' }}
          m={SPACE.PX_30}
        >
          <H4
            isLoading={isLoading}
            textAlign={{ MOBILE: 'center', TABLET: 'start' }}
            itemProp={SCHEMA_PROPS.NAME}
          >
            {content.headline}
          </H4>

          <Text
            isLoading={isLoading}
            textAlign={{ MOBILE: 'center', TABLET: 'start' }}
          >
            <MarkdownRenderer markdownSource={content.body} />
          </Text>

          {content.hasButton &&
            (content.hasCustomLink ? (
              <StyledLink
                href={content.customButtonUrl}
                mt={SPACE.PX_16}
                aria-label={content.buttonText}
              >
                {content.buttonText}
              </StyledLink>
            ) : (
              <Button mt={SPACE.PX_16} width="fit-content" onClick={openModal}>
                {content.buttonText}
              </Button>
            ))}
        </Flex>

        <Box
          height="100%"
          width="100%"
          position="relative"
          order={{ MOBILE: -1, TABLET: 1 }}
        >
          <MediaQueryWrapper
            mobile={
              <Image
                isLoading={isLoading}
                src={content.previewAttachment.url}
                alt={content.previewAttachment.alt || content.headline}
                objectFit="cover"
                layout={IMAGE_LAYOUT.RESPONSIVE}
                aspectRatio={ASPECT_RATIO.PRODUCTS}
                height={MOBILE_IMAGE_HEIGHT}
                itemProp={SCHEMA_PROPS.IMAGE}
              />
            }
            tablet={
              <Image
                isLoading={isLoading}
                src={content.previewAttachment.url}
                alt={content.previewAttachment.alt || content.headline}
                objectFit="cover"
                layout={IMAGE_LAYOUT.FILL}
                aspectRatio={ASPECT_RATIO.PRODUCTS}
                itemProp={SCHEMA_PROPS.IMAGE}
              />
            }
          />
        </Box>
      </Grid>

      <ContactUsModal
        isModalOpen={isOpen}
        closeModal={closeModal}
        type="InfoListingWidget"
      />
    </>
  )
}

export const infoListingWidgetPropType = PropTypes.shape({
  headline: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  hasButton: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  hasCustomLink: PropTypes.bool.isRequired,
  customButtonUrl: PropTypes.string,
  previewAttachment: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
})

InfoListingWidget.propTypes = {
  isLoading: PropTypes.bool,
  content: infoListingWidgetPropType.isRequired,
  position: PropTypes.number,
}
