import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Grid, Flex, Container } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE, COLOR, IMAGE_BASE_WIDTH, COLOR_INTENT } from 'Theme'

const LOGOS = {
  WSJ: '/featuredIn/wsj_logo.svg',
  AD: '/featuredIn/ad_logo.svg',
  DECOR: '/featuredIn/decor_logo.svg',
  FORBES: '/featuredIn/forbes_logo.svg',
  // BOH: '/featuredIn/boh_logo.svg',
  NYT: '/featuredIn/nyt_logo.svg',
  DOMINO: '/featuredIn/domino_logo.svg',
}

const FEATURED_IN_LOGO_HEIGHT = 34

const Wrapper = styled(Flex)`
  background-color: ${COLOR_INTENT.WIDGETS.FEATURED_IN.BACKGROUND_COLOR};
`

export const FeaturedInWidget = () => (
  <Wrapper
    justifyContent="center"
    data-test-class={DATA_TEST_CLASS.FEATURED_IN_WIDGET}
    mb={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
  >
    <Container width="100%" maxWidth={rem(900)}>
      <Flex justifyContent="center">
        <Grid
          p={{
            MOBILE: SPACE.PX_30,
            DESKTOP: SPACE.PX_20,
          }}
          width={{ MOBILE: 'auto', TABLET: '100%' }}
          gridTemplateColumns={{
            MOBILE: '1fr 1fr',
            TABLET: '0.5fr 0.5fr 0.5fr',
            DESKTOP: 'repeat( auto-fit, minmax(50px, auto) )',
          }}
          gridColumnGap={{
            MOBILE: SPACE.PX_40,
            TABLET: SPACE.PX_20,
            DESKTOP: SPACE.PX_30,
          }}
          gridRowGap={SPACE.PX_20}
          justifyContent="center"
        >
          <Grid
            gridColumn={{ MOBILE: '1 / 3', TABLET: '1 / 4', DESKTOP: '1' }}
            alignItems="center"
            justifyContent="center"
          >
            <Text color={COLOR.GRAYSCALE_3}>FEATURED IN</Text>
          </Grid>
          {Object.entries(LOGOS).map(([key, src]) => (
            <Grid
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              key={key}
            >
              <Image
                unoptimized
                layout={IMAGE_LAYOUT.FIXED}
                src={src}
                alt={key}
                placeholder="empty"
                baseWidth={IMAGE_BASE_WIDTH.PX_80}
                baseHeight={FEATURED_IN_LOGO_HEIGHT}
              />
            </Grid>
          ))}
        </Grid>
      </Flex>
    </Container>
  </Wrapper>
)
