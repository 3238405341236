import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import ReactCrop from 'react-image-crop'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import 'react-image-crop/dist/ReactCrop.css'
import { Button, BUTTON_VARIANT } from 'components/Button'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { WrappedLoader } from 'components/WrappedLoader'
import { SPACE, BORDER_WIDTH, COLOR, RADIUS } from 'Theme'

const CROP_AREA_MIN_HEIGHT = rem(300)

const CropArea = styled(Flex)`
  border: ${BORDER_WIDTH.PX_1} dashed ${COLOR.GRAYSCALE_5};
  background-color: ${COLOR.GRAYSCALE_6};
  border-radius: ${RADIUS.PX_10};
`

export const UploadImageArea = ({
  handleDrop,
  isImageLoading,
  uploadedImage,
  onLoad,
  crop,
  setCrop,
  setCompletedCrop,
  handleFileChange,
  fileInput,
}) => (
  <Flex flex="1">
    <CropArea
      width="100%"
      minHeight={CROP_AREA_MIN_HEIGHT}
      mt={SPACE.PX_5}
      justifyContent="center"
      alignItems="center"
      onDrop={handleDrop}
      onDragEnter={(event) => {
        event.preventDefault()
      }}
      onDragLeave={(event) => {
        event.preventDefault()
      }}
      onDragOver={(event) => {
        event.preventDefault()
      }}
    >
      {isImageLoading && (
        <WrappedLoader data-test-id={DATA_TEST_ID.UPLOAD_MODAL_LOADER} />
      )}
      {uploadedImage && !isImageLoading && (
        <ReactCrop
          src={uploadedImage}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(_, cropInPercents) => {
            setCrop(cropInPercents)
          }}
          onComplete={(_, cropInPercents) => {
            setCompletedCrop(cropInPercents)
          }}
        />
      )}
      {!uploadedImage && !isImageLoading && (
        <Flex flexDirection="column" alignItems="center">
          <Text color={COLOR.GRAYSCALE_2}>
            Drag and drop image here to upload
          </Text>
          <Box mt={SPACE.PX_15}>
            <Button
              icon={Icon.Upload}
              variant={BUTTON_VARIANT.OUTLINE}
              onClick={() => {
                fileInput.current.click()
              }}
            >
              Upload
            </Button>
            <Box display="none">
              <input
                type="file"
                ref={fileInput}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>
          </Box>
        </Flex>
      )}
    </CropArea>
  </Flex>
)

UploadImageArea.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  isImageLoading: PropTypes.bool.isRequired,
  uploadedImage: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  crop: PropTypes.object.isRequired,
  setCrop: PropTypes.func.isRequired,
  setCompletedCrop: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  fileInput: PropTypes.shape({
    current: PropTypes.shape({ click: PropTypes.func }),
  }),
}
