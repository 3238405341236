import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { A11y, Autoplay, Controller, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  CarouselNavigation,
  CarouselPaginationDot,
} from 'components/CarouselNavigation'
import { Delimiter } from 'components/Delimiter'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { CONTAINER_HORIZONTAL_PADDING, Flex } from 'components/Layout'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { SwiperGalleryArrows, SwiperGalleryBox } from 'components/SwiperGallery'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  COLOR,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  PAGE_MAX_WIDTH,
  SPACE,
  Z_INDEX,
} from 'Theme'
import { useMedia } from 'useMedia'
import 'swiper/css/bundle'
import { WidgetTitle } from '../WidgetTitle'
import { GalleryItemWrapper } from './GalleryItemWrapper'

const GALLERY_ITEM_IMAGE_NAME = 'Gallery item image'

const GALLERY_AUTOPLAY_DELAY = 15000

const TitleWrapper = styled(Flex)`
  background: ${COLOR.WHITE};
`

const StyledText = styled(Text)`
  text-decoration: underline;
`

const StyledSwiper = styled(Swiper).withConfig({
  shouldForwardProp: (prop) => !['observerUpdate'].includes(prop),
})`
  width: 100%;
  height: 100%;
  position: static;
`

const GALLERY_WIDGET_MAX_WIDTH = {
  WIDTH_1: 700,
}

const GALLERY_MAX_WIDTH_BREAKPOINTS_MAP = {
  [GALLERY_WIDGET_MAX_WIDTH.WIDTH_1]: {
    0: {
      slidesPerView: 1,
    },
  },
  DEFAULT: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1.5,
    },
    1000: {
      slidesPerView: 2,
    },
    1700: {
      slidesPerView: 3.5,
    },
    2000: {
      slidesPerView: 4,
    },
  },
}

const resolveGalleryBreakpoints = (maxWidgetWidth) => {
  if (maxWidgetWidth <= GALLERY_WIDGET_MAX_WIDTH.WIDTH_1) {
    return GALLERY_MAX_WIDTH_BREAKPOINTS_MAP[GALLERY_WIDGET_MAX_WIDTH.WIDTH_1]
  }
  return GALLERY_MAX_WIDTH_BREAKPOINTS_MAP.DEFAULT
}

const hasExpertOrCaption = ({ expert, caption }) => Boolean(expert || caption)

const widgetHasExpertOrCaption = (cmsGalleryWidgetItems) =>
  cmsGalleryWidgetItems.some(hasExpertOrCaption)

const BULLET_ACTIVE_CLASS = 'gallery-widget-pagination-bullet-active'
const BULLET_THRESHOLD = 5

export const GalleryWidget = ({
  title,
  cmsGalleryWidgetItems = [],
  maxWidgetWidth,
}) => {
  const media = useMedia()
  const paginationRef = useRef(null)
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)

  const [textSwiper, setTextSwiper] = useState(null)

  if (!cmsGalleryWidgetItems.length) {
    return null
  }

  const hasCaption = widgetHasExpertOrCaption(cmsGalleryWidgetItems)
  const swiperProps = {
    modules: [A11y, Pagination, Autoplay, Controller, Navigation],
    isMobile: media.MOBILE,
    spaceBetween: media.MOBILE ? 0 : 10,
    autoplay: { delay: GALLERY_AUTOPLAY_DELAY },
    centeredSlides: true,
    normalizeSlideIndex: false,
    observeSlideChildren: true,
    observerUpdate: true,
    observer: true,
    loop: true,
    slidesPerView: 1,
    loopedSlides: 4,
    breakpoints: resolveGalleryBreakpoints(maxWidgetWidth),
  }

  return (
    <>
      <Flex
        flexDirection="column"
        mt={SPACE.PX_30}
        mx="auto"
        width="100%"
        maxWidth={PAGE_MAX_WIDTH.LAYOUT}
        px={CONTAINER_HORIZONTAL_PADDING}
        position="relative"
      >
        {!!title && (
          <Flex
            justifyContent="center"
            position="relative"
            mb={{ MOBILE: SPACE.PX_30, TABLET: SPACE.PX_60 }}
          >
            <Delimiter />
            <TitleWrapper
              position="absolute"
              top={{ MOBILE: '-18px', TABLET: '-24px' }}
              px={{ MOBILE: SPACE.PX_15, TABLET: SPACE.PX_80 }}
            >
              <WidgetTitle color={COLOR.BLACK}>{title}</WidgetTitle>
            </TitleWrapper>
          </Flex>
        )}
        <SwiperGalleryBox
          margin="auto"
          isLoop
          isMobile={media.MOBILE}
          position="relative"
          isHiddenOnMobile
        >
          <StyledSwiper
            controller={hasCaption ? { control: textSwiper } : {}}
            data-test-class={DATA_TEST_CLASS.GALLERY_WIDGET}
            {...swiperProps}
            pagination={
              hasCaption
                ? false
                : {
                    clickable: true,
                  }
            }
            navigation={{
              prevEl: prevButtonRef.current,
              nextEl: nextButtonRef.current,
            }}
          >
            {cmsGalleryWidgetItems.map(
              ({ id, attachment: { url, alt }, expert }) => (
                <SwiperSlide key={id}>
                  <GalleryItemWrapper slug={expert?.slug}>
                    <Flex flexDirection="column" width="100%" height="100%">
                      <Image
                        data-test-class={DATA_TEST_CLASS.GALLERY_WIDGET_IMAGE}
                        layout={IMAGE_LAYOUT.RESPONSIVE}
                        src={url}
                        alt={alt || GALLERY_ITEM_IMAGE_NAME}
                        aspectRatio={ASPECT_RATIO.GALLERY}
                        sizes="(max-width: 768px) 100vw, (max-width: 1024px) 60vw, (max-width: 1920px) 50vw, 33vw"
                      />
                    </Flex>
                  </GalleryItemWrapper>
                </SwiperSlide>
              )
            )}
            {!media.MOBILE && (
              <SwiperGalleryArrows
                prevButtonRef={prevButtonRef}
                nextButtonRef={nextButtonRef}
                isLoop
              />
            )}
          </StyledSwiper>
        </SwiperGalleryBox>
        <SwiperGalleryBox
          mt={{
            MOBILE: 0,
            TABLET: SPACE.PX_20,
          }}
          ml="auto"
          mr="auto"
          isLoop
          isMobile={media.MOBILE}
          position="relative"
          isHiddenOnMobile
        >
          {hasCaption && (
            <StyledSwiper
              onSwiper={setTextSwiper}
              {...swiperProps}
              pagination={{
                el: paginationRef.current,
                clickable: true,
                bulletActiveClass: BULLET_ACTIVE_CLASS,
                renderBullet: (_, className) =>
                  ReactDOMServer.renderToStaticMarkup(
                    <CarouselPaginationDot
                      className={className}
                      isAutoRotationEnabled={false}
                      animationDuration={4}
                      bulletActiveClass={BULLET_ACTIVE_CLASS}
                      slidesCount={cmsGalleryWidgetItems.length}
                      isDarkTheme
                    />
                  ),
                dynamicBullets: cmsGalleryWidgetItems.length > BULLET_THRESHOLD,
              }}
            >
              {cmsGalleryWidgetItems.map(({ id, expert, caption }) => (
                <SwiperSlide key={id}>
                  <Flex
                    flexDirection="column"
                    width="100%"
                    px={{
                      MOBILE: SPACE.PX_15,
                      TABLET: SPACE.PX_30,
                    }}
                  >
                    {caption && (
                      <Text
                        color={COLOR.BLACK}
                        fontSize={FONT_SIZE.PX_12}
                        fontFamily={FONT_STACK.SERIF}
                        lineHeight={SPACE.PX_15}
                        mt={SPACE.PX_5}
                      >
                        <MarkdownRenderer markdownSource={caption} />
                      </Text>
                    )}
                    {expert && (
                      <Flex
                        flexDirection="column"
                        width="100%"
                        mt={caption ? SPACE.PX_15 : SPACE.PX_30}
                      >
                        <Flex
                          mb={SPACE.PX_15}
                          justifyContent={{
                            MOBILE: 'center',
                            TABLET: 'initial',
                          }}
                        >
                          <Text
                            fontFamily={FONT_STACK.SERIF}
                            fontWeight={FONT_WEIGHT.MEDIUM}
                            color={COLOR.BLACK}
                            fontSize={{
                              MOBILE: FONT_SIZE.PX_20,
                              TABLET: FONT_SIZE.PX_24,
                            }}
                          >
                            {expert?.profileName}
                          </Text>
                        </Flex>
                        <Flex
                          mb={SPACE.PX_15}
                          justifyContent={{
                            MOBILE: 'center',
                            TABLET: 'initial',
                          }}
                        >
                          <StyledText
                            fontSize={{
                              MOBILE: FONT_SIZE.PX_14,
                              TABLET: FONT_SIZE.PX_16,
                            }}
                            color={COLOR.BLACK}
                          >
                            VIEW PROFILE
                          </StyledText>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          )}
        </SwiperGalleryBox>
      </Flex>
      <Flex
        bottom={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_24 }}
        width="100%"
        px={SPACE.PX_16}
        justifyContent="center"
        zIndex={Z_INDEX.CAROUSEL_NAVIGATION_WRAPPER}
        display={{ MOBILE: 'flex', TABLET: 'none' }}
      >
        <CarouselNavigation hasArrows={false} paginationRef={paginationRef} />
      </Flex>
    </>
  )
}

GalleryWidget.propTypes = {
  title: PropTypes.string.isRequired,
  cmsGalleryWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      expert: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        profileName: PropTypes.string.isRequired,
      }),
      attachment: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      caption: PropTypes.string,
    })
  ),
  maxWidgetWidth: PropTypes.number,
}
