import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, SPACE } from 'Theme'
import { getAbsoluteUrl } from 'utils/getAbsoluteUrl'
import { type AlphabeticalItemType } from './types'

const StyledTextLink = styled(LinkWithText)`
  color: ${COLOR_INTENT.WIDGETS.ALPHABETICAL_INDEX.LINK};
  font-size: ${FONT_SIZE.PX_16};
  text-align: center;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

type Props = {
  title: string
  items: AlphabeticalItemType[]
}

export const AlphabeticalItem = ({ title, items = [] }: Props) => (
  <Flex
    mb={{ MOBILE: SPACE.PX_24, DESKTOP: SPACE.PX_48 }}
    mr={SPACE.PX_16}
    flexDirection="column"
  >
    <Box mb={SPACE.PX_16}>
      <Text
        color={COLOR_INTENT.WIDGETS.ALPHABETICAL_INDEX.TITLE}
        fontSize={FONT_SIZE.PX_36}
        fontFamily={FONT_STACK.SERIF}
      >
        {title}
      </Text>
    </Box>
    <Flex flexDirection="column" gap={SPACE.PX_12}>
      {items.map((item, index) => (
        <StyledTextLink href={getAbsoluteUrl(item.url)} key={index}>
          {item.title}
        </StyledTextLink>
      ))}
    </Flex>
  </Flex>
)
