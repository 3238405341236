import { get } from 'lodash'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { BUTTON_VARIANT } from 'components/Button'
import { CardCarousel } from 'components/CardCarousel'
import { ARROW_WITH_BACKGROUND_WRAPPER_HEIGHT } from 'components/CarouselNavigation'
import { CollectionsShowroomPageCard } from 'components/CollectionsShowroomPageCard'
import { Box, Container, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, PAGE_MAX_WIDTH, SPACE, Z_INDEX } from 'Theme'
import 'swiper/css/bundle'
import { type CmsCollectionsCarouselWidget } from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const SWIPER_OFFSET = { MOBILE: 20, DESKTOP: 40 } as const
const SLIDE_WIDTH = { MOBILE: rem(300), TABLET: rem(560) } as const
const FOLD_LEFT_OFFSET = rem(270)
const FOLD_BORDER_SIZE = rem(120)

const Wrapper = styled(Box)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: ${({ isMobile }) =>
      isMobile
        ? '0'
        : `max(calc(((100vw - ${PAGE_MAX_WIDTH.LAYOUT}) / 2) + ${FOLD_LEFT_OFFSET}), ${FOLD_BORDER_SIZE})`};
    right: 0;
    bottom: 0;
    height: 100%;
    background: ${COLOR_INTENT.CAROUSEL.FOLD};
    border-radius: ${FOLD_BORDER_SIZE} 0 0 0;
    z-index: ${Z_INDEX.NOT_SHOWN};
  }
`

const CenteredContainer = styled(Container)`
  text-align: center;
`

export const CollectionsCarouselWidget: React.FC<
  React.PWC<CmsCollectionsCarouselWidget>
> = ({
  title,
  subtitle,
  hasExploreAllButton,
  cmsCollectionsCarouselWidgetItems,
  cmsShowroomPage: cmsShowroomPageLink,
}) => {
  const { MOBILE: isMobile } = useMedia()
  const expandSlug = get(cmsShowroomPageLink, ['cmsPage', 'slug'])
  return (
    <Wrapper
      my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
      isMobile={isMobile}
    >
      <CenteredContainer
        mt={{ MOBILE: SPACE.PX_40, TABLET: SPACE.PX_60 }}
        mb={{ MOBILE: SPACE.PX_20, TABLET: SPACE.PX_40 }}
      >
        {subtitle && (
          <WidgetSubtitle color={COLOR_INTENT.GRAY_90} mb={SPACE.PX_10}>
            {subtitle}
          </WidgetSubtitle>
        )}
        <WidgetTitle color={COLOR_INTENT.GRAY_90} mb={SPACE.PX_10}>
          {title}
        </WidgetTitle>
      </CenteredContainer>
      <CardCarousel
        offset={SWIPER_OFFSET}
        spaceBetween={SWIPER_OFFSET}
        cards={cmsCollectionsCarouselWidgetItems.map(
          ({
            id,
            caption,
            cmsShowroomPage,
            previewImageAttachment,
            profileImageAttachment,
          }) => {
            const cardTitle =
              caption || get(cmsShowroomPage, ['cmsPage', 'title'])
            const slug = get(cmsShowroomPage, ['cmsPage', 'slug'])
            return {
              card: (
                <Box width={SLIDE_WIDTH}>
                  <CollectionsShowroomPageCard
                    title={cardTitle}
                    previewImageAttachment={previewImageAttachment}
                    profileImageAttachment={profileImageAttachment}
                    href={ROUTE.SHOWROOM_DETAIL({ slug })}
                  />
                </Box>
              ),
              key: id,
            }
          }
        )}
        arrowsOffsetTop={`calc(1rem + (${SLIDE_WIDTH.TABLET} / 2) - (${ARROW_WITH_BACKGROUND_WRAPPER_HEIGHT} / 2))`} // centers arrow according to image (1 rem in the beginning is top padding of swiper)
      />
      {hasExploreAllButton && (
        <Flex justifyContent="center" mt={SPACE.PX_24} mb={SPACE.PX_40}>
          <StyledLink
            href={ROUTE.SHOWROOM_DETAIL({ slug: expandSlug })}
            variant={BUTTON_VARIANT.OUTLINE}
            aria-label="Explore all"
          >
            Explore all
          </StyledLink>
        </Flex>
      )}
    </Wrapper>
  )
}
