import React from 'react'
import styled, { css } from 'styled-components'
import { Image } from 'components/Image'
import { Flex } from 'components/Layout'
import { OptionalLink } from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import {
  COLOR_INTENT,
  FONT_SIZE,
  IMAGE_BASE_WIDTH,
  RADIUS,
  SPACE,
  mq,
} from 'Theme'
import { type Attachment } from 'types/graphql-generated'

const StyledImage = styled(Image)`
  border-radius: ${RADIUS.PX_8};
`

const Wrapper = styled.article`
  ${({ isFullWidthOnMobile }) =>
    isFullWidthOnMobile &&
    css`
      ${mq.to.TABLET`
        grid-column-start: 1;
        grid-column-end: 3;
      `}
    `}
`

interface ColumnCardProps {
  title: string
  link?: string
  image: Attachment
  isFullWidthOnMobile?: boolean
}

export const ColumnCard: React.FC<React.PWC<ColumnCardProps>> = ({
  title,
  link,
  image,
  isFullWidthOnMobile,
}) => (
  <Wrapper
    isFullWidthOnMobile={isFullWidthOnMobile}
    itemScope
    itemType={SCHEMA_TYPES.LIST_ITEM}
  >
    <OptionalLink href={link} itemProp={SCHEMA_PROPS.URL}>
      <Flex
        position="relative"
        width="100%"
        flexDirection="column"
        mb={{ MOBILE: SPACE.PX_16, TABLET: 0 }}
      >
        <StyledImage
          alt={image.alt || title}
          objectFit="cover"
          aspectRatio={ASPECT_RATIO.WIDGET_ITEM_COLUMN_CARD}
          src={image.url}
          baseWidth={IMAGE_BASE_WIDTH.PX_500}
          itemProp={SCHEMA_PROPS.IMAGE}
        />
        <Text
          mt={SPACE.PX_12}
          textAlign="center"
          fontSize={{ MOBILE: FONT_SIZE.PX_14, TABLET: FONT_SIZE.PX_16 }}
          color={COLOR_INTENT.WIDGETS.SECTION_WIDGET.TEXT}
          itemProp={SCHEMA_PROPS.NAME}
        >
          {title}
        </Text>
      </Flex>
    </OptionalLink>
  </Wrapper>
)
