import queryString from 'query-string'
import { useState } from 'react'
import { useLocation } from 'react-use'
import { Toast } from 'components/Toast'
import {
  FavoriteExpertsSlugsDocument,
  useAddExpertToFavoritesMutation,
  useFavoriteExpertsSlugsQuery,
  useRemoveExpertFromFavoritesMutation,
} from 'types/graphql-generated'

export const useClientFavoriteExpertsManagement = ({
  clientId,
}: {
  clientId: number
}) => {
  const location = useLocation()
  const {
    isAuthModalOpen: isAuthModalOpenParam,
    expertId: expertIdParam,
    slug: expertSlugParam,
  } = queryString.parse(location.search)

  const [clientFavoriteExpertsSlugs, setClientFavoriteExpertsSlugs] = useState(
    []
  )

  const { loading: isFavoriteExpertsSlugsLoading } =
    useFavoriteExpertsSlugsQuery({
      variables: {
        id: clientId,
      },
      skip: !clientId,
      onCompleted: ({ clientMe }) => {
        setClientFavoriteExpertsSlugs(clientMe?.favoriteExpertsSlugs)
      },
    })

  const [executeAddExpertToFavorite, { loading: isAddExpertLoading }] =
    useAddExpertToFavoritesMutation()
  const [executeRemoveExpertFromFavorite, { loading: isRemoveExpertLoading }] =
    useRemoveExpertFromFavoritesMutation()

  return {
    isLoading:
      isAddExpertLoading ||
      isRemoveExpertLoading ||
      isFavoriteExpertsSlugsLoading,
    isAuthModalOpen: !!isAuthModalOpenParam,
    expertId: expertIdParam,
    slug: expertSlugParam,
    clientFavoriteExpertsSlugs,
    addExpertToFavorites: async ({ expertId }) => {
      const response = await executeAddExpertToFavorite({
        variables: {
          expertId: parseInt(expertId),
        },
        awaitRefetchQueries: true,
        refetchQueries: clientId
          ? [
              {
                query: FavoriteExpertsSlugsDocument,
                variables: {
                  id: clientId,
                },
              },
            ]
          : [],
      })
      if (!response.errors) {
        Toast.success('You successfully saved the Expert')
      }
    },
    removeExpertFromFavorites: async ({ expertId }) => {
      await executeRemoveExpertFromFavorite({
        variables: {
          expertId: parseInt(expertId),
        },
        awaitRefetchQueries: true,
        refetchQueries: clientId
          ? [
              {
                query: FavoriteExpertsSlugsDocument,
                variables: {
                  id: clientId,
                },
              },
            ]
          : [],
      })
    },
  }
}
