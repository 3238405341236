import { gql } from '@apollo/client'
import { get } from 'lodash'
import { useMemo } from 'react'
import { useFetchNextPageQuery } from 'hooks'
import { formatFilter } from '../utils/collections'

export const COLLECTION_PRODUCTS_QUERY = gql`
  query collection(
    $collectionId: Int!
    $productsInput: ProductsInput!
    $variantStatuses: [ProductVariantStatus!]
  ) {
    collection(id: $collectionId) {
      id
      products(input: $productsInput) {
        data {
          id
          sku
          title
          slug
          variants(statuses: $variantStatuses) {
            id
            title
            quantity
            price {
              total
              base
              currencyIsoCode
            }
          }
          images {
            url
            alt
          }
        }
        paginationInfo {
          totalCount
          nextPage
        }
      }
    }
  }
`
export const useFetchMoreCollectionProducts = ({
  productStatuses,
  variantStatuses,
  collectionId,
  pageSize,
  sorter,
  filter,
  maxPrice,
}) => {
  const { loading, data, previousData, fetchNextPage, refetch } =
    useFetchNextPageQuery(COLLECTION_PRODUCTS_QUERY, {
      fetchPolicy: 'cache-first',
      pageSize,
      skip: !collectionId,
      variablesFactory: (pagination) => ({
        collectionId,
        variantStatuses,
        productsInput: {
          pagination,
          sorter,
          filter: {
            statuses: productStatuses,
            variantStatuses,
            ...formatFilter({ filter, maxPrice: maxPrice.total }),
          },
        },
      }),
    })

  const { productsList, nextPage, totalCount } = useMemo(() => {
    const { data: productsData, paginationInfo } = get(
      loading ? previousData : data,
      ['collection', 'products'],
      { data: [], paginationInfo: { nextPage: null, totalCount: 0 } }
    )
    return { productsList: productsData, ...paginationInfo }
  }, [loading, data, previousData])

  return {
    isLoading: loading,
    isMoreButtonVisible: totalCount > productsList.length || !!nextPage,
    totalProductCount: totalCount,
    productsList,
    fetchNextPage,
    refetch,
  }
}
