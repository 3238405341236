import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { WIDGET_PLACEMENT } from 'constants/common'
import { ROUTE } from 'constants/routes'
import {
  SPACE,
  FONT_SIZE,
  FONT_WEIGHT,
  ARTICLE_MAX_WIDTH,
  COLOR_INTENT,
} from 'Theme'
import { WidgetTitle } from '../WidgetTitle'

export const ArticleHeadingWidget = ({ title, author, caption }) => {
  const { pathname } = useRouter()
  const isArticlePage = pathname.startsWith(ROUTE.BLOG_LIST())
  const DESKTOP_TEXT_ALIGN = isArticlePage ? 'center' : 'left'

  return (
    <Flex
      width="100%"
      flexDirection="column"
      my={SPACE.PX_20}
      px={{ DESKTOP: isArticlePage ? SPACE.PX_15 : 'unset' }}
      maxWidth={{ DESKTOP: isArticlePage ? ARTICLE_MAX_WIDTH.TEXT : 'unset' }}
      mx={{ DESKTOP: isArticlePage ? 'auto' : 'unset' }}
      data-test-class={DATA_TEST_CLASS.ARTICLE_HEADING_WIDGET}
      color={COLOR_INTENT.WIDGETS.ARTICLE_HEADING}
    >
      {caption && (
        <Text
          fontSize={FONT_SIZE.PX_16}
          fontWeight={FONT_WEIGHT.NORMAL}
          mb={SPACE.PX_12}
          textAlign={{ DESKTOP: DESKTOP_TEXT_ALIGN }}
        >
          {caption}
        </Text>
      )}
      <WidgetTitle
        placement={WIDGET_PLACEMENT.HERO}
        mb={SPACE.PX_24}
        textAlign={{ DESKTOP: DESKTOP_TEXT_ALIGN }}
      >
        {title}
      </WidgetTitle>
      <Text
        fontSize={FONT_SIZE.PX_16}
        fontWeight={FONT_WEIGHT.NORMAL}
        textAlign={{ DESKTOP: DESKTOP_TEXT_ALIGN }}
      >
        {author}
      </Text>
    </Flex>
  )
}

ArticleHeadingWidget.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  caption: PropTypes.string,
}
