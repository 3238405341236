import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Container, Flex, Grid, Box } from 'components/Layout'
import { SharpStyledLink } from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  IMAGE_BASE_WIDTH,
  SPACE,
} from 'Theme'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const SPOTLIGHT_IMAGE_NAME = 'Spotlight widget image'
const TABLET_IMAGE_WRAPPER_MAX_WIDTH = '533px'

const Wrapper = styled(Box)`
  background: ${COLOR_INTENT.WIDGETS.SPOTLIGHT.BACKGROUD};
`

export const SpotlightWidget = ({
  title,
  attachment,
  subTitle,
  buttonText,
  buttonLink,
  description,
}) => (
  <Wrapper
    data-test-class={DATA_TEST_CLASS.SPOTLIGHT_WIDGET}
    height={{ MOBILE: 'initial' }}
    my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
  >
    <Container p={0}>
      <Grid
        gridTemplateColumns={{ MOBILE: '1fr', TABLET: '1fr 1fr' }}
        gridGap={SPACE.PX_60}
        height="100%"
      >
        <Flex
          flexDirection="column"
          display={{ MOBILE: 'none', TABLET: 'flex' }}
          ml={{ MOBILE: 0, TABLET: SPACE.PX_15, DESKTOP: 0 }}
          width="100%"
          height="100%"
          maxWidth={TABLET_IMAGE_WRAPPER_MAX_WIDTH}
        >
          <Image
            src={attachment.url}
            alt={attachment.alt || SPOTLIGHT_IMAGE_NAME}
            layout={IMAGE_LAYOUT.RESPONSIVE}
            aspectRatio={ASPECT_RATIO.SPOTLIGHT}
          />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
          maxWidth={{ MOBILE: '100%', TABLET: '390px' }}
          width="100%"
          pr={{ MOBILE: 0, TABLET: SPACE.PX_15, DESKTOP: 0 }}
        >
          <Flex
            p={{ MOBILE: `${SPACE.PX_30} ${SPACE.PX_20}`, TABLET: 0 }}
            flexDirection="column"
            justifyContent="center"
            alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
            width="100%"
          >
            <WidgetSubtitle
              fontFamily={FONT_STACK.SANS}
              color={COLOR_INTENT.WIDGETS.SPOTLIGHT.SUBTITLE_COLOR}
              textAlign={{ MOBILE: 'center', TABLET: 'initial' }}
            >
              {subTitle}
            </WidgetSubtitle>
            <WidgetTitle
              color={COLOR_INTENT.WIDGETS.SPOTLIGHT.TITLE_COLOR}
              mb={{ MOBILE: 0, TABLET: SPACE.PX_30 }}
              textAlign={{ MOBILE: 'center', TABLET: 'initial' }}
            >
              {title}
            </WidgetTitle>
          </Flex>
          <Flex
            flexDirection="column"
            display={{ MOBILE: 'flex', TABLET: 'none' }}
            width="100%"
            height="100%"
          >
            <Image
              src={attachment.url}
              alt={attachment.alt || SPOTLIGHT_IMAGE_NAME}
              baseWidth={IMAGE_BASE_WIDTH.PX_800}
              aspectRatio={ASPECT_RATIO.SPOTLIGHT}
            />
          </Flex>
          <Text
            fontSize={FONT_SIZE.PX_16}
            mb={{ MOBILE: SPACE.PX_20, TABLET: SPACE.PX_30 }}
            p={{
              MOBILE: `${SPACE.PX_30} ${SPACE.PX_20} 0 ${SPACE.PX_20}`,
              TABLET: 0,
            }}
            textAlign={{ MOBILE: 'center', TABLET: 'initial' }}
          >
            {description}
          </Text>
          <Flex mb={{ MOBILE: SPACE.PX_30, TABLET: 0 }}>
            <SharpStyledLink href={buttonLink} aria-label={buttonText}>
              {buttonText}
            </SharpStyledLink>
          </Flex>
        </Flex>
      </Grid>
    </Container>
  </Wrapper>
)

SpotlightWidget.propTypes = {
  title: PropTypes.string.isRequired,
  attachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  subTitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  description: PropTypes.string,
}
