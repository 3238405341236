import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/Button'
import {
  CONTAINER_HORIZONTAL_PADDING,
  Container,
  Flex,
  Grid,
} from 'components/Layout'
import { H2 } from 'components/Typography'
import { environment } from 'constants/environment'
import { COLOR_INTENT, HEADING_VARIANT, SPACE } from 'Theme'
import {
  ConciergeWidgetItem,
  type ConciergeWidgetItemProps,
} from './ConciergeWidgetItem'

const FIRST_COLUMN_WIDTH = {
  TABLET: rem(250),
  DESKTOP: rem(360),
}

const StyledSection = styled('section')`
  background: ${COLOR_INTENT.WIDGETS.CONCIERGE.BACKGROUND};
`

const conciergeWidgetItems: ConciergeWidgetItemProps[] = [
  {
    title: 'At Your Service',
    description:
      'Our world-class procurement team can help bring your vision to life—all free of charge.',
  },
  {
    title: 'Insider Access',
    description:
      'Our design concierge will give you exclusive access to ‘trade only’ brands and help you place your order.',
  },
  {
    title: 'Easy Shipping',
    description:
      'Every Showroom order includes fully managed tracking and delivery of all items.',
  },
]

export const ConciergeWidget: React.FC<React.PWC<unknown>> = () => {
  const handleClickGetInTouch = () => {
    if (environment.CUSTOMER_SERVICE_FRAMEWORK === 'intercom') {
      window?.Intercom?.('show')
    } else {
      window?.FrontChat?.('show')
    }
  }

  return (
    <StyledSection my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}>
      <Container
        px={{
          MOBILE: 0,
          TABLET: CONTAINER_HORIZONTAL_PADDING,
        }}
      >
        <Grid
          gridTemplateColumns={{
            MOBILE: '1fr',
            TABLET: `${FIRST_COLUMN_WIDTH.TABLET} 1fr`,
            DESKTOP: `${FIRST_COLUMN_WIDTH.DESKTOP} 1fr`,
          }}
          gridGap={SPACE.PX_32}
          py={{
            MOBILE: SPACE.PX_32,
            TABLET: SPACE.PX_56,
          }}
          px={{
            MOBILE: SPACE.PX_16,
            TABLET: 0,
          }}
        >
          <Flex
            flexDirection="column"
            gap={{
              MOBILE: SPACE.PX_16,
              TABLET: SPACE.PX_32,
            }}
          >
            <H2 variant={HEADING_VARIANT.SERIF} m={0}>
              Your personal concierge
            </H2>
            <Button width="max-content" onClick={handleClickGetInTouch}>
              Get in touch
            </Button>
          </Flex>
          <Grid
            gridTemplateColumns={{
              MOBILE: '1fr',
              TABLET: '1fr 1fr 1fr',
            }}
            gridGap={{
              MOBILE: SPACE.PX_32,
              TABLET: SPACE.PX_16,
              DESKTOP: SPACE.PX_32,
            }}
          >
            {conciergeWidgetItems.map((item, index) => (
              <ConciergeWidgetItem key={index} {...item} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  )
}
