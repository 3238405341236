import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex, Grid } from 'components/Layout'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  COLLECTION_HEADER_WIDGET_TYPE,
  WIDGET_PLACEMENT,
} from 'constants/common'
import { COLOR_INTENT, SPACE, Z_INDEX } from 'Theme'
import { type CmsCollectionHeaderWidget } from 'types/graphql-generated'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const IMAGE_ALT = 'Collection header image'
const IMAGE_MIN_HEIGHT = { MOBILE: null, TABLET: rem(432) }
const WIDGET_TEXT_HEIGHT = { MOBILE: '100%', TABLET: rem(500) }
const WIDGET_TEXT_PADDING = { MOBILE: rem(40), TABLET: rem(140) }

const WidgetTextGrid = styled(Grid)`
  text-align: center;
`

export const CollectionHeaderWidget: React.FC<
  React.PWC<CmsCollectionHeaderWidget>
> = ({
  title,
  subtitle,
  headerImageAttachment,
  mobileImageAttachment,
  collectionHeaderType,
}) => {
  const textColor =
    collectionHeaderType === COLLECTION_HEADER_WIDGET_TYPE.WHITE_TEXT_ON_DARK
      ? COLOR_INTENT.TEXT_ON_DARK
      : COLOR_INTENT.GRAY_90
  const responsiveTextColor = {
    MOBILE: mobileImageAttachment ? textColor : null,
    TABLET: textColor,
  }
  return (
    <Box
      px={0}
      width="100%"
      minHeight={IMAGE_MIN_HEIGHT}
      position="relative"
      data-test-class={DATA_TEST_CLASS.COLLECTION_HEADER_WIDGET}
    >
      <Box
        width="100%"
        height="100%"
        minHeight={IMAGE_MIN_HEIGHT}
        position="absolute"
        zIndex={Z_INDEX.BACKGROUND_IMAGE}
      >
        <MediaQueryWrapper
          mobile={
            mobileImageAttachment && (
              <Image
                src={mobileImageAttachment.url}
                alt={mobileImageAttachment.alt || IMAGE_ALT}
                layout={IMAGE_LAYOUT.FILL}
                aspectRatio={ASPECT_RATIO.COLLECTION_HEADER_MOBILE}
                objectFit="cover"
                objectPosition="50% 100%"
              />
            )
          }
          tablet={
            <Image
              src={headerImageAttachment.url}
              alt={headerImageAttachment.alt || IMAGE_ALT}
              layout={IMAGE_LAYOUT.FILL}
              aspectRatio={ASPECT_RATIO.COLLECTION_HEADER}
              objectFit="cover"
              objectPosition="50% 100%"
            />
          }
        />
      </Box>
      <Box width="100%" height="100%">
        <Flex
          margin="0 auto"
          width={WIDGET_TEXT_HEIGHT}
          px={{ MOBILE: SPACE.PX_12, TABLET: 0 }}
          py={WIDGET_TEXT_PADDING}
        >
          <Flex width="100%" justifyContent="center" alignItems="center">
            <WidgetTextGrid gridGap={SPACE.PX_12}>
              <WidgetTitle
                placement={WIDGET_PLACEMENT.HERO}
                color={responsiveTextColor}
                mb={0}
              >
                {title}
              </WidgetTitle>
              {subtitle && (
                <WidgetSubtitle mt={0} color={responsiveTextColor}>
                  {subtitle}
                </WidgetSubtitle>
              )}
            </WidgetTextGrid>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
