import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Grid, Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { ARTICLES_WIDGET_LIMIT } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { SPACE, COLOR, FONT_SIZE, FONT_WEIGHT, FONT_STACK } from 'Theme'
import { useMedia } from 'useMedia'
import { multilineEllipsis } from 'utils/formatters'
import { ArticlesWidget } from '../ArticlesWidget'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const RELATED_ARTICLE_PREVIEW_IMAGE_NAME = 'Related article preview image'

const RelatedArticlesWidgetWrapper = styled(Grid)`
  border: 1px solid ${COLOR.GRAYSCALE_4};
`

const RelatedArticleWrapper = styled(Grid)`
  border-top: 1px solid ${COLOR.GRAYSCALE_4};
`

const chooseImageAlt = (previewAttachment, title) => {
  if (previewAttachment?.alt) {
    return previewAttachment.alt
  }
  return title
    ? `The preview image of an ${title} related article`
    : RELATED_ARTICLE_PREVIEW_IMAGE_NAME
}

export const RelatedArticlesWidget = ({
  widgetId,
  cmsPageSlug,
  title,
  subtitle,
  cmsArticlesWidgetItems,
}) => {
  const media = useMedia()

  if (media.MOBILE || media.TABLET) {
    return (
      <ArticlesWidget
        title={title}
        subtitle={subtitle}
        cmsPageSlug={cmsPageSlug}
        widgetId={widgetId}
      />
    )
  }
  return (
    <RelatedArticlesWidgetWrapper
      mr={SPACE.PX_10}
      data-test-class={DATA_TEST_CLASS.RELATED_ARTICLES_WIDGET}
    >
      {(title || subtitle) && (
        <Flex alignItems="center" p={SPACE.PX_15} flexDirection="column">
          {title && <WidgetTitle>{title}</WidgetTitle>}
          <Flex display={{ MOBILE: 'none' }}>
            {subtitle && <WidgetSubtitle>{subtitle}</WidgetSubtitle>}
          </Flex>
        </Flex>
      )}
      {(cmsArticlesWidgetItems.data ?? [])
        .slice(0, ARTICLES_WIDGET_LIMIT.DESKTOP)
        .map(
          ({
            article: {
              slug,
              title: previewTitle,
              previewText,
              previewAttachment,
            },
            id,
          }) => (
            <Link
              href={ROUTE.BLOG_DETAIL({ slug })}
              key={id}
              aria-label="blog detail"
            >
              <RelatedArticleWrapper
                gridTemplateColumns={{ DESKTOP: '1fr 1.5fr' }}
                p={SPACE.PX_20}
                gridGap={SPACE.PX_30}
              >
                <Image
                  src={previewAttachment?.url}
                  alt={chooseImageAlt(previewAttachment, title)}
                  layout={IMAGE_LAYOUT.RESPONSIVE}
                  aspectRatio={ASPECT_RATIO.ARTICLE_PREVIEW_IMAGE}
                  sizes="(max-width: 1920px) 15vw, 10vw"
                />
                <Flex justifyContent="center" flexDirection="column">
                  <Text
                    mb={SPACE.PX_20}
                    fontWeight={FONT_WEIGHT.BOLD}
                    color={COLOR.BLACK}
                    fontSize={FONT_SIZE.PX_12}
                    fontFamily={FONT_STACK.SERIF}
                  >
                    {multilineEllipsis(previewTitle)}
                  </Text>
                  <Text
                    color={COLOR.BLACK}
                    fontSize={FONT_SIZE.PX_12}
                    fontFamily={FONT_STACK.SERIF}
                  >
                    {multilineEllipsis(previewText)}
                  </Text>
                </Flex>
              </RelatedArticleWrapper>
            </Link>
          )
        )}
    </RelatedArticlesWidgetWrapper>
  )
}

RelatedArticlesWidget.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  widgetId: PropTypes.number.isRequired,
  cmsPageSlug: PropTypes.string.isRequired,
  cmsArticlesWidgetItems: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        article: PropTypes.shape({
          slug: PropTypes.string.isRequired,
          previewText: PropTypes.string.isRequired,
          previewAttachment: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string,
            name: PropTypes.string.isRequired,
          }),
        }).isRequired,
      })
    ),
  }),
}
