import { transparentize } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { ROUTE } from 'constants/routes'
import { COLOR, FONT_SIZE, SPACE, FONT_STACK, FONT_WEIGHT } from 'Theme'

const Wrapper = styled(Flex)`
  background-color: ${transparentize(0.5, COLOR.PRIMARY_2)};
`

interface ArticleCardProps {
  slug: string
  title: string
  description: string
  tag?: string
  image?: {
    url: string
    alt: string
  }
}

export const ArticleCard: React.FC<React.PWC<ArticleCardProps>> = ({
  slug,
  title,
  description,
  tag,
  image,
}) => (
  <Link
    href={ROUTE.BLOG_DETAIL({ slug })}
    data-test-class={DATA_TEST_CLASS.ARTICLES_WIDGET_CARD}
    aria-label={`${tag} ${title}${description ? ` ${description}` : ''}`}
  >
    <Wrapper flexDirection="column">
      <Image
        layout={IMAGE_LAYOUT.RESPONSIVE}
        src={image?.url}
        alt={image?.alt || `The preview image of an ${title} article`}
        aspectRatio={ASPECT_RATIO.ARTICLE_PREVIEW_IMAGE}
        sizes="(max-width: 768px) 100vw, (max-width) 2048px) 22.75rem, 73.75rem"
      />
      <Flex p={`0 ${SPACE.PX_10} ${SPACE.PX_10}`} flexDirection="column">
        <Text
          mt={SPACE.PX_30}
          color={COLOR.PRIMARY_1}
          fontSize={FONT_SIZE.PX_12}
          fontWeight={FONT_WEIGHT.MEDIUM}
        >
          {`${tag} `}
        </Text>
        <Text
          mt={SPACE.PX_15}
          color={COLOR.BLACK}
          fontSize={FONT_SIZE.PX_24}
          fontFamily={FONT_STACK.SERIF}
        >
          {`${title}${description ? ' ' : ''}`}
        </Text>
        {description && (
          <Text
            mt={SPACE.PX_15}
            color={COLOR.BLACK}
            fontFamily={FONT_STACK.SERIF}
          >
            {description}
          </Text>
        )}
      </Flex>
    </Wrapper>
  </Link>
)
