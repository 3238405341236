import { useRouter } from 'next/router'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Flex } from 'components/Layout'
import { QA } from 'components/QA'
import { ROUTE } from 'constants/routes'
import { ARTICLE_MAX_WIDTH, SPACE } from 'Theme'

interface QAWidgetProps {
  cmsQAWidgetItems: {
    id: number
    question: string
    answer: string
    slug: string
  }[]
}

export const QAWidget: React.FC<React.PWC<QAWidgetProps>> = ({
  cmsQAWidgetItems = [],
}) => {
  const { pathname } = useRouter()
  const isArticlePage = pathname.startsWith(ROUTE.BLOG_LIST())
  return (
    <Flex
      maxWidth={{ DESKTOP: isArticlePage ? ARTICLE_MAX_WIDTH.FAQ : 'unset' }}
      flexDirection="column"
      mx={{ DESKTOP: isArticlePage ? 'auto' : 'unset' }}
      px={{ DESKTOP: isArticlePage ? SPACE.PX_15 : 'unset' }}
    >
      {cmsQAWidgetItems.map(({ slug, id, question, answer }) => (
        <QA
          id={slug}
          key={id}
          title={question}
          data-test-class={DATA_TEST_CLASS.QA_WIDGET_ITEM}
        >
          <QA.Markdown source={answer} />
        </QA>
      ))}
    </Flex>
  )
}
