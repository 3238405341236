import { get } from 'lodash'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { WIDGET_NAMES } from '@admin/routes/Cms/components/Widgets/constants'
import { CollectionsShowroomPageCard } from 'components/CollectionsShowroomPageCard'
import Icon from 'components/Icon'
import { Flex, Grid } from 'components/Layout'
import NoData from 'components/NoData'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { PAGE_MAX_WIDTH, SPACE } from 'Theme'

const DESKTOP_WIDTH = rem(560)

export const ExpertCollectionsWidget = ({
  cmsExpertCollectionsWidgetItems,
}) => (
  <Flex
    flexDirection="column"
    width="100%"
    alignItems="center"
    position="relative"
    px={{ MOBILE: SPACE.PX_15, DESKTOP: 0 }}
    pb={{ MOBILE: SPACE.PX_30, DESKTOP: SPACE.PX_60 }}
    itemScope
    itemType={SCHEMA_TYPES.THING}
  >
    {cmsExpertCollectionsWidgetItems.length > 0 ? (
      <>
        <meta
          itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
          content={WIDGET_NAMES.EXPERT_COLLECTIONS.name}
        />
        <Grid
          mb={SPACE.PX_40}
          pt={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
          width="100%"
          maxWidth={PAGE_MAX_WIDTH.LAYOUT}
          gridGap={SPACE.PX_40}
          gridAutoRows={{ MOBILE: 'auto', TABLET: '1fr' }}
          gridTemplateColumns={{
            MOBILE: '1fr',
            TABLET: '1fr 1fr',
          }}
          itemScope
          itemType={SCHEMA_TYPES.ITEM_LIST}
        >
          {cmsExpertCollectionsWidgetItems.map(
            (
              {
                id,
                caption,
                cmsShowroomPage,
                expert,
                previewImageAttachment,
                profileImageAttachment,
              },
              index
            ) => {
              const title =
                caption || get(cmsShowroomPage, ['cmsPage', 'title'])
              const slug = get(cmsShowroomPage, ['cmsPage', 'slug'])

              const href = expert
                ? ROUTE.FIND_AN_EXPERT_SHOWROOM_TAB_DYNAMIC({
                    slug: expert.slug,
                  })
                : ROUTE.SHOWROOM_DETAIL({ slug })

              return (
                <CollectionsShowroomPageCard
                  key={id}
                  title={title}
                  index={index}
                  previewImageAttachment={previewImageAttachment}
                  profileImageAttachment={profileImageAttachment}
                  width={{ MOBILE: '100%', TABLET: 'auto' }}
                  maxWidth={{ MOBILE: 'auto', TABLET: DESKTOP_WIDTH }}
                  href={href}
                />
              )
            }
          )}
        </Grid>
      </>
    ) : (
      <NoData
        mt={SPACE.PX_30}
        icon={Icon.Basket}
        header="No expert collections available"
      />
    )}
  </Flex>
)

ExpertCollectionsWidget.propTypes = {
  cmsExpertCollectionsWidgetItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      caption: PropTypes.string,
      cmsShowroomPage: PropTypes.shape({
        id: PropTypes.number.isRequired,
        cmsPage: PropTypes.shape({
          title: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      previewImageAttachment: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      profileImageAttachment: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}
