import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import styled from 'styled-components'
import { CardCarousel } from 'components/CardCarousel'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { useLightbox } from 'hooks'
import { useSwiperState } from 'hooks/useSwiperState'
import { type CmsCategoryHeaderWidgetItem } from 'types/graphql-generated'

const SLIDES_PER_VIEW = { MOBILE: 1.2, DESKTOP: 1.5 }
const CAROUSEL_OFFSET = { MOBILE: 0, DESKTOP: 0 }
const SPACE_BETWEEN_SLIDES = { MOBILE: 1, DESKTOP: 1 }

const calculateImageIndex = (index: number, imageCount: number) =>
  (index + imageCount) % imageCount

const SwiperWrapper = styled(Box)`
  user-select: none;
  cursor: zoom-in;
`

interface CategoryHeaderCarouselProps {
  images: CmsCategoryHeaderWidgetItem[]
}

export const CategoryHeaderCarousel: React.FC<
  React.PWC<CategoryHeaderCarouselProps>
> = ({ images }) => {
  const [swiper, setSwiper] = useSwiperState()
  const { isLightboxOpen, openLightbox, closeLightbox, lightboxRef } =
    useLightbox()
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  const imageCount = images.length
  const prevImageIndex = calculateImageIndex(activeImageIndex - 1, imageCount)
  const nextImageIndex = calculateImageIndex(activeImageIndex + 1, imageCount)

  const slideToIndex = (index: number) => {
    swiper.slideToLoop(index)
  }

  return (
    <>
      <SwiperWrapper overflow="hidden">
        <CardCarousel
          loop
          swiper={swiper}
          setSwiper={setSwiper}
          slidesPerView={SLIDES_PER_VIEW}
          offset={CAROUSEL_OFFSET}
          spaceBetween={SPACE_BETWEEN_SLIDES}
          onSlideChange={(currentSwiperInstance) => {
            setActiveImageIndex(currentSwiperInstance.realIndex)
          }}
          cards={images.map(({ id, attachment: { url, alt } }, index) => ({
            card: (
              <Image
                aspectRatio={ASPECT_RATIO.CATEGORY_HEADER_CAROUSEL}
                alt={alt || `Category image #${index}`}
                layout={IMAGE_LAYOUT.RESPONSIVE}
                objectFit="cover"
                src={url}
                sizes="(max-width: 768px) 60vw, (max-width: 1920px) 40vw, 30vw"
                onClick={() => {
                  slideToIndex(calculateImageIndex(index, imageCount))
                  openLightbox()
                }}
              />
            ),
            key: id,
          }))}
          hasArrowsCentered
        />
      </SwiperWrapper>
      {isLightboxOpen && (
        <Lightbox
          ref={lightboxRef}
          mainSrc={images[activeImageIndex]?.attachment.url}
          prevSrc={images[prevImageIndex]?.attachment.url}
          nextSrc={images[nextImageIndex]?.attachment.url}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => {
            slideToIndex(prevImageIndex)
          }}
          onMoveNextRequest={() => {
            slideToIndex(nextImageIndex)
          }}
        />
      )}
    </>
  )
}
