import { DialogContent, DialogOverlay } from '@reach/dialog'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { rem, transparentize } from 'polished'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { BUTTON_ICON_POSITION } from 'components/Button'
import Icon from 'components/Icon'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Container, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { WIDGET_PLACEMENT } from 'constants/common'
import { GA4_EVENTS, useGoogleAnalytics } from 'providers/googleAnalytics'
import {
  COLOR_INTENT,
  LINE_HEIGHT,
  PAGE_MAX_WIDTH,
  RADIUS,
  SPACE,
  Z_INDEX,
} from 'Theme'
import {
  type Attachment,
  type CmsVideoSectionWidget,
} from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const THUMBNAIL_ELEMENT_NAME = 'videoWidgetThumbnail'

const FOLD_LEFT_OFFSET = rem(90)
const FOLD_BORDER_SIZE = rem(120)
const TEXT_BOX_SIZE_WIDTH = {
  MOBILE: '90%',
  TABLET: rem(516),
}
const ICON_PLAY_SIZE = rem(84)
const MODAL_VIDEO_MAX_WIDTH = rem(1200)

const TextWrapper = styled(Flex)`
  svg {
    rect,
    path {
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    }
    &:hover {
      rect,
      path {
        transform: scale(0.95);
        transform-origin: center;
      }
    }
  }
`

const Overlay = styled(DialogOverlay)`
  overflow: hidden;
  z-index: ${Z_INDEX.OVERLAY};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-content: center;
  background-color: ${transparentize(
    0.2,
    COLOR_INTENT.WIDGETS.VIDEO_SECTION.OVERLAY
  )};
`

const ThumbnailWrapper = styled(Flex)`
  cursor: pointer;
  @media screen and (max-width: 375px) {
    min-height: 211px;
  }
`

const Content = styled(DialogContent)`
  outline: none;
  width: 100%;
  max-width: ${MODAL_VIDEO_MAX_WIDTH};
  justify-content: center;
`

const afterSharedStyles = css`
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background: ${COLOR_INTENT.WIDGETS.VIDEO_SECTION.BACKGROUND_COLOR};
  z-index: ${Z_INDEX.NOT_SHOWN};
`

const MobileWrapper = styled(Box)`
  &:after {
    ${afterSharedStyles}
    right: 0;
    border-top-right-radius: ${RADIUS.PX_48};
`

const TabletWrapper = styled(Box)`
  &:after {
    ${afterSharedStyles}
    right: max(calc(((100vw - ${PAGE_MAX_WIDTH.LAYOUT}) / 2) + ${FOLD_LEFT_OFFSET}), ${FOLD_BORDER_SIZE});
    border-top-right-radius: ${RADIUS.PX_80};`

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type VideoSectionWidgetProps = {
  [P in keyof CmsVideoSectionWidget]: CmsVideoSectionWidget[P]
} & {
  thumbnail: Attachment
  video: Attachment
}

export const VideoSectionWidget: React.FC<
  React.PWC<VideoSectionWidgetProps>
> = ({
  video,
  thumbnail,
  title,
  ctaCopy,
  ctaCopyUrl,
  hasCtaButton,
  subtitle,
}) => {
  const contentRef = React.useRef(null)
  const { asPath } = useRouter()
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const { executeDataToDataLayer } = useGoogleAnalytics()
  const { MOBILE: isMobile } = useMedia()

  const containerWrapper = (
    <Container p={{ MOBILE: 0, TABLET: SPACE.PX_15 }}>
      <Flex
        flexDirection={{ MOBILE: 'column', TABLET: 'row' }}
        alignItems="center"
      >
        <Flex
          flex="1"
          justifyContent={{ MOBILE: 'center', TABLET: 'flex-start' }}
          width="100%"
        >
          <Flex
            flexDirection="column"
            maxWidth={TEXT_BOX_SIZE_WIDTH}
            mr={{ MOBILE: '0', TABLET: SPACE.PX_40 }}
          >
            <WidgetTitle
              placement={WIDGET_PLACEMENT.HERO}
              textAlign={{ MOBILE: 'center', TABLET: 'left' }}
              mb={SPACE.PX_12}
              lineHeight={{ MOBILE: LINE_HEIGHT.XXS, TABLET: LINE_HEIGHT.XXS }}
            >
              {title}
            </WidgetTitle>
            <WidgetSubtitle
              mb={SPACE.PX_24}
              textAlign={{ MOBILE: 'center', TABLET: 'left' }}
            >
              {subtitle}
            </WidgetSubtitle>
            {hasCtaButton && (
              <Flex
                display={{ MOBILE: 'none', TABLET: 'flex' }}
                justifyContent="start"
                flex="1"
              >
                <StyledLink
                  href={ctaCopyUrl}
                  icon={Icon.ChevronRight}
                  iconPosition={BUTTON_ICON_POSITION.RIGHT}
                  aria-label={ctaCopy}
                  onClick={() => {
                    executeDataToDataLayer({
                      event: GA4_EVENTS.CLICK,
                      overrideData: {
                        text: ctaCopy,
                        url: asPath,
                        name: 'Button of video section widget',
                      },
                    })
                  }}
                >
                  {ctaCopy}
                </StyledLink>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex flex="1" width={{ MOBILE: '100%', TABLET: '50%' }}>
          <ThumbnailWrapper
            name={THUMBNAIL_ELEMENT_NAME}
            flexDirection="column"
            width="100%"
            height="100%"
            minHeight={{ MOBILE: '432px', TABLET: '368px' }}
            onClick={() => {
              setIsLightboxOpen(true)
            }}
            position="relative"
          >
            <Image
              src={thumbnail.url}
              alt={thumbnail.alt || title}
              layout={IMAGE_LAYOUT.FILL}
              objectFit="cover"
            />
            <TextWrapper
              zIndex={1}
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Icon.PlayVideo size={ICON_PLAY_SIZE} />
            </TextWrapper>
          </ThumbnailWrapper>
        </Flex>
      </Flex>
      {isLightboxOpen && (
        <Overlay
          onClick={() => {
            setIsLightboxOpen(false)
          }}
          onDismiss={() => {
            setIsLightboxOpen(false)
          }}
          initialFocusRef={contentRef}
        >
          <Content ref={contentRef} aria-label="Video popup">
            <Flex width="100%" maxWidth={MODAL_VIDEO_MAX_WIDTH} m="auto">
              <ReactPlayer
                playing
                onClick={(event) => {
                  event.stopPropagation()
                }}
                url={video.url}
                controls
                pip={false}
                width="100%"
                height="auto"
              />
            </Flex>
          </Content>
        </Overlay>
      )}
    </Container>
  )

  return (
    <>
      {isMobile ? (
        <MobileWrapper position="relative" my={SPACE.PX_24} py={SPACE.PX_40}>
          {containerWrapper}
          <Flex
            justifyContent="center"
            flex="1"
            m={{ MOBILE: `${SPACE.PX_24} 0 ${SPACE.PX_16} 0` }}
          >
            {hasCtaButton && (
              <StyledLink
                href={ctaCopyUrl}
                icon={Icon.ChevronRight}
                iconPosition={BUTTON_ICON_POSITION.RIGHT}
                display={{ TABLET: 'none' }}
                aria-label={ctaCopy}
              >
                {ctaCopy}
              </StyledLink>
            )}
          </Flex>
        </MobileWrapper>
      ) : (
        <TabletWrapper position="relative" my={SPACE.PX_48} py={SPACE.PX_64}>
          {containerWrapper}
        </TabletWrapper>
      )}
    </>
  )
}
