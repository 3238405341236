import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {
  CarouselNavigationArrow,
  DefaultArrowWrapper,
} from 'components/CarouselNavigation/CarouselNavigationArrow'

export const ArrowButtonWrapper = styled(DefaultArrowWrapper)`
  cursor: pointer;
  overflow: hidden;
  .swiper-button-disabled {
    display: none;
  }
`

export const SwiperGalleryArrows = ({ prevButtonRef, nextButtonRef }) => (
  <>
    <ArrowButtonWrapper isStickyToLeft>
      <CarouselNavigationArrow
        isLtr={false}
        hasBackground
        ref={prevButtonRef}
        aria-label="previous arrow button"
      />
    </ArrowButtonWrapper>
    <ArrowButtonWrapper>
      <CarouselNavigationArrow
        isLtr
        hasBackground
        ref={nextButtonRef}
        aria-label="next arrow button"
      />
    </ArrowButtonWrapper>
  </>
)

SwiperGalleryArrows.propTypes = {
  prevButtonRef: PropTypes.any,
  nextButtonRef: PropTypes.any,
}
