import { rem } from 'polished'
import React from 'react'
import { Divider } from 'components/Divider'
import { Grid } from 'components/Layout'
import { H4, Text } from 'components/Typography'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  HEADING_VARIANT,
  SPACE,
} from 'Theme'

const DIVIDER_WIDTH = {
  MOBILE: rem(24),
  TABLET: rem(40),
}

export interface ConciergeWidgetItemProps {
  title: string
  description: string
}

export const ConciergeWidgetItem: React.FC<
  React.PWC<ConciergeWidgetItemProps>
> = ({ title, description }) => (
  <article>
    <Grid gridGap={{ MOBILE: SPACE.PX_12, DESKTOP: SPACE.PX_16 }}>
      <H4 as="h3" variant={HEADING_VARIANT.SERIF} m={0}>
        {title}
      </H4>
      <Divider
        width={DIVIDER_WIDTH}
        color={COLOR_INTENT.WIDGETS.CONCIERGE.DIVIDER}
        opacity={1}
      />
      <Text as="p" fontFamily={FONT_STACK.SERIF} fontSize={FONT_SIZE.PX_16}>
        {description}
      </Text>
    </Grid>
  </article>
)
