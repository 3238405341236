import { rem } from 'polished'
import React from 'react'
import { Image } from 'components/Image'
import { Flex } from 'components/Layout'

const GIFT_CARD_PREVIEW_IMAGE = {
  BASIC: '/giftCardPreview.png',
  DISCOUNT: '/discountGiftCardPreview.png',
}

interface GiftCardPreviewProps {
  isDiscount?: boolean
  width?: string
  height?: string
}

export const GiftCardPreview: React.FC<GiftCardPreviewProps> = ({
  isDiscount,
  width = rem(320),
  height = rem(215),
}) => (
  <Flex maxWidth="100%" width={width} height={height} flexDirection="column">
    <Image
      src={
        isDiscount
          ? GIFT_CARD_PREVIEW_IMAGE.DISCOUNT
          : GIFT_CARD_PREVIEW_IMAGE.BASIC
      }
      objectFit="contain"
      unoptimized
      alt="Gift card preview"
    />
  </Flex>
)
