export type ExpertListingItemType = {
  name: string
  slug: string
  imageUrl: string
  imageAlt?: string
}

export const experts: ExpertListingItemType[] = [
  {
    name: 'Leanne Ford',
    slug: 'leanneford',
    imageUrl: 'https://theexpert-production.b-cdn.net/widgets/leanne-ford.jpg',
  },
  {
    name: 'Jenna Lyons',
    slug: 'jennalyons',
    imageUrl: 'https://theexpert-production.b-cdn.net/widgets/jenna-lyons.jpg',
  },
  {
    name: 'Brigette Romanek',
    slug: 'brigetteromanek',
    imageUrl:
      'https://theexpert-production.b-cdn.net/widgets/brigette-romanek.jpg',
  },
  {
    name: 'Jake Arnold',
    slug: 'jakearnold',
    imageUrl: 'https://theexpert-production.b-cdn.net/widgets/jake-arnold.jpg',
  },
  {
    name: 'Amber Lewis',
    slug: 'amberinteriors',
    imageUrl: 'https://theexpert-production.b-cdn.net/widgets/amber-lewis.jpg',
  },
  {
    name: 'Mandy Cheng',
    slug: 'mandycheng',
    imageUrl: 'https://theexpert-production.b-cdn.net/widgets/mandy-cheng.jpg',
  },
  {
    name: 'Sarah Sherman Samuel',
    slug: 'sarahshermansamuel',
    imageUrl:
      'https://theexpert-production.b-cdn.net/widgets/sarah-sherman-samuel.jpg',
  },
]
