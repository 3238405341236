import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Grid, Flex } from 'components/Layout'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { CATEGORY_HEADER_WIDGET_TYPE } from 'constants/common'
import { SPACE, PAGE_MAX_WIDTH } from 'Theme'
import { type CmsCategoryHeaderWidget } from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import {
  CarouselSubtitle,
  CarouselTitle,
  CategoryHeaderCarousel,
  ExpertImage,
  SingleImageSubtitle,
  SingleImageTitle,
} from './components'

interface CategoryHeaderWidgetProps
  extends Omit<CmsCategoryHeaderWidget, 'type'> {
  categoryHeaderWidgetType: CmsCategoryHeaderWidget['type']
}

export const CategoryHeaderWidget: React.FC<
  React.PWC<CategoryHeaderWidgetProps>
> = ({
  title,
  subtitle,
  categoryHeaderWidgetType: type,
  cmsCategoryHeaderWidgetItems,
  expert,
}) => {
  const { MOBILE: isMobile } = useMedia()

  const isCarouselType = type === CATEGORY_HEADER_WIDGET_TYPE.CAROUSEL
  const isWithExpertPhoto = !!expert?.headshotImageAttachment?.url

  if (!cmsCategoryHeaderWidgetItems.length) {
    return null
  }

  return (
    <Flex
      justifyContent="center"
      width="100%"
      px={{ MOBILE: 0, TABLET: SPACE.PX_15 }}
    >
      <Grid
        width={{ MOBILE: '100%', DESKTOP: PAGE_MAX_WIDTH.LAYOUT }}
        gridTemplateColumns={{
          MOBILE: '1fr',
          TABLET: isCarouselType ? '1fr 2fr' : '1fr 1fr',
        }}
        gridGap={{ MOBILE: 0, TABLET: SPACE.PX_24, DESKTOP: SPACE.PX_40 }}
        mb={isCarouselType ? SPACE.PX_20 : 0}
        data-test-class={DATA_TEST_CLASS.CATEGORY_HEADER_WIDGET}
      >
        <Grid
          gridRow={{ MOBILE: 2, TABLET: 1 }}
          alignSelf="flex-end"
          position="relative"
          mx={{ MOBILE: SPACE.PX_15, TABLET: 0 }}
        >
          {isCarouselType ? (
            <>
              {expert?.headshotImageAttachment && (
                <ExpertImage
                  isMobile={isMobile}
                  src={expert.headshotImageAttachment.url}
                  alt={expert.headshotImageAttachment.alt}
                />
              )}
              <CarouselTitle isWithExpertPhoto={isWithExpertPhoto}>
                {title}
              </CarouselTitle>
              {subtitle && <CarouselSubtitle>{subtitle}</CarouselSubtitle>}
            </>
          ) : (
            <>
              <SingleImageTitle>{title}</SingleImageTitle>
              {subtitle && (
                <SingleImageSubtitle>{subtitle}</SingleImageSubtitle>
              )}
            </>
          )}
        </Grid>
        {isCarouselType ? (
          <CategoryHeaderCarousel images={cmsCategoryHeaderWidgetItems} />
        ) : (
          <MediaQueryWrapper
            tablet={
              <Image
                alt={
                  cmsCategoryHeaderWidgetItems[0].attachment.alt ||
                  'Category image'
                }
                src={cmsCategoryHeaderWidgetItems[0].attachment.url}
                layout={IMAGE_LAYOUT.RESPONSIVE}
                aspectRatio={ASPECT_RATIO.CATEGORY_HEADER_SINGLE_IMAGE}
              />
            }
          />
        )}
      </Grid>
    </Flex>
  )
}
