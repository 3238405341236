import React from 'react'
import { Grid } from 'components/Layout'
import { SPACE } from 'Theme'
import { type Attachment } from 'types/graphql-generated'
import { ColumnCard } from './ColumnCard'

interface ItemThreeColumnCardProps {
  firstAttachment: Attachment
  secondAttachment: Attachment
  thirdAttachment: Attachment
  firstTitle: string
  firstLink?: string
  secondTitle: string
  secondLink?: string
  thirdTitle: string
  thirdLink?: string
}

export const ItemThreeColumnCard: React.FC<
  React.PWC<ItemThreeColumnCardProps>
> = ({
  firstAttachment,
  secondAttachment,
  thirdAttachment,
  firstTitle,
  firstLink,
  secondTitle,
  secondLink,
  thirdTitle,
  thirdLink,
}) => (
  <Grid
    pb={{ MOBILE: 0, TABLET: SPACE.PX_16 }}
    gridTemplateColumns={{ MOBILE: '1fr 1fr', TABLET: '1fr 1fr 1fr' }}
    gridColumnGap={SPACE.PX_16}
  >
    <ColumnCard
      title={firstTitle}
      link={firstLink}
      image={firstAttachment}
      isFullWidthOnMobile
    />
    <ColumnCard
      title={secondTitle}
      link={secondLink}
      image={secondAttachment}
    />
    <ColumnCard title={thirdTitle} link={thirdLink} image={thirdAttachment} />
  </Grid>
)
