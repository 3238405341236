/**
 * @description The inclusion of a leading '/' ensures that the URL is always interpreted as an absolute path, rather than being treated as a path relative to the current URL.
 * @example getAbsoluteUrl('showroom/brands/test') => '/showroom/brands/test'
 * @returns Absolute URL
 */
export const getAbsoluteUrl = (url: string): string => {
  if (!url) {
    return '/'
  }

  if (url.startsWith('/')) {
    return url
  }

  return `/${url}`
}
