import { rem } from 'polished'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Box, Flex } from 'components/Layout'
import { Modal } from 'components/Modal'
import { SPACE } from 'Theme'
import { ASPECT_RATIO } from './constants'
import { UploadImageArea } from './UploadImageArea'
import { UploadImageProvider } from './UploadImageProvider'

const MODAL_MAX_WIDTH = rem(660)

export const UploadImageModal = ({
  onModalClose,
  onConfirm,
  aspectRatio,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleCloseModal = useCallback(
    (resetImage) => () => {
      resetImage()
      onModalClose()
    },
    [onModalClose]
  )

  return (
    <UploadImageProvider aspectRatio={aspectRatio}>
      {({ resetImage, saveImage, ...uploadImageAreaProps }) => (
        <Modal
          data-test-id={DATA_TEST_ID.UPLOAD_MODAL}
          maxWidth={MODAL_MAX_WIDTH}
          onModalClose={handleCloseModal(resetImage)}
          customFooter={() => (
            <Flex p={SPACE.PX_20} justifyContent="flex-end">
              <Box mr={SPACE.PX_20}>
                <Button
                  onClick={handleCloseModal(resetImage)}
                  variant={BUTTON_VARIANT.OUTLINE}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  isDisabled={isLoading}
                  data-test-id={DATA_TEST_ID.UPLOAD_MODAL_SAVE_BTN}
                  onClick={() =>
                    saveImage(async (value) => {
                      setIsLoading(true)
                      await onConfirm(value)
                      handleCloseModal(resetImage)()
                      setIsLoading(false)
                    })
                  }
                >
                  Save image
                </Button>
              </Box>
            </Flex>
          )}
          {...props}
        >
          <UploadImageArea {...uploadImageAreaProps} />
        </Modal>
      )}
    </UploadImageProvider>
  )
}

UploadImageModal.propTypes = {
  aspectRatio: PropTypes.oneOf(Object.values(ASPECT_RATIO)),
  onModalClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}
