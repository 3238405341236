import { DialogContent, DialogOverlay } from '@reach/dialog'
import dynamic from 'next/dynamic'
import { transparentize } from 'polished'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { COLOR, IMAGE_BASE_WIDTH, SPACE, Z_INDEX } from 'Theme'
import { useMedia } from 'useMedia'
import { WidgetTitle } from '../WidgetTitle'

const VIDEO_MAX_WIDTH = '1200px'
export const THUMBNAIL_ELEMENT_NAME = 'videoWidgetThumbnail'

const TextWrapper = styled(Flex)`
  pointer-events: none;
`

const Overlay = styled(DialogOverlay)`
  overflow: hidden;
  z-index: ${Z_INDEX.OVERLAY};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-content: center;
  background-color: ${transparentize(0.2, COLOR.GRAYSCALE_1)};
`

const ThumbnailWrapper = styled(Flex)`
  cursor: pointer;
`

const Content = styled(DialogContent)`
  outline: none;
  width: 100%;
  max-width: ${VIDEO_MAX_WIDTH};
  justify-content: center;
`

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

export const VideoWidget = ({
  videoAttachment,
  thumbnailAttachment,
  title,
}) => {
  const media = useMedia()
  const contentRef = React.useRef(null)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  return (
    <Box my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}>
      <ThumbnailWrapper
        name={THUMBNAIL_ELEMENT_NAME}
        flexDirection="column"
        position="relative"
        width="100%"
        height="100%"
        onClick={() => {
          setIsLightboxOpen(true)
        }}
      >
        <TextWrapper
          zIndex={1}
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <WidgetTitle
            mb={{ MOBILE: SPACE.PX_10, TABLET: SPACE.PX_15 }}
            color={COLOR.WHITE}
          >
            {title}
          </WidgetTitle>
          <Icon.Play
            color={COLOR.WHITE}
            size={media.MOBILE ? '40px' : '50px'}
          />
        </TextWrapper>
        <Image
          src={thumbnailAttachment.url}
          alt={thumbnailAttachment.alt || title}
          layout={IMAGE_LAYOUT.RESPONSIVE}
          aspectRatio={ASPECT_RATIO.VIDEO_THUMBNAIL}
          baseWidth={IMAGE_BASE_WIDTH.PX_1200}
        />
      </ThumbnailWrapper>
      {isLightboxOpen && (
        <Overlay
          onClick={() => {
            setIsLightboxOpen(false)
          }}
          onDismiss={() => {
            setIsLightboxOpen(false)
          }}
          initialFocusRef={contentRef}
        >
          <Content ref={contentRef} aria-label="Video popup">
            <Flex width="100%" maxWidth={VIDEO_MAX_WIDTH} m="auto">
              <ReactPlayer
                playing
                onClick={(event) => {
                  event.stopPropagation()
                }}
                url={videoAttachment.url}
                controls
                pip={false}
                width="100%"
                height="auto"
              />
            </Flex>
          </Content>
        </Overlay>
      )}
    </Box>
  )
}
VideoWidget.defaultProps = {
  title: 'Video player',
}

VideoWidget.propTypes = {
  videoAttachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  thumbnailAttachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
}
