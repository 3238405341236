import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex, Grid, Box, Container } from 'components/Layout'
import { SharpStyledLink } from 'components/Link'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  LINE_HEIGHT,
  SPACE,
} from 'Theme'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const IMAGE_MIN_HEIGHT = rem(500)
const IMAGE_ALT = 'Introduction widget image'
const WIDGET_TEXT_GRID_GAP = rem(24)
const TITLE_GRID_GAP = rem(12)

const Wrapper = styled(Box)`
  background: ${COLOR_INTENT.WIDGETS.INTRODUCTION.BACKGROUND};
`

const WidgetTextGrid = styled(Grid)`
  text-align: center;
  width: 100%;
`

export const IntroductionWidget = ({
  title,
  pretitle,
  posttitle,
  description,
  attachment,
  buttonText,
  buttonLink,
}) => (
  <Wrapper
    data-test-class={DATA_TEST_CLASS.INTRODUCTION_WIDGET}
    my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
  >
    <Container px={0}>
      <Grid gridTemplateColumns={{ MOBILE: '1fr', TABLET: '1fr 1fr' }}>
        <Box
          width="100%"
          height="100%"
          minHeight={{ MOBILE: 'auto', TABLET: IMAGE_MIN_HEIGHT }}
          position="relative"
        >
          <MediaQueryWrapper
            mobile={
              <Image
                src={attachment.url}
                alt={attachment.alt || IMAGE_ALT}
                layout={IMAGE_LAYOUT.RESPONSIVE}
                aspectRatio={ASPECT_RATIO.INTRODUCTION}
                objectFit="cover"
                sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 33vw"
              />
            }
            tablet={
              <Image
                src={attachment.url}
                alt={attachment.alt || IMAGE_ALT}
                layout={IMAGE_LAYOUT.FILL}
                aspectRatio={ASPECT_RATIO.INTRODUCTION}
                objectFit="cover"
                sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 33vw"
              />
            }
          />
        </Box>
        <Flex alignItems="center" p={SPACE.PX_30}>
          <WidgetTextGrid
            gridGap={WIDGET_TEXT_GRID_GAP}
            justifyContent="center"
            alignItems="center"
          >
            <Grid gridGap={TITLE_GRID_GAP}>
              {pretitle && (
                <Text
                  fontSize={FONT_SIZE.PX_20}
                  fontWeight={FONT_WEIGHT.MEDIUM}
                  fontFamily={FONT_STACK.SERIF}
                >
                  {pretitle}
                </Text>
              )}
              <WidgetTitle lineHeight={LINE_HEIGHT.XXS} mb={0}>
                {title}
              </WidgetTitle>
              {posttitle && <WidgetSubtitle>{posttitle}</WidgetSubtitle>}
            </Grid>
            <Text
              textAlign="center"
              px={{
                MOBILE: 0,
                TABLET: SPACE.PX_30,
                DESKTOP: SPACE.PX_80,
                TV: SPACE.PX_100,
              }}
              fontFamily={FONT_STACK.SERIF}
            >
              {description}
            </Text>
            <Flex justifyContent="center">
              <SharpStyledLink href={buttonLink} aria-label={buttonText}>
                {buttonText}
              </SharpStyledLink>
            </Flex>
          </WidgetTextGrid>
        </Flex>
      </Grid>
    </Container>
  </Wrapper>
)

IntroductionWidget.propTypes = {
  title: PropTypes.string.isRequired,
  pretitle: PropTypes.string,
  posttitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  attachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
}
