import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Card, CARD_ELEVATION, CARD_PADDING_SIZE } from 'components/Card'
import { Delimiter } from 'components/Delimiter'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex, Grid, Box } from 'components/Layout'
import { H5, Text } from 'components/Typography'
import { SPACE, COLOR, FONT_STACK, FONT_SIZE, FONT_WEIGHT } from 'Theme'

const DELIMITER_SIZE = '80px'
const TABLET_CARD_SIZE = '80px'

const HorizontalDelimiter = styled(Flex)`
  width: 1px;
  height: 120px;
  background: ${COLOR.GRAYSCALE_5};
`

export const InfoCardsWidget = () => (
  <Grid
    mb={{ MOBILE: SPACE.PX_60, TABLET: 0 }}
    mt={{ MOBILE: SPACE.PX_48, TABLET: 0 }}
    width="100%"
    gridGap={{ MOBILE: SPACE.PX_60, TABLET: SPACE.PX_40 }}
    gridTemplateColumns={{ MOBILE: '1fr', TABLET: '1fr 1px 1fr 1px 1fr' }}
    gridTemplateRows={{ MOBILE: 'initial', TABLET: rem(240) }}
    alignItems="center"
    justifyItems="center"
    data-test-class={DATA_TEST_CLASS.INFO_CARDS_WIDGET}
  >
    <Card elevation={CARD_ELEVATION.NONE} paddingSize={CARD_PADDING_SIZE.NONE}>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Box mb={SPACE.PX_20}>
          <Icon.Search size={ICON_SIZE.PX_24} color={COLOR.PRIMARY_1} />
        </Box>
        <H5
          fontFamily={FONT_STACK.SERIF}
          textAlign="center"
          fontSize={FONT_SIZE.PX_20}
          fontWeight={FONT_WEIGHT.NORMAL}
          mb={SPACE.PX_20}
          color={COLOR.GRAYSCALE_1}
        >
          Find an Expert
        </H5>
        <Flex height={{ MOBILE: 'initial', TABLET: TABLET_CARD_SIZE }}>
          <Text
            textAlign="center"
            color={COLOR.BLACK}
            fontSize={FONT_SIZE.PX_16}
          >
            Choose from a curated selection of top design Experts from across
            the world
          </Text>
        </Flex>
      </Flex>
    </Card>
    <Flex width={DELIMITER_SIZE} display={{ MOBILE: 'flex', TABLET: 'none' }}>
      <Delimiter />
    </Flex>
    <Flex display={{ MOBILE: 'none', TABLET: 'flex' }}>
      <HorizontalDelimiter />
    </Flex>
    <Card elevation={CARD_ELEVATION.NONE} paddingSize={CARD_PADDING_SIZE.NONE}>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Box mb={SPACE.PX_20}>
          <Icon.Calendar size={ICON_SIZE.PX_24} color={COLOR.PRIMARY_1} />
        </Box>
        <H5
          fontSize={FONT_SIZE.PX_20}
          textAlign="center"
          mb={SPACE.PX_20}
          fontWeight={FONT_WEIGHT.NORMAL}
          fontFamily={FONT_STACK.SERIF}
          color={COLOR.BLACK}
        >
          Book a Video Consultation
        </H5>
        <Flex height={{ MOBILE: 'initial', TABLET: TABLET_CARD_SIZE }}>
          <Text
            textAlign="center"
            color={COLOR.BLACK}
            fontSize={FONT_SIZE.PX_16}
          >
            Choose the length of consultation you&apos;d like to book and a time
            that works for you
          </Text>
        </Flex>
      </Flex>
    </Card>
    <Flex width={DELIMITER_SIZE} display={{ MOBILE: 'flex', TABLET: 'none' }}>
      <Delimiter />
    </Flex>
    <Flex display={{ MOBILE: 'none', TABLET: 'flex' }}>
      <HorizontalDelimiter />
    </Flex>
    <Card elevation={CARD_ELEVATION.NONE} paddingSize={CARD_PADDING_SIZE.NONE}>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Box mb={SPACE.PX_20}>
          <Icon.Communication size={ICON_SIZE.PX_24} color={COLOR.PRIMARY_1} />
        </Box>
        <H5
          textAlign="center"
          fontSize={FONT_SIZE.PX_20}
          mb={SPACE.PX_20}
          fontWeight={FONT_WEIGHT.NORMAL}
          fontFamily={FONT_STACK.SERIF}
          color={COLOR.BLACK}
        >
          Learn from the Best
        </H5>
        <Flex height={{ MOBILE: 'initial', TABLET: TABLET_CARD_SIZE }}>
          <Text
            textAlign="center"
            color={COLOR.BLACK}
            fontSize={FONT_SIZE.PX_16}
          >
            You’ll receive a link to your consultation via Zoom so you can meet
            your Expert face to face
          </Text>
        </Flex>
      </Flex>
    </Card>
  </Grid>
)
