import React from 'react'
import { Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { SPACE } from 'Theme'

interface ItemButtonProps {
  text: string
  link: string
}

export const ItemButton: React.FC<React.PWC<ItemButtonProps>> = ({
  text,
  link,
}) => (
  <Flex pb={SPACE.PX_16} pt={SPACE.PX_8} width="100%" justifyContent="center">
    <StyledLink href={link}>{text}</StyledLink>
  </Flex>
)
