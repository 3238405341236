import { rem } from 'polished'

export const EXPERT_IMAGE_SIZE = {
  MOBILE: {
    WIDTH: rem(80),
    HEIGHT: rem(80),
  },
  TABLET: {
    WIDTH: rem(90),
    HEIGHT: rem(90),
  },
}
