import { debounce, get, omit } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useUnloadPage } from 'hooks'
import { GA4_EVENTS, useGoogleAnalytics } from 'providers/googleAnalytics'
import { getEcomCustomEventFromArea } from '../helpers'

const REPORT_TIMEOUT = 60 * 60 * 1000 // 60 minutes
const EVENTS_DEBOUNCED_TIMEOUT = 5 * 1000 // 5 seconds

interface UseItemsListViewGA4Props {
  overrideData?: Record<string, any>
}
const RESET_REPORT_TIMEOUT_EVENTS = ['mousemove', 'scroll', 'keydown']

export const useItemsListViewGA4 = ({
  overrideData = {},
}: UseItemsListViewGA4Props = {}) => {
  const { executeDataToDataLayer, data, aggregateData } = useGoogleAnalytics()
  const pushViewedItemsTimeoutRef = useRef(null)

  const reportDataToGA4 = useCallback(() => {
    if (!get(data, ['current', 'items'], []).length) {
      return
    }
    const { area } = overrideData
    executeDataToDataLayer({
      event: GA4_EVENTS.VIEW_ITEM_LIST,
      overrideData,
    })

    executeDataToDataLayer({
      event: getEcomCustomEventFromArea(area),
      includeStoredData: false,
    })

    aggregateData((values) => omit(values, 'items'))
  }, [aggregateData, data, executeDataToDataLayer, overrideData])

  useEffect(() => {
    const resetReportTimeout = () => {
      clearTimeout(pushViewedItemsTimeoutRef.current)
      pushViewedItemsTimeoutRef.current = setTimeout(() => {
        reportDataToGA4()
      }, REPORT_TIMEOUT)
    }
    resetReportTimeout() // initial timeout set

    const resetReportTimeoutDebounced = debounce(
      resetReportTimeout,
      EVENTS_DEBOUNCED_TIMEOUT,
      {
        leading: true,
        trailing: false,
        maxWait: EVENTS_DEBOUNCED_TIMEOUT,
      }
    )
    RESET_REPORT_TIMEOUT_EVENTS.forEach((eventName) => {
      window.addEventListener(eventName, resetReportTimeoutDebounced)
    })

    return () => {
      RESET_REPORT_TIMEOUT_EVENTS.forEach((eventName) => {
        window.removeEventListener(eventName, resetReportTimeoutDebounced)
      })
      clearTimeout(pushViewedItemsTimeoutRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useUnloadPage(reportDataToGA4)
}
