import React from 'react'
import { WidgetTitle } from 'components/Widgets/WidgetTitle'
import { SPACE } from 'Theme'

export const SingleImageTitle: React.FC<React.PWC> = ({ children }) => (
  <WidgetTitle
    mt={{ MOBILE: SPACE.PX_40, TABLET: 0 }}
    mb={SPACE.PX_10}
    textAlign={{ MOBILE: 'center', TABLET: 'start' }}
  >
    {children}
  </WidgetTitle>
)
