import {
  MULTI_COLLECTION_WIDGET_TYPE,
  CATEGORY_HEADER_WIDGET_TYPE,
  WIDGET_PLACEMENT,
  ALPHABETICAL_INDEX_WIDGET_TYPE,
} from 'constants/common'
import { COLLECTION_SORT_FIELDS } from 'routes/admin/constants/general'

export const ALLOWED_WIDGET_TYPES = {
  GALLERY: 'CMSGalleryWidget',
  HERO_CAROUSEL: 'CMSHeroCarouselWidget',
  HERO_LEGACY: 'CMSHeroWidget',
  HERO_MAIN: 'CMSHeroMainWidget',
  TESTIMONIALS: 'CMSTestimonialsWidget',
  INFO_CARDS: 'CMSInfoCardsWidget',
  FEATURED_IN: 'CMSFeaturedInWidget',
  EXPERTS: 'CMSExpertsWidget',
  ARTICLES: 'CMSArticlesWidget',
  VIDEO: 'CMSVideoWidget',
  VIDEO_SECTION: 'CMSVideoSectionWidget',
  SPOTLIGHT: 'CMSSpotlightWidget',
  RICH_TEXT: 'CMSRichTextWidget',
  ARTICLE_HEADING: 'CMSArticleHeadingWidget',
  CATEGORY_HEADER: 'CMSCategoryHeaderWidget',
  COLLECTION_HEADER: 'CMSCollectionHeaderWidget',
  BANNER: 'CMSBannerWidget',
  SINGLE_IMAGE: 'CMSSingleImageWidget',
  PRODUCTS: 'CMSProductsWidget',
  DOUBLE_IMAGE: 'CMSDoubleImageWidget',
  QA: 'CMSQAWidget',
  GIFT_CARD: 'CMSGiftCardWidget',
  MULTI_COLLECTION: 'CMSMultiCollectionWidget',
  EXPERT_COLLECTIONS: 'CMSExpertCollectionsWidget',
  COLLECTIONS_CAROUSEL: 'CMSCollectionsCarouselWidget',
  INTRODUCTION: 'CMSIntroductionWidget',
  VALUE_PROPOSITION: 'CMSValuePropositionWidget',
  ALPHABETICAL_INDEX: 'CMSAlphabeticalIndexWidget',
  EXPERTS_LISTING: 'CMSExpertsListingWidget',
  CARD_NAVIGATION: 'CMSCardNavigationWidget',
  ALT_EXPERTS_LISTING: 'CMSAltExpertsListingWidget',
  ALT_VALUE_PROPOSITION: 'CMSAltValuePropositionWidget',
  CONCIERGE: 'CMSConciergeWidget',
  SECTION: 'CMSSectionWidget',
} as const

export const WIDGET_NAMES = {
  GALLERY: {
    __typename: ALLOWED_WIDGET_TYPES.GALLERY,
    name: 'Image - Gallery',
  },
  HERO_CAROUSEL: {
    __typename: ALLOWED_WIDGET_TYPES.HERO_CAROUSEL,
    name: 'Hero - Carousel',
  },
  HERO_LEGACY: {
    __typename: ALLOWED_WIDGET_TYPES.HERO_LEGACY,
    name: 'Hero (Legacy)',
  },
  HERO_MAIN: {
    __typename: ALLOWED_WIDGET_TYPES.HERO_MAIN,
    name: 'Hero - Main',
  },
  TESTIMONIALS: {
    __typename: ALLOWED_WIDGET_TYPES.TESTIMONIALS,
    name: 'Testimonials',
  },
  INFO_CARDS: {
    __typename: ALLOWED_WIDGET_TYPES.INFO_CARDS,
    name: 'Info cards',
  },
  FEATURED_IN: {
    __typename: ALLOWED_WIDGET_TYPES.FEATURED_IN,
    name: 'Featured in',
  },
  EXPERTS: {
    __typename: ALLOWED_WIDGET_TYPES.EXPERTS,
    name: 'Listing - Selected Experts',
  },
  EXPERTS_LISTING: {
    __typename: ALLOWED_WIDGET_TYPES.EXPERTS_LISTING,
    name: 'Listing - All Experts',
  },
  VIDEO: {
    __typename: ALLOWED_WIDGET_TYPES.VIDEO,
    name: 'Video',
  },
  VIDEO_SECTION: {
    __typename: ALLOWED_WIDGET_TYPES.VIDEO_SECTION,
    name: 'Video section',
  },
  SPOTLIGHT: {
    __typename: ALLOWED_WIDGET_TYPES.SPOTLIGHT,
    name: 'Spotlight',
  },
  RICH_TEXT: {
    __typename: ALLOWED_WIDGET_TYPES.RICH_TEXT,
    name: 'Rich text',
  },
  ARTICLE_HEADING: {
    __typename: ALLOWED_WIDGET_TYPES.ARTICLE_HEADING,
    name: 'Hero - Article',
  },
  CATEGORY_HEADER: {
    __typename: ALLOWED_WIDGET_TYPES.CATEGORY_HEADER,
    name: 'Hero - Category page',
  },
  BANNER: {
    __typename: ALLOWED_WIDGET_TYPES.BANNER,
    name: 'Banner',
  },
  SINGLE_IMAGE: {
    __typename: ALLOWED_WIDGET_TYPES.SINGLE_IMAGE,
    name: 'Image - Single',
  },
  PRODUCTS: {
    __typename: ALLOWED_WIDGET_TYPES.PRODUCTS,
    name: 'Products',
  },
  ARTICLES: {
    __typename: ALLOWED_WIDGET_TYPES.ARTICLES,
    name: 'Listing - Articles',
  },
  DOUBLE_IMAGE: {
    __typename: ALLOWED_WIDGET_TYPES.DOUBLE_IMAGE,
    name: 'Image - Double',
  },
  QA: {
    __typename: ALLOWED_WIDGET_TYPES.QA,
    name: 'FAQ',
  },
  GIFT_CARD: {
    __typename: ALLOWED_WIDGET_TYPES.GIFT_CARD,
    name: 'Gift Card',
  },
  COLLECTION_HEADER: {
    __typename: ALLOWED_WIDGET_TYPES.COLLECTION_HEADER,
    name: 'Hero - Expert curations',
  },
  MULTI_COLLECTION: {
    __typename: ALLOWED_WIDGET_TYPES.MULTI_COLLECTION,
    name: 'Listing - Products',
  },
  EXPERT_COLLECTIONS: {
    __typename: ALLOWED_WIDGET_TYPES.EXPERT_COLLECTIONS,
    name: 'Listing - Expert curations',
  },
  COLLECTIONS_CAROUSEL: {
    __typename: ALLOWED_WIDGET_TYPES.COLLECTIONS_CAROUSEL,
    name: 'Carousel - Expert curations',
  },
  INTRODUCTION: {
    __typename: ALLOWED_WIDGET_TYPES.INTRODUCTION,
    name: 'Introduction',
  },
  VALUE_PROPOSITION: {
    __typename: ALLOWED_WIDGET_TYPES.VALUE_PROPOSITION,
    name: 'Value proposition',
  },
  ALPHABETICAL_INDEX: {
    __typename: ALLOWED_WIDGET_TYPES.ALPHABETICAL_INDEX,
    name: 'Alphabetical index',
  },
  CARD_NAVIGATION: {
    __typename: ALLOWED_WIDGET_TYPES.CARD_NAVIGATION,
    name: 'Card navigation',
  },
  ALT_EXPERTS_LISTING: {
    __typename: ALLOWED_WIDGET_TYPES.ALT_EXPERTS_LISTING,
    name: 'Experts listing (B)',
  },
  ALT_VALUE_PROPOSITION: {
    __typename: ALLOWED_WIDGET_TYPES.ALT_VALUE_PROPOSITION,
    name: 'Value proposition (B)',
  },
  CONCIERGE: {
    __typename: ALLOWED_WIDGET_TYPES.CONCIERGE,
    name: 'Concierge',
  },
  SECTION: {
    __typename: ALLOWED_WIDGET_TYPES.SECTION,
    name: 'Section',
  },
} as const

export const WIDGET_MENU_ITEMS = [
  {
    name: 'General',
    items: [
      WIDGET_NAMES.HERO_MAIN,
      WIDGET_NAMES.HERO_CAROUSEL,
      WIDGET_NAMES.VALUE_PROPOSITION,
      WIDGET_NAMES.ALT_VALUE_PROPOSITION,
      WIDGET_NAMES.SECTION,
      WIDGET_NAMES.EXPERTS,
      WIDGET_NAMES.EXPERTS_LISTING,
      WIDGET_NAMES.TESTIMONIALS,
      WIDGET_NAMES.VIDEO_SECTION,
      WIDGET_NAMES.GIFT_CARD,
      WIDGET_NAMES.SPOTLIGHT,
      WIDGET_NAMES.CARD_NAVIGATION,
      WIDGET_NAMES.ALT_EXPERTS_LISTING,
      WIDGET_NAMES.CONCIERGE,
    ],
  },
  {
    name: 'Shop',
    items: [
      WIDGET_NAMES.CATEGORY_HEADER,
      WIDGET_NAMES.COLLECTION_HEADER,
      WIDGET_NAMES.MULTI_COLLECTION,
      WIDGET_NAMES.EXPERT_COLLECTIONS,
      WIDGET_NAMES.COLLECTIONS_CAROUSEL,
    ],
  },
  {
    name: 'Articles',
    items: [
      WIDGET_NAMES.ARTICLE_HEADING,
      WIDGET_NAMES.RICH_TEXT,
      WIDGET_NAMES.SINGLE_IMAGE,
      WIDGET_NAMES.DOUBLE_IMAGE,
      WIDGET_NAMES.GALLERY,
      WIDGET_NAMES.PRODUCTS,
      WIDGET_NAMES.ARTICLES,
    ],
  },
  {
    name: 'Other',
    items: [
      WIDGET_NAMES.ALPHABETICAL_INDEX,
      WIDGET_NAMES.BANNER,
      WIDGET_NAMES.QA,
      WIDGET_NAMES.FEATURED_IN,
      WIDGET_NAMES.HERO_LEGACY,
      WIDGET_NAMES.VIDEO,
      WIDGET_NAMES.INFO_CARDS,
      WIDGET_NAMES.INTRODUCTION,
      WIDGET_NAMES.SPOTLIGHT,
    ],
  },
] as const

export const COLLECTION_SORT_FIELD_OPTIONS = [
  {
    value: COLLECTION_SORT_FIELDS.POSITION_INDEX_WEIGHT,
    label: 'Default',
  },
  {
    value: COLLECTION_SORT_FIELDS.PRICE,
    label: 'Price',
  },
  {
    value: COLLECTION_SORT_FIELDS.FIRST_PUBLISHED_AT_UTC,
    label: 'Newest-Oldest',
  },
] as const

export const MULTI_COLLECTION_WIDGET_TYPE_OPTIONS = [
  {
    value: MULTI_COLLECTION_WIDGET_TYPE.DEFAULT,
    label: 'Default',
  },
  {
    value: MULTI_COLLECTION_WIDGET_TYPE.FEATURED,
    label: 'Featured',
  },
] as const

export const WIDGET_PLACEMENT_OPTIONS = [
  {
    value: WIDGET_PLACEMENT.HERO,
    label: 'Hero',
  },
  {
    value: WIDGET_PLACEMENT.SECTION,
    label: 'Section',
  },
] as const

export const CATEGORY_HEADER_WIDGET_TYPE_OPTIONS = [
  {
    value: CATEGORY_HEADER_WIDGET_TYPE.SINGLE_IMAGE,
    label: 'Single image',
  },
  {
    value: CATEGORY_HEADER_WIDGET_TYPE.CAROUSEL,
    label: 'Carousel',
  },
] as const

export const ALPHABETICAL_INDEX_WIDGET_TYPE_OPTIONS = [
  {
    value: ALPHABETICAL_INDEX_WIDGET_TYPE.BRAND,
    label: 'Brand',
  },
] as const

export const INITIAL_WIDGET_VALUES = {
  [ALLOWED_WIDGET_TYPES.EXPERTS]: {
    title: '',
    isButtonHidden: false,
    mobilePageSize: 4,
    desktopPageSize: 8,
    cmsExpertsWidgetItems: null,
  },
} as const
