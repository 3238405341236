import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { GiftCardPreview } from 'components/GiftCardPreview'
import { FillImageWrapper, Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import { SharpStyledLink } from 'components/Link'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE_FULL,
} from 'constants/common'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, FONT_STACK, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const GIFT_CARD_PREVIEW_IMAGE_URL = '/giftCardPreviewImage.png'
const GIFT_CARD_DISCOUNT_BADGE = '/giftCardDiscountBadge.png'
const IMAGE_MAX_WIDTH = rem(400)
const GIFT_CARD_PREVIEW_WIDTH = rem(240)
const GIFT_CARD_PREVIEW_HEIGHT = rem(155)
const DESCRIPTION_MAX_WIDTH = rem(390)

const TargetFlex = styled(Flex)`
  // fix for overlapping navigation with anchored link
  &:target {
    scroll-margin-top: ${({ isMobile }) =>
      isMobile ? HEADER_HEIGHT_MOBILE_FULL : HEADER_HEIGHT_DESKTOP}
`

export const GiftCardWidget = ({
  title,
  description,
  isCouponDiscountEnabled,
}) => {
  const media = useMedia()
  return (
    <TargetFlex
      id="gift-card"
      isMobile={media.MOBILE}
      my={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
      data-test-id={DATA_TEST_ID.GIFT_CARD_WIDGET}
      flexDirection={{ MOBILE: 'column', TABLET: 'row' }}
      justifyContent="center"
    >
      {isCouponDiscountEnabled && !media.MOBILE && (
        <Flex
          width="100%"
          maxWidth={IMAGE_MAX_WIDTH}
          alignItems="center"
          position="relative"
        >
          <FillImageWrapper width="100%">
            <Image
              layout={IMAGE_LAYOUT.FILL}
              src={GIFT_CARD_PREVIEW_IMAGE_URL}
              alt="Gift card preview image"
              aspectRatio={ASPECT_RATIO.SINGLE_IMAGE}
            />
          </FillImageWrapper>
          <Box position="absolute" bottom={-33} right={-84}>
            <GiftCardPreview
              isDiscount={media.MOBILE}
              width={GIFT_CARD_PREVIEW_WIDTH}
              height={GIFT_CARD_PREVIEW_HEIGHT}
            />
          </Box>
        </Flex>
      )}
      {(!isCouponDiscountEnabled || media.MOBILE) && (
        <Flex
          width="100%"
          mx={{ MOBILE: 'auto', TABLET: 0 }}
          justifyContent="center"
          alignItems={{ MOBILE: 'center' }}
        >
          <GiftCardPreview isDiscount={isCouponDiscountEnabled} />
        </Flex>
      )}
      <Flex
        ml={{
          MOBILE: 0,
          TABLET: isCouponDiscountEnabled ? SPACE.PX_150 : SPACE.PX_60,
        }}
        flexDirection="column"
        justifyContent={isCouponDiscountEnabled ? 'center' : 'space-between'}
        alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
        pr={{ MOBILE: 0, TABLET: SPACE.PX_15 }}
      >
        <Flex
          flexDirection="column"
          alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
        >
          {isCouponDiscountEnabled && (
            <Flex
              width="100px"
              mb={SPACE.PX_15}
              display={{ MOBILE: 'none', TABLET: 'block' }}
            >
              <FillImageWrapper width="100%">
                <Image
                  layout={IMAGE_LAYOUT.FILL}
                  src={GIFT_CARD_DISCOUNT_BADGE}
                  alt="Gift card discount badge"
                  aspectRatio={ASPECT_RATIO.SINGLE_IMAGE}
                />
              </FillImageWrapper>
            </Flex>
          )}
          <Flex
            flexDirection="column"
            mt={{ MOBILE: SPACE.PX_40, TABLET: 0 }}
            mb={{ MOBILE: SPACE.PX_30, TABLET: SPACE.PX_15 }}
          >
            <WidgetTitle
              textAlign={{ MOBILE: 'center', TABLET: 'initial' }}
              m={0}
            >
              {title}
            </WidgetTitle>
            {isCouponDiscountEnabled && (
              <WidgetTitle
                textAlign={{ MOBILE: 'center', TABLET: 'initial' }}
                color={COLOR_INTENT.BRAND}
                m={0}
              >
                Now 10% off!
              </WidgetTitle>
            )}
          </Flex>
          <Flex maxWidth={DESCRIPTION_MAX_WIDTH}>
            <WidgetSubtitle
              textAlign={{ MOBILE: 'center', TABLET: 'initial' }}
              fontFamily={FONT_STACK.SANS}
              mb={SPACE.PX_30}
            >
              {description}
            </WidgetSubtitle>
          </Flex>
        </Flex>
        <Flex>
          <SharpStyledLink
            href={ROUTE.COUPON_CHECKOUT()}
            aria-label={isCouponDiscountEnabled ? 'get a discount' : 'purchase'}
          >
            {isCouponDiscountEnabled ? 'Get a discount' : 'Purchase'}
          </SharpStyledLink>
        </Flex>
      </Flex>
    </TargetFlex>
  )
}

GiftCardWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isCouponDiscountEnabled: PropTypes.bool,
}
