import React from 'react'
import { Text } from 'components/Typography'
import { FONT_SIZE, FONT_STACK, FONT_WEIGHT, SPACE } from 'Theme'

export const CarouselSubtitle: React.FC<React.PWC> = ({ children }) => (
  <Text
    fontFamily={FONT_STACK.SERIF}
    fontSize={{ MOBILE: FONT_SIZE.PX_14, DESKTOP: FONT_SIZE.PX_16 }}
    fontWeight={FONT_WEIGHT.NORMAL}
    mb={{ MOBILE: 0, DESKTOP: SPACE.PX_20 }}
  >
    {children}
  </Text>
)
