import { rem } from 'polished'
import React from 'react'
import { ROUTE } from 'shared-constants'
import { Button, BUTTON_INTENT, BUTTON_VARIANT } from 'components/Button'
import { Box, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { Text } from 'components/Typography'
import { GA4_EVENTS, useGoogleAnalytics } from 'providers/googleAnalytics'
import { COLOR_INTENT, FONT_SIZE, SPACE } from 'Theme'

const SHOP_ALL_PRODUCTS_BUTTON_WIDTH = rem(150)
const SHOW_MORE = 'Show more'

interface CollectionWidgetFooterProps {
  isLoading?: boolean
  isMoreButtonVisible?: boolean
  isProductCountVisible?: boolean
  currentProductCount: number
  totalProductCount: number
  onMoreButtonClick: () => void
  isShopAllProductsButtonEnabled: boolean
  showroomRoute?: string
  expertSlug?: string
}

export const CollectionWidgetFooter: React.FC<
  React.PWC<CollectionWidgetFooterProps>
> = ({
  isLoading,
  isMoreButtonVisible,
  currentProductCount,
  totalProductCount,
  onMoreButtonClick,
  isProductCountVisible = true,
  isShopAllProductsButtonEnabled,
  showroomRoute,
  expertSlug,
}) => {
  const { executeDataToDataLayer } = useGoogleAnalytics()

  return (
    <Flex flexDirection="column" alignItems="center">
      {isProductCountVisible && (
        <Text
          isLoading={isLoading}
          fontSize={FONT_SIZE.PX_12}
          color={COLOR_INTENT.GRAY_90}
          my={SPACE.PX_15}
        >
          Showing {currentProductCount} of {totalProductCount}
        </Text>
      )}
      {isMoreButtonVisible && (
        <Box mb={SPACE.PX_15}>
          <Button
            isLoading={isLoading}
            onClick={() => {
              onMoreButtonClick()
              executeDataToDataLayer({
                event: GA4_EVENTS.CLICK,
                overrideData: {
                  text: SHOW_MORE,
                  name: 'Show more products on products page',
                },
              })
            }}
            intent={BUTTON_INTENT.PRIMARY}
            variant={BUTTON_VARIANT.OUTLINE}
          >
            {SHOW_MORE}
          </Button>
        </Box>
      )}

      {isShopAllProductsButtonEnabled && showroomRoute && (
        <StyledLink width={SHOP_ALL_PRODUCTS_BUTTON_WIDTH} href={showroomRoute}>
          Shop all products
        </StyledLink>
      )}

      {isShopAllProductsButtonEnabled && expertSlug && (
        <StyledLink
          width={SHOP_ALL_PRODUCTS_BUTTON_WIDTH}
          href={ROUTE.FIND_AN_EXPERT_SHOWROOM_TAB_DYNAMIC({
            slug: expertSlug,
          })}
        >
          Shop all products
        </StyledLink>
      )}
    </Flex>
  )
}
