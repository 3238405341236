import { rem } from 'polished'
import React from 'react'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { ROUTE } from 'constants/routes'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  IMAGE_BASE_WIDTH,
  RADIUS,
} from 'Theme'
import { type ExpertListingItemType } from './experts'

export const EXPERT_NAME_HEIGHT = rem(34)

export const AltExpertListingItem: React.FC<
  React.PWC<ExpertListingItemType & { position?: number }>
> = ({ name, slug, imageUrl, imageAlt, position }) => (
  <Box itemScope itemType={SCHEMA_TYPES.LIST_ITEM}>
    <Link
      href={ROUTE.FIND_AN_EXPERT_CONSULTATION_TAB_DYNAMIC({ slug })}
      itemProp={SCHEMA_PROPS.URL}
    >
      <Flex flexDirection="column" width="100%" flexShrink={0}>
        <Box
          width="100%"
          height="100%"
          borderRadius={RADIUS.PX_12}
          overflow="hidden"
        >
          <Image
            alt={imageAlt || name}
            objectFit="cover"
            layout={IMAGE_LAYOUT.RESPONSIVE}
            aspectRatio={ASPECT_RATIO.ALT_EXPERT_LISTING}
            baseWidth={IMAGE_BASE_WIDTH.PX_260}
            src={imageUrl}
            sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 33vw"
            itemProp={SCHEMA_PROPS.IMAGE}
          />
        </Box>
        <Flex
          height={EXPERT_NAME_HEIGHT}
          alignItems="flex-end"
          justifyContent="center"
        >
          <Text
            fontSize={{ MOBILE: FONT_SIZE.PX_14, TABLET: FONT_SIZE.PX_16 }}
            fontFamily={FONT_STACK.SANS}
            color={COLOR_INTENT.WIDGETS.ALT_EXPERT_LISTING.EXPERT_NAME}
            textAlign="center"
            as="p"
            itemProp={SCHEMA_PROPS.NAME}
          >
            {name}
          </Text>
        </Flex>
      </Flex>
    </Link>
    {position && (
      <meta itemProp={SCHEMA_PROPS.POSITION} content={String(position)} />
    )}
  </Box>
)
