import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Image, IMAGE_LAYOUT, FillImageWrapper } from 'components/Image'
import { Flex } from 'components/Layout'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { SPACE, FONT_SIZE } from 'Theme'

const SINGLE_IMAGE_NAME = 'Single widget image'

export const SingleImageWidget = ({ attachment, caption }) => (
  <Flex
    flexDirection="column"
    pt={SPACE.PX_20}
    pb={SPACE.PX_40}
    width="100%"
    data-test-class={DATA_TEST_CLASS.SINGLE_IMAGE_WIDGET}
  >
    <FillImageWrapper>
      <Image
        layout={IMAGE_LAYOUT.FILL}
        objectFit="contain"
        src={attachment.url}
        alt={attachment.alt || SINGLE_IMAGE_NAME}
        sizes="(max-width: 1024px) 100vw, (max-width: 1920px) 60vw, 45vw"
      />
    </FillImageWrapper>
    {caption && (
      <MarkdownRenderer
        markdownSource={caption}
        fontSize={FONT_SIZE.PX_12}
        mt={SPACE.PX_10}
      />
    )}
  </Flex>
)

SingleImageWidget.propTypes = {
  attachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  caption: PropTypes.string.isRequired,
}
