import React from 'react'
import { WIDGET_NAMES } from '@admin/routes/Cms/components/Widgets/constants'
import { Grid } from 'components/Layout'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { ASPECT_RATIO } from 'components/UploadImage'
import { ValuePropositionItem } from 'components/ValuePropositionItem'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { SPACE } from 'Theme'

const CONSULTATIONS_IMAGE_ALT = 'A kitchen counter with a sink and a faucet'

const SHOWROOM_IMAGE_ALT = 'A green couch with a red rose in a vase'

const IMAGE_DIMENSIONS_MOBILE = {
  baseWidth: 768,
  baseHeight: 953,
  aspectRatio: ASPECT_RATIO.ALT_VALUE_PROPOSITION_MOBILE,
} as const

const IMAGE_DIMENSIONS_TABLET = {
  baseWidth: 708,
  baseHeight: 320,
  aspectRatio: ASPECT_RATIO.ALT_VALUE_PROPOSITION_TABLET,
} as const

const CONSULTATIONS_ITEM = {
  title: 'Consult top interior designers',
  subtitle: 'and get personalized advice for your home.',
  buttonText: 'Get advice',
  link: ROUTE.CONSULTATIONS(),
  position: 1,
} as const

const SHOWROOM_ITEM = {
  title: (
    <>
      Shop furniture <br /> & décor
    </>
  ),
  subtitle: 'curated by our Experts.',
  buttonText: 'Shop all',
  link: ROUTE.SHOWROOM(),
  position: 2,
} as const

export const AltValuePropositionWidget = () => (
  <section itemScope itemType={SCHEMA_TYPES.THING}>
    <meta
      itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
      content={WIDGET_NAMES.ALT_VALUE_PROPOSITION.name}
    />
    <Grid
      gridTemplateColumns="1fr 1fr"
      p={{ MOBILE: SPACE.PX_4, DESKTOP: SPACE.PX_8 }}
      gridColumnGap={{ MOBILE: SPACE.PX_4, DESKTOP: SPACE.PX_8 }}
      itemScope
      itemType={SCHEMA_TYPES.ITEM_LIST}
    >
      <MediaQueryWrapper
        mobile={
          <ValuePropositionItem
            {...CONSULTATIONS_ITEM}
            image={{
              src: 'https://theexpert-production.b-cdn.net/widgets/value-proposition-consultations-mobile.png',
              alt: CONSULTATIONS_IMAGE_ALT,
              ...IMAGE_DIMENSIONS_MOBILE,
            }}
          />
        }
        tablet={
          <ValuePropositionItem
            {...CONSULTATIONS_ITEM}
            image={{
              src: 'https://theexpert-production.b-cdn.net/widgets/value-proposition-consultations.png',
              alt: CONSULTATIONS_IMAGE_ALT,
              objectPosition: 0,
              ...IMAGE_DIMENSIONS_TABLET,
            }}
          />
        }
      />
      <MediaQueryWrapper
        mobile={
          <ValuePropositionItem
            {...SHOWROOM_ITEM}
            image={{
              src: 'https://theexpert-production.b-cdn.net/widgets/value-proposition-showroom-mobile.png',
              alt: SHOWROOM_IMAGE_ALT,
              ...IMAGE_DIMENSIONS_TABLET,
            }}
          />
        }
        tablet={
          <ValuePropositionItem
            {...SHOWROOM_ITEM}
            image={{
              src: 'https://theexpert-production.b-cdn.net/widgets/value-proposition-showroom.png',
              alt: SHOWROOM_IMAGE_ALT,
              objectPosition: 0,
              ...IMAGE_DIMENSIONS_TABLET,
            }}
          />
        }
      />
    </Grid>
  </section>
)
