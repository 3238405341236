import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Flex } from 'components/Layout'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { ROUTE } from 'constants/routes'
import { ARTICLE_MAX_WIDTH, FONT_SIZE, LINE_HEIGHT, SPACE } from 'Theme'

export const RichTextWidget = ({ richText }) => {
  const { pathname } = useRouter()
  const isArticlePage = pathname.startsWith(ROUTE.BLOG_LIST())

  return (
    <Flex
      flexDirection="column"
      px={{ DESKTOP: isArticlePage ? SPACE.PX_15 : 'unset' }}
      maxWidth={{ DESKTOP: isArticlePage ? ARTICLE_MAX_WIDTH.TEXT : 'unset' }}
      mx={{ DESKTOP: isArticlePage ? 'auto' : 'unset' }}
      data-test-class={DATA_TEST_CLASS.RICH_TEXT_WIDGET}
    >
      <MarkdownRenderer
        markdownSource={richText}
        fontSize={FONT_SIZE.PX_18}
        lineHeight={LINE_HEIGHT.XL}
      />
    </Flex>
  )
}

RichTextWidget.propTypes = {
  richText: PropTypes.string.isRequired,
}
