import { isArray, isEmpty, isNil, isObjectLike, omitBy } from 'lodash'
import { formatPriceFilterStateToInput } from '../../PriceFilter'

export const formatFilter = ({
  filter: {
    collections,
    price: { priceFrom, priceTo },
    ...rest
  },
  maxPrice,
}: {
  [key: string]: any
  maxPrice: number
}) => ({
  ...(collections && { collectionIds: collections }),
  price: formatPriceFilterStateToInput({
    from: priceFrom,
    to: priceTo,
    maxPrice,
  }),
  ...rest,
})

export const getAppliedFiltersOnly = (input) =>
  omitBy(input, (value) => {
    if (isObjectLike(value)) {
      if (isArray(value) && isEmpty(value)) {
        return true
      }
      return isEmpty(getAppliedFiltersOnly(value))
    }
    return isNil(value)
  })

export const AVAILABILITY_OPTIONS = [
  {
    label: 'Ready to ship',
    value: 'READY_TO_SHIP',
  },
]
