import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { useSwiper, useSwiperSlide } from 'swiper/react'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import {
  SPACE,
  FONT_SIZE,
  FONT_STACK,
  COLOR_INTENT,
  BORDER_WIDTH,
  RADIUS,
} from 'Theme'

const StyledFlex = styled(Flex)`
  border-radius: ${RADIUS.PX_10};
  border: ${BORDER_WIDTH.PX_1} solid ${COLOR_INTENT.GRAY_20};
`

const StyledText = styled(Text)`
  text-transform: capitalize;
`

const CLICK_SLIDE_DELAY = 300

export const Testimonial = ({ feedback, clientInfo }) => {
  const { isNext, isPrev } = useSwiperSlide()
  const swiper = useSwiper()

  return (
    <Flex flex={1} position="relative" pl={SPACE.PX_16} justifyContent="center">
      <StyledFlex
        className="swiper-slide-box"
        flex={1}
        justifyContent="center"
        padding={{
          MOBILE: `${SPACE.PX_24}`,
          TABLET: `${SPACE.PX_56} ${SPACE.PX_64}`,
        }}
        onClick={() => {
          if (isNext) {
            swiper.slideNext(CLICK_SLIDE_DELAY, true)
          } else if (isPrev) {
            swiper.slidePrev(CLICK_SLIDE_DELAY, true)
          }
        }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontFamily={FONT_STACK.SERIF}
            fontSize={{
              TABLET: FONT_SIZE.PX_24,
              MOBILE: FONT_SIZE.PX_20,
            }}
            textAlign="center"
          >
            {feedback}
          </Text>
          <Flex mt={SPACE.PX_30}>
            <StyledText
              color={COLOR_INTENT.GRAY_90}
              fontSize={{ TABLET: FONT_SIZE.PX_18, MOBILE: FONT_SIZE.PX_16 }}
              textAlign="center"
            >
              {clientInfo}
            </StyledText>
          </Flex>
        </Flex>
      </StyledFlex>
    </Flex>
  )
}

Testimonial.propTypes = {
  feedback: PropTypes.string.isRequired,
  clientInfo: PropTypes.string.isRequired,
}
