import { get } from 'lodash'
import { rem, remToPx, stripUnit } from 'polished'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { A11y, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  ARROW_WITH_BACKGROUND_WRAPPER_HEIGHT,
  CarouselNavigationArrow,
  getArrowStickySide,
} from 'components/CarouselNavigation/CarouselNavigationArrow'
import { Box, Flex } from 'components/Layout'
import { PAGE_MAX_WIDTH, SPACE } from 'Theme'
import { type CmsCardNavigationWidgetClientFieldsFragment } from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { CardNavigationCard, DEFAULT_CARD_WIDTH } from './CardNavigationCard'

interface CardNavigationCarouselProps {
  cmsCardNavigationWidgetItems: CmsCardNavigationWidgetClientFieldsFragment['cmsCardNavigationWidgetItems']
  maxSlidesCount: number
}

const CAROUSEL_NAVIGATION_Z_INDEX = 1

const SLIDES_OFFSET = 16

const LAYOUT_MAX_WIDTH = parseInt(
  stripUnit(remToPx(PAGE_MAX_WIDTH.LAYOUT)) as string
)
// Calculation of spacing from widget content to base content as widget is not part of container but should have similar spacing
const WIDGET_CONTENT_SPACING = `calc((100% - ${PAGE_MAX_WIDTH.LAYOUT}) / 2)`

const StyledSwiper = styled(Swiper).withConfig({
  shouldForwardProp: (prop) => !['observerUpdate'].includes(prop),
})<{ isSwipeable: boolean }>`
  ${space};
  user-select: none;
  .swiper-slide {
    height: auto;
    width: ${({ isSwipeable }) => isSwipeable && 'auto !important'};
    &:last-child {
      margin-right: 0 !important;
    }
  }
  .swiper-button-lock,
  .swiper-button-disabled {
    display: none;
  }
`

const ArrowWrapper = styled(Flex)`
  position: absolute;
  ${({ isStickyToLeft }) => getArrowStickySide({ isStickyToLeft })}
`

const NavigationWrapper = styled(Flex)`
  pointer-events: none;
`

export const CardNavigationCarousel: React.FC<
  React.PWC<CardNavigationCarouselProps>
> = ({ cmsCardNavigationWidgetItems, maxSlidesCount }) => {
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)
  const { TABLET: isTablet } = useMedia()
  const [slidesOffsetBefore, setSlidesOffsetBefore] = useState(SLIDES_OFFSET)
  const slidesCount = cmsCardNavigationWidgetItems.length
  const isStaticSwiper = slidesCount <= maxSlidesCount

  // On change of responsivity we need to recalculate left offset for slides, not needed on tablet as carousel is full width there
  const updateslidesOffsetBefore = useCallback(
    ({ width }) => {
      const offset = isTablet
        ? SLIDES_OFFSET
        : Math.max(
            // to calculate start offset for carousel we need to substract base width with layout constant plus 30
            // which means padding from left and right and then divide with two as its only start spacing
            (width - LAYOUT_MAX_WIDTH + 30) / 2,
            SLIDES_OFFSET as number
          )
      setSlidesOffsetBefore(offset)
    },
    [isTablet]
  )

  return (
    <Box
      pl={{
        TABLET: isStaticSwiper ? rem(15) : 0,
        DESKTOP: isStaticSwiper ? WIDGET_CONTENT_SPACING : 0,
      }}
      pr={{
        TABLET: isStaticSwiper ? rem(15) : 0,
        DESKTOP: isStaticSwiper ? WIDGET_CONTENT_SPACING : 0,
      }}
      mx={{
        DESKTOP: isStaticSwiper ? rem(15) : 0,
      }}
    >
      <StyledSwiper
        modules={[Navigation, A11y]}
        slidesOffsetBefore={
          // we only setting slidesOffsetBefore for swipeable carousel ( swipeable is only when slidesCount is greater than maxSlidesCount )
          isStaticSwiper ? 0 : slidesOffsetBefore
        }
        slidesPerView={isStaticSwiper ? maxSlidesCount : 'auto'}
        slidesPerGroup={maxSlidesCount}
        // for end offset we dont need to calculate space between carousel content and base content because its only number from design without extra spacing
        slidesOffsetAfter={isStaticSwiper ? 0 : SLIDES_OFFSET}
        spaceBetween={SLIDES_OFFSET}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        onInit={updateslidesOffsetBefore}
        onUpdate={(currentSwiperInstance) => {
          currentSwiperInstance.navigation.update()
        }}
        onResize={(currentSwiperInstance) => {
          updateslidesOffsetBefore(currentSwiperInstance)
          currentSwiperInstance.navigation.update()
        }}
        centerInsufficientSlides={isStaticSwiper}
        watchOverflow
        observer
        observerUpdate
        updateOnWindowResize
        isSwipeable={slidesCount > maxSlidesCount}
      >
        {cmsCardNavigationWidgetItems.map(
          ({
            title: itemTitle,
            attachment,
            cmsPage,
            id,
            positionIndexWeight,
          }) => {
            const href = get(cmsPage, 'href')
            return (
              <SwiperSlide key={id}>
                <CardNavigationCard
                  index={positionIndexWeight}
                  title={itemTitle}
                  imageUrl={attachment.url}
                  alt={attachment.alt}
                  url={href}
                  width={isStaticSwiper ? 'auto' : undefined}
                />
              </SwiperSlide>
            )
          }
        )}
        <NavigationWrapper
          position="absolute"
          top={0}
          height="100%"
          width="100%"
          px={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_16 }}
          justifyContent="space-between"
          alignItems="start"
          paddingTop={`calc((${DEFAULT_CARD_WIDTH}  / 2) - (${ARROW_WITH_BACKGROUND_WRAPPER_HEIGHT} / 2))`}
          zIndex={CAROUSEL_NAVIGATION_Z_INDEX}
        >
          <ArrowWrapper isStickyToLeft>
            <CarouselNavigationArrow
              ref={prevButtonRef}
              hasBackground
              aria-label="previous arrow button"
              isDarkTheme
              isLtr={false}
            />
          </ArrowWrapper>
          <ArrowWrapper>
            <CarouselNavigationArrow
              ref={nextButtonRef}
              hasBackground
              aria-label="next arrow button"
              isDarkTheme
              isLtr
            />
          </ArrowWrapper>
        </NavigationWrapper>
      </StyledSwiper>
    </Box>
  )
}
