import { isEmpty, isString } from 'lodash'
import { formatPriceFilterStateToInput } from 'components/PriceFilter'
import { EXPERTS_LISTING_WIDGET_LIMIT } from 'constants/common'
import { useFetchNextPageQuery } from 'hooks'
import {
  type WidgetsByIdInput,
  CmsWidgetByIdDocument as CMS_WIDGET_BY_ID_QUERY,
  type CmsExpertsListingWidgetFieldsFragment,
  type CmsWidgetByIdQuery,
} from 'types/graphql-generated'

export const FILTER_MAX_PRICE = 1500
export const EXPERT_FIRST_PAGE = 1

const parseSessionLengthToQuery = (sessionLength) => {
  if (isEmpty(sessionLength)) {
    return null
  }
  if (isString(sessionLength)) {
    return Number(sessionLength)
  }
  return sessionLength.map(Number)
}

const createInputVariables = ({
  page,
  limit = EXPERTS_LISTING_WIDGET_LIMIT,
  currencyIsoCode,
  sessionLength,
  priceFrom,
  priceTo,
  availabilityFrom,
  availabilityTo,
  tags,
  regions,
}) => ({
  input: {
    currencyIsoCode,
    pagination: {
      page: parseInt(page),
      limit,
    },
    filter: {
      isFindable: true,
      ...(sessionLength?.length
        ? {
            sessionLength: parseSessionLengthToQuery(sessionLength),
          }
        : {}),
      price: formatPriceFilterStateToInput({
        from: priceFrom,
        to: priceTo,
        maxPrice: FILTER_MAX_PRICE,
      }),
      ...(availabilityFrom &&
        availabilityTo && {
          availability: {
            from: availabilityFrom,
            to: availabilityTo,
          },
        }),
      ...(tags?.length
        ? {
            tags,
          }
        : {}),
      ...(regions?.length
        ? {
            regions,
          }
        : {}),
    },
  },
})

export const useExpertsQuery = ({
  widgetId,
  page,
  sessionLength,
  priceFrom,
  priceTo,
  availabilityFrom,
  availabilityTo,
  tags,
  regions,
  isRouterReady,
  currencyIsoCode,
}) => {
  const { data, loading, fetchNextPage } = useFetchNextPageQuery<
    CmsWidgetByIdQuery,
    { input: WidgetsByIdInput }
  >(CMS_WIDGET_BY_ID_QUERY, {
    fetchPolicy: 'cache-first',
    currentPage: parseInt(page),
    pageSize: EXPERTS_LISTING_WIDGET_LIMIT,
    variablesFactory: (paginationInfo) => ({
      input: {
        widgetId,
        cmsExpertsListingWidgetInput: createInputVariables({
          ...paginationInfo,
          currencyIsoCode,
          sessionLength,
          priceFrom,
          priceTo,
          availabilityFrom,
          availabilityTo,
          tags,
          regions,
        }),
      },
    }),
    skip: !isRouterReady,
  })

  const widget = data?.cmsWidgetById
    ?.content as CmsExpertsListingWidgetFieldsFragment
  const nextPage = widget?.expertItems?.paginationInfo?.nextPage

  return {
    experts: widget?.expertItems?.data ?? [],
    nextPage,
    isLoading: loading,
    currencyIsoCode,
    fetchNextPage,
  }
}
