import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import {
  ExpertCard,
  useClientFavoriteExpertsManagement,
} from 'components/ExpertCard'
import { Flex, Grid } from 'components/Layout'
import { LOADER_SIZE } from 'components/Loader'
import { PAGE_MIN_HEIGHT, WrappedLoader } from 'components/WrappedLoader'
import {
  GA4_SIGN_IN_AREAS,
  useGoogleAnalytics,
  GA4_EVENTS,
  GA4_AREAS,
  GA4_PARAMS,
} from 'providers/googleAnalytics'
import { useItemsListViewGA4 } from 'providers/googleAnalytics/utils/ListView/useItemsListViewGA4'
import { useItemListStore, LIST_ENTITY_TYPE } from 'providers/useItemListStore'
import { useUserAuth } from 'providers/userAuth'
import { USER_TYPE } from 'providers/userAuthUtil'
import { SPACE } from 'Theme'
import { formatLocation } from 'utils/formatters'
import { silentPushUrl } from 'utils/pushUrl'

const AuthModal = dynamic(
  () => import('components/AuthModal').then((module) => module.AuthModal),
  { ssr: false }
)

export const ExpertsList = ({
  children,
  experts,
  isLoading: isQueryLoading,
  isWithLoading,
  route,
}) => {
  useItemsListViewGA4({
    overrideData: {
      area: GA4_AREAS.CONSULTATIONS,
    },
  })
  const router = useRouter()
  const { isClientSignedIn, user } = useUserAuth()
  const { executeDataToDataLayer, getPropertyInTree } = useGoogleAnalytics()
  const { setItemListData } = useItemListStore()
  const [isClicked, setIsClicked] = useState()

  const {
    addExpertToFavorites,
    removeExpertFromFavorites,
    clientFavoriteExpertsSlugs,
    expertId,
    slug: expertSlug,
    isAuthModalOpen,
    isLoading: isFavoriteExpertLoading,
  } = useClientFavoriteExpertsManagement({
    clientId: isClientSignedIn && user?.id,
  })

  const hasChildren = Array.isArray(children) && children.some(Boolean)

  const isLoading = isQueryLoading && isWithLoading
  return (
    <>
      {isLoading && experts.length === 0 && (
        <WrappedLoader
          isFillSpace={false}
          minHeight={PAGE_MIN_HEIGHT}
          loaderSize={LOADER_SIZE.S}
        />
      )}
      {experts.length > 0 && (
        <Flex flexDirection="column" position="relative">
          <Grid
            width="100%"
            mb={hasChildren ? SPACE.PX_40 : 'unset'}
            gridGap={SPACE.PX_32}
            gridAutoRows={{ MOBILE: 'auto', TABLET: '1fr' }}
            gridTemplateColumns={{
              MOBILE: '1fr',
              TABLET: 'repeat(3, 1fr)',
              DESKTOP: 'repeat(4, 1fr)',
            }}
          >
            {experts.map((expert, index) => {
              const {
                id,
                profileImageAttachment,
                profileName,
                jobTitle,
                slug,
                primaryLocation,
                city,
                countryIsoCode,
              } = expert

              return (
                <ExpertCard
                  data-test-class={DATA_TEST_CLASS.EXPERT_CARD}
                  height="100%"
                  isWithRedirect
                  slug={slug}
                  name={profileName}
                  id={id}
                  index={index}
                  key={id}
                  profileImageAttachment={profileImageAttachment}
                  jobTitle={jobTitle}
                  isReportingListItemGA
                  isClientSignedIn={isClientSignedIn}
                  isFavorite={
                    isClientSignedIn &&
                    clientFavoriteExpertsSlugs.includes(slug)
                  }
                  isAddToFavoriteLoading={isFavoriteExpertLoading}
                  onRemoveFromFavorite={() =>
                    removeExpertFromFavorites({
                      slug,
                      expertId: id,
                    })
                  }
                  onAddToFavorite={() =>
                    addExpertToFavorites({
                      slug,
                      expertId: id,
                    })
                  }
                  onNotAuthorizedClick={() => {
                    silentPushUrl({
                      router,
                      route,
                      params: {
                        isAuthModalOpen: true,
                        expertId: id,
                        slug,
                        fromUrl: GA4_SIGN_IN_AREAS.ADD_TO_WISHLIST,
                      },
                    })
                  }}
                  onClickExpert={() => {
                    if (isClicked) {
                      return
                    }
                    setItemListData(
                      {
                        entityId: id,
                        entityType: LIST_ENTITY_TYPE.EXPERTS,
                      },
                      {
                        itemListName: getPropertyInTree(
                          GA4_PARAMS.ITEM_LIST_NAME
                        ),
                        index,
                      }
                    )
                    executeDataToDataLayer({
                      event: GA4_EVENTS.SELECT_ITEM,
                      overrideData: {
                        area: GA4_AREAS.CONSULTATIONS,
                        items: [{ index, ...expert }],
                      },
                    })
                    setIsClicked(true)
                  }}
                  {...(primaryLocation && city && countryIsoCode
                    ? {
                        location: formatLocation({
                          primaryLocation,
                          city,
                          countryIsoCode,
                        }),
                      }
                    : {})}
                >
                  {id}
                </ExpertCard>
              )
            })}
          </Grid>
          {children}
        </Flex>
      )}
      <AuthModal
        userAuthType={USER_TYPE.CLIENT}
        isLoading={isFavoriteExpertLoading}
        onModalClose={() => {
          silentPushUrl({
            router,
            route,
          })
        }}
        isModalOpen={isAuthModalOpen}
        onSignInCompleted={async () => {
          if (isClientSignedIn) {
            silentPushUrl({
              router,
              route,
            })
            await addExpertToFavorites({ expertId, slug: expertSlug })
          }
        }}
      />
    </>
  )
}

ExpertsList.defaultProps = {
  experts: [],
  isLoading: true,
  isWithLoading: true,
}

ExpertsList.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isWithLoading: PropTypes.bool,
  route: PropTypes.string.isRequired,
  experts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      profileImageAttachment: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
      profileName: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      jobTitle: PropTypes.string,
      primaryLocation: PropTypes.string,
      city: PropTypes.string,
      countryIsoCode: PropTypes.string,
    }).isRequired
  ),
}
