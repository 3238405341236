import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { type IconProps, ICON_SIZE } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import {
  BORDER_WIDTH,
  COLOR_INTENT,
  FONT_SIZE,
  FONT_WEIGHT,
  mq,
  RADIUS,
  SPACE,
} from 'Theme'

const ITEM_ICON_SIZE = ICON_SIZE.PX_48
const ITEM_SIZE_TABLET = rem(400)

const Wrapper = styled(Box)`
  border: ${BORDER_WIDTH.PX_1} solid
    ${COLOR_INTENT.WIDGETS.VALUE_PROPOSITION.BORDER};
  border-radius: ${RADIUS.PX_8};
  cursor: pointer;
  transition: border-color cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
  &:hover {
    border-color: ${COLOR_INTENT.WIDGETS.VALUE_PROPOSITION.BORDER_HOVER};
  }
`

const StyledFlex = styled(Flex)`
  gap: ${SPACE.PX_16};

  ${mq.from.TABLET`
    margin: 0 auto;
    width: ${ITEM_SIZE_TABLET};
  `}

  ${mq.from.DESKTOP`
    width: 100%;
    gap: ${SPACE.PX_24};
  `}
`

export interface ValuePropositionWidgetItemProps {
  title: string
  description: string
  href: string
  icon?: React.ElementType<IconProps>
}

export const ValuePropositionWidgetItem: React.FC<
  React.PWC<ValuePropositionWidgetItemProps>
> = ({ title, description, icon: Icon, href }) => (
  <Link href={href} color={COLOR_INTENT.GRAY_90} aria-label={title}>
    <Wrapper>
      <StyledFlex px={SPACE.PX_24} py={SPACE.PX_16}>
        <Box width={ITEM_ICON_SIZE} height={ITEM_ICON_SIZE}>
          <Icon
            size={ITEM_ICON_SIZE}
            color={COLOR_INTENT.WIDGETS.VALUE_PROPOSITION.ICON}
          />
        </Box>
        <Flex flexDirection="column">
          <Text
            fontWeight={FONT_WEIGHT.MEDIUM}
            fontSize={FONT_SIZE.PX_16}
            mb={SPACE.PX_4}
          >
            {title}
          </Text>
          <Text>{description}</Text>
        </Flex>
      </StyledFlex>
    </Wrapper>
  </Link>
)
