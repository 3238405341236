import { rem } from 'polished'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import isRequiredIf from 'react-proptype-conditional-require'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Button, BUTTON_SIZE } from 'components/Button'
import { CARD_ELEVATION } from 'components/Card'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex, Box } from 'components/Layout'
import Link from 'components/Link'
import { H5, Text } from 'components/Typography'
import { ROUTE } from 'constants/routes'
import { useGAListItemView } from 'providers/googleAnalytics/utils/ListView/useGAListItemView'
import { FONT_STACK, SPACE, COLOR, RADIUS, FONT_SIZE } from 'Theme'
import { FavoriteMarker } from './FavoriteMarker'

const PROFILE_PHOTO_MAX_SIZE = rem(120)
const PROFILE_PHOTO_MINIMAL_SIZE_IN_PX = 120

export const EXPERT_CARD_VARIANT = {
  DEFAULT: 'DEFAULT',
  MINIMAL: 'MINIMAL',
}

const Photo = styled(Image)`
  ${({ variant }) =>
    variant === EXPERT_CARD_VARIANT.MINIMAL &&
    `
      border-radius: ${RADIUS.CIRCLE};
  `}
`

const Wrapper = styled(Box)`
  overflow: hidden;
  ${({ variant }) =>
    variant === EXPERT_CARD_VARIANT.DEFAULT &&
    `
    border-radius: ${RADIUS.PX_10};
    box-shadow: ${CARD_ELEVATION.ELEVATION_1};
  `}
  &:hover {
    ${({ onClick }) => onClick && `cursor: pointer;`}
  }
`

export const ExpertCard = ({
  id,
  isWithRedirect,
  profileImageAttachment,
  name,
  index,
  jobTitle,
  onClickExpert,
  socialLinks,
  location,
  variant,
  hasBookNowButton,
  onBookNow,
  slug,
  height,
  isFavorite,
  onRemoveFromFavorite,
  onAddToFavorite,
  isAddToFavoriteLoading,
  onNotAuthorizedClick,
  isReportingListItemGA,
}) => {
  const intersectionRef = useRef(null)
  useGAListItemView({
    itemRef: intersectionRef,
    reportData: { id, profileName: name, index, __typename: 'Expert' },
    isActive: isReportingListItemGA,
  })
  const isMinimal = variant === EXPERT_CARD_VARIANT.MINIMAL

  const content = (
    <Wrapper
      height={height}
      width="100%"
      onClick={onClickExpert}
      elevation={CARD_ELEVATION.ELEVATION_2}
      variant={variant}
      data-test-class={DATA_TEST_CLASS.EXPERT_CARD}
      ref={intersectionRef}
    >
      <Flex flexDirection="column" height="auto" width="100%" flexShrink={0}>
        <Flex
          flexDirection="column"
          width="100%"
          height="100%"
          {...(variant === EXPERT_CARD_VARIANT.MINIMAL && {
            alignSelf: 'center',
            maxHeight: PROFILE_PHOTO_MAX_SIZE,
            maxWidth: PROFILE_PHOTO_MAX_SIZE,
          })}
        >
          <Photo
            variant={variant}
            layout={IMAGE_LAYOUT.RESPONSIVE}
            alt={profileImageAttachment?.alt || "Expert's profile picture"}
            src={profileImageAttachment?.url}
            sizes={`
            (max-width: 768px) ${
              variant === EXPERT_CARD_VARIANT.MINIMAL
                ? `${PROFILE_PHOTO_MINIMAL_SIZE_IN_PX}px`
                : '100vw'
            }, 33vw`}
          />
        </Flex>
        <Flex
          position="relative"
          alignItems="center"
          flexDirection="column"
          pt={isMinimal ? SPACE.PX_20 : SPACE.PX_30}
          flex={1}
          pb={SPACE.PX_30}
          px={SPACE.PX_16}
        >
          <FavoriteMarker
            isLoading={isAddToFavoriteLoading}
            isFavorite={isFavorite}
            onRemoveFromFavorite={onRemoveFromFavorite}
            onAddToFavorite={onAddToFavorite}
            onNotAuthorizedClick={onNotAuthorizedClick}
            position="absolute"
            top={0}
            right={0}
          />
          <H5 textAlign="center" mb={0} color={COLOR.GRAYSCALE_7}>
            {name}
          </H5>
          {location && (
            <Text
              fontSize={FONT_SIZE.PX_14}
              fontFamily={FONT_STACK.SERIF}
              color={COLOR.GRAYSCALE_2}
              mt={SPACE.PX_5}
            >
              {location}
            </Text>
          )}
          <Text
            textAlign="center"
            fontSize={FONT_SIZE.PX_16}
            fontFamily={FONT_STACK.SERIF}
            color={COLOR.GRAYSCALE_2}
            mt={isMinimal ? SPACE.PX_10 : SPACE.PX_20}
            mb={isMinimal ? SPACE.PX_5 : SPACE.PX_10}
            px={SPACE.PX_20}
          >
            {jobTitle}
          </Text>
          {socialLinks?.instagram && (
            <Flex mb={SPACE.PX_15}>
              <Box ml={SPACE.PX_10} mr={SPACE.PX_10}>
                <a
                  href={socialLinks.instagram}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Link to Instagram of ${name}`}
                >
                  <Icon.Instagram
                    size={ICON_SIZE.PX_20}
                    color={COLOR.GRAYSCALE_2}
                  />
                </a>
              </Box>
            </Flex>
          )}
          {isMinimal && hasBookNowButton && (
            <Box>
              <Button size={BUTTON_SIZE.L} onClick={onBookNow}>
                Book now
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
    </Wrapper>
  )

  if (isWithRedirect) {
    return (
      <Flex position="relative">
        <Link
          display="flex"
          href={ROUTE.EXPERT_PROFILE({ slug })}
          width="100%"
          aria-label={`Profile of expert ${name}`}
        >
          {content}
        </Link>
      </Flex>
    )
  }
  return <Flex position="relative">{content}</Flex>
}

ExpertCard.propTypes = {
  id: PropTypes.number,
  height: PropTypes.string,
  isWithRedirect: PropTypes.bool,
  slug: isRequiredIf(PropTypes.string, (props) => props.isWithRedirect),
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  jobTitle: PropTypes.string,
  profileImageAttachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  onClickExpert: PropTypes.func,
  socialLinks: PropTypes.shape({
    youtube: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    facebook: PropTypes.string,
    linkedin: PropTypes.string,
  }),
  location: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(EXPERT_CARD_VARIANT)),
  hasBookNowButton: PropTypes.bool,
  onBookNow: isRequiredIf(PropTypes.func, (props) => props.hasBookNowButton),
  isFavorite: PropTypes.bool,
  onRemoveFromFavorite: PropTypes.func,
  onAddToFavorite: PropTypes.func,
  isAddToFavoriteLoading: PropTypes.bool,
  onNotAuthorizedClick: PropTypes.func,
  isReportingListItemGA: PropTypes.bool,
}

ExpertCard.defaultProps = {
  variant: EXPERT_CARD_VARIANT.DEFAULT,
}
