import { get, isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import React, { useCallback } from 'react'
import { useLocation } from 'react-use'
import { AvailabilityFilter } from 'components/AvailabilityFilter'
import {
  ComposedFilters,
  useComposedFiltersStateManagement,
} from 'components/ComposedFilters'
import {
  formatUpdatedPrices,
  PriceFilter,
  setPriceFilterStateOnExit,
  usePriceFilterStateManagement,
} from 'components/PriceFilter'
import { TagFilter } from 'components/TagFilter'
import { COMPOSED_FILTERS_CONFIGURATION_NAMES } from 'constants/common'
import { useCurrency } from 'providers/currency'
import { useGoogleAnalytics } from 'providers/googleAnalytics'
import { executePriceFilterEvent } from 'providers/googleAnalytics/utils/Filter/executePriceFilterEvent'
import {
  usePublicRegionsQuery,
  usePublicTagsQuery,
} from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { EXPERT_FIRST_PAGE, FILTER_MAX_PRICE } from './hooks'
import {
  getTagFilterValueFromParams,
  SESSION_LENGTH_FILTER_OPTIONS,
  silentPushExpertsUrl,
} from './utils'

const mapTagFilterOptions = (options) =>
  options.map(({ title: label, value, children = [] }) => ({
    label,
    value,
    children: children.map(
      ({ title: childrenLabel, value: childrenValue }) => ({
        label: childrenLabel,
        value: childrenValue,
      })
    ),
  }))

const EMPTY_ARRAY = []

interface ExpertsFilterProps {
  expertsParams: {
    priceFrom: number
    priceTo: number
    availabilityFrom?: string
    availabilityTo?: string
    sessionLength?: string | string[]
    tags?: string | string[]
    regions?: string | string[]
  }
}

interface FilterValues {
  [COMPOSED_FILTERS_CONFIGURATION_NAMES.SESSION_LENGTH]: string[]
  [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: {
    priceFrom: number
    priceTo: number
  }
  [COMPOSED_FILTERS_CONFIGURATION_NAMES.AVAILABILITY]: {
    availabilityFrom?: string
    availabilityTo?: string
  }
  [COMPOSED_FILTERS_CONFIGURATION_NAMES.TAGS]: string[]
  [COMPOSED_FILTERS_CONFIGURATION_NAMES.REGIONS]: string[]
}

export const ExpertsFilter: React.FC<React.PWC<ExpertsFilterProps>> = ({
  expertsParams,
}) => {
  const media = useMedia()
  const location = useLocation()
  const router = useRouter()
  const { currencyIsoCode } = useCurrency()
  const {
    priceTo,
    priceFrom,
    availabilityFrom,
    availabilityTo,
    sessionLength,
    tags,
    regions,
  } = expertsParams
  const regionsFilterOptions = mapTagFilterOptions(
    get(usePublicRegionsQuery(), ['data', 'publicRegions'], EMPTY_ARRAY)
  )
  const tagsFilterOptions = mapTagFilterOptions(
    get(usePublicTagsQuery(), ['data', 'publicTags'], EMPTY_ARRAY)
  )
  const { executeDataToDataLayer } = useGoogleAnalytics()

  const { values, onChange, onClearAll } =
    useComposedFiltersStateManagement<FilterValues>({
      initialValues: {
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.SESSION_LENGTH]:
          getTagFilterValueFromParams(sessionLength),
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: {
          priceFrom,
          priceTo,
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.AVAILABILITY]: {
          availabilityFrom,
          availabilityTo,
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.TAGS]:
          getTagFilterValueFromParams(tags),
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.REGIONS]:
          getTagFilterValueFromParams(regions),
      },
      submit: (updatedParams) => {
        const searchParams = queryString.parse(location.search)
        silentPushExpertsUrl({
          router,
          route: location.pathname,
          params: {
            ...searchParams,
            ...expertsParams,
            ...updatedParams,
            page: EXPERT_FIRST_PAGE,
          },
        })
      },
    })
  const { setPriceFrom, setPriceTo, ...priceFilterValue } =
    usePriceFilterStateManagement({
      onChange: (updatedValues) => {
        onChange({
          updatedValues: {
            [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: formatUpdatedPrices({
              ...updatedValues,
              maxPrice: FILTER_MAX_PRICE,
            }),
          },
          isImmediateSubmit: !media.MOBILE,
        })

        executePriceFilterEvent({
          isNotMobile: !media.MOBILE,
          updatedValues,
          previousValues: values.price,
          executeDataToDataLayer,
          maxPrice: FILTER_MAX_PRICE,
          currencyIsoCode,
        })
      },
      initialPriceFrom: priceFrom,
      initialPriceTo: priceTo,
    })

  const setComponentStateOnExit = useCallback(() => {
    setPriceFilterStateOnExit({
      state: priceFilterValue,
      setPriceFrom,
      setPriceTo,
      initialFrom: 0,
      initialTo: FILTER_MAX_PRICE,
    })
  }, [setPriceFrom, setPriceTo, priceFilterValue])
  return (
    <ComposedFilters
      configuration={{
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.SESSION_LENGTH]: {
          viewComponent: TagFilter,
          label: 'Consultation length',
          isActive: (value) => !isEmpty(value),
          viewComponentProps: {
            options: SESSION_LENGTH_FILTER_OPTIONS,
            columns: media.MOBILE ? 2 : 1,
          },
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: {
          viewComponent: PriceFilter,
          label: 'Price',
          isActive: (price) =>
            !(
              get(price, 'priceFrom', 0) === 0 &&
              get(price, 'priceTo', FILTER_MAX_PRICE) === FILTER_MAX_PRICE
            ),
          setComponentStateOnExit,

          viewComponentProps: {
            maxPrice: FILTER_MAX_PRICE,
            ...priceFilterValue,
            setPriceFrom,
            setPriceTo,
            currencyIsoCode,
          },
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.AVAILABILITY]: {
          viewComponent: AvailabilityFilter,
          label: 'Availability',
          isActive: (availability) => !!get(availability, 'availabilityFrom'),
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.TAGS]: {
          viewComponent: TagFilter,
          label: 'Style',
          isActive: (value) => !isEmpty(value),
          viewComponentProps: {
            options: tagsFilterOptions,
            columns: 2,
            placement: media.TABLET ? 'bottom-right' : 'bottom-left',
          },
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.REGIONS]: {
          viewComponent: TagFilter,
          label: 'Regions',
          isActive: (value) => !isEmpty(value),
          viewComponentProps: {
            options: regionsFilterOptions,
            placement: media.TABLET ? 'bottom-right' : 'bottom-left',
          },
        },
      }}
      values={values}
      onChange={onChange}
      onClearAll={() => {
        onClearAll()
        setPriceFrom(0)
        setPriceTo(FILTER_MAX_PRICE)
      }}
    />
  )
}
