import { useRouter } from 'next/router'
import { rem } from 'polished'
import React from 'react'
import { BUTTON_VARIANT } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { WIDGET_PLACEMENT } from 'constants/common'
import { GA4_EVENTS, useGoogleAnalytics } from 'providers/googleAnalytics'
import {
  SPACE,
  FONT_WEIGHT,
  FONT_STACK,
  COLOR_INTENT,
  LINE_HEIGHT,
} from 'Theme'
import { type CmsHeroMainWidget } from 'types/graphql-generated'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

const HERO_IMAGE_NAME = 'Hero image'

export const HeroMainWidget: React.FC<React.PWC<CmsHeroMainWidget>> = ({
  headline,
  subheadline,
  mainButtonText,
  mainButtonLink,
  hasSecondaryButton,
  secondaryButtonText,
  secondaryButtonLink,
  attachment,
}) => {
  const { asPath } = useRouter()
  const { executeDataToDataLayer } = useGoogleAnalytics()

  return (
    <Flex
      backgroundColor={COLOR_INTENT.WIDGETS.HERO_MAIN.BACKGROUND}
      flexDirection={{ MOBILE: 'column-reverse', TABLET: 'row' }}
      mt={{ DESKTOP: SPACE.PX_1 }}
    >
      <Box width={{ MOBILE: '100%', TABLET: '50%' }}>
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          mt={{ MOBILE: SPACE.PX_32, TABLET: 0 }}
          height="100%"
        >
          <Box
            width="100%"
            maxWidth={{ MOBILE: '100%', TABLET: '575px' }}
            ml={SPACE.PX_15}
            mr={{ MOBILE: SPACE.PX_15, TABLET: 0 }}
          >
            <Flex
              flexDirection="column"
              alignItems={{ MOBILE: 'center', TABLET: 'flex-start' }}
              maxWidth={{ MOBILE: '100%', TABLET: '440px' }}
              pr={{ MOBILE: 0, TABLET: SPACE.PX_15 }}
            >
              <WidgetTitle
                placement={WIDGET_PLACEMENT.HERO}
                lineHeight={LINE_HEIGHT.XXXS}
                textAlign={{ MOBILE: 'center', TABLET: 'left' }}
                mb={SPACE.PX_12}
              >
                {headline}
              </WidgetTitle>
              {subheadline && (
                <WidgetSubtitle
                  textAlign={{ MOBILE: 'center', TABLET: 'left' }}
                  mt={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_12 }}
                  mb="0"
                >
                  {subheadline}
                </WidgetSubtitle>
              )}
              <Flex
                flexDirection="row"
                justifyContent={{ MOBILE: 'center', TABLET: 'start' }}
                mt={SPACE.PX_24}
                mb={{ MOBILE: SPACE.PX_32, TABLET: 0 }}
                gap={SPACE.PX_8}
              >
                <StyledLink
                  href={mainButtonLink}
                  fontFamily={FONT_STACK.SANS}
                  fontWeight={FONT_WEIGHT.MEDIUM}
                  color={COLOR_INTENT.TEXT_ON_DARK}
                  aria-label={mainButtonText}
                  onClick={() => {
                    executeDataToDataLayer({
                      event: GA4_EVENTS.CLICK,
                      overrideData: {
                        text: mainButtonText,
                        url: asPath,
                        name: 'Main button of hero main widget',
                      },
                    })
                  }}
                >
                  {mainButtonText}
                </StyledLink>
                {hasSecondaryButton && (
                  <StyledLink
                    href={secondaryButtonLink}
                    variant={BUTTON_VARIANT.OUTLINE}
                    fontFamily={FONT_STACK.SANS}
                    fontWeight={FONT_WEIGHT.MEDIUM}
                    color={COLOR_INTENT.TEXT_ON_LIGHT}
                    border="none"
                    aria-label={secondaryButtonText}
                    onClick={() => {
                      executeDataToDataLayer({
                        event: GA4_EVENTS.CLICK,
                        overrideData: {
                          text: secondaryButtonText,
                          url: asPath,
                          name: 'Secondary button of hero main widget',
                        },
                      })
                    }}
                  >
                    {secondaryButtonText}
                  </StyledLink>
                )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box
        width={{ MOBILE: '100%', TABLET: '50%' }}
        position="relative"
        minHeight={{ MOBILE: rem(240), TABLET: rem(540) }}
      >
        <Image
          alt={attachment.alt || HERO_IMAGE_NAME}
          src={attachment.url}
          layout={IMAGE_LAYOUT.FILL}
          objectFit="cover"
        />
      </Box>
    </Flex>
  )
}
