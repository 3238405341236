import { rem } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { H2 } from 'components/Typography'
import {
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  LINE_HEIGHT,
  mq,
  SPACE,
} from 'Theme'
import { EXPERT_IMAGE_SIZE } from '../../constants'

const TITLE_MIN_HEIGHT = rem(56)

const Title = styled(H2)`
  ${({ isWithExpertPhoto }) => mq.to.TABLET`
    ${
      isWithExpertPhoto &&
      css`
        min-height: ${TITLE_MIN_HEIGHT};
      `
    }
  `}
`

interface CarouselTitleProps {
  isWithExpertPhoto: boolean
}

export const CarouselTitle: React.FC<React.PWC<CarouselTitleProps>> = ({
  isWithExpertPhoto,
  children,
}) => (
  <Title
    isWithExpertPhoto={isWithExpertPhoto}
    fontFamily={FONT_STACK.SERIF}
    fontSize={{
      MOBILE: FONT_SIZE.PX_32,
      TABLET: FONT_SIZE.PX_36,
    }}
    fontWeight={FONT_WEIGHT.NORMAL}
    lineHeight={{ MOBILE: LINE_HEIGHT.XS, TABLET: LINE_HEIGHT.M }}
    pl={{
      MOBILE: isWithExpertPhoto
        ? `calc(${EXPERT_IMAGE_SIZE.MOBILE.WIDTH} + ${SPACE.PX_16})`
        : 0,
      TABLET: 0,
    }}
    mt={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_20 }}
    mb={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_12 }}
  >
    {children}
  </Title>
)
