import { addHours, addWeeks, isSameDay } from 'date-fns'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { DatePicker, RangeHeader } from 'components/DatePicker'
import { Box } from 'components/Layout'
import {
  ALLOWED_HOURS_TO_BOOK_SESSION,
  FILTER_VIEW_COMPONENT_NAMES,
  NUMBER_OF_AVAILABLE_WEEKS_IN_FUTURE,
} from 'constants/common'
import { useUserAuth } from 'providers/userAuth'
import { useMedia } from 'useMedia'

const Wrapper = styled(Box)`
  .react-datepicker {
    box-shadow: none;
  }

  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker .react-datepicker__month {
    ${({ isMobile }) =>
      isMobile &&
      css`
        margin: 0;
      `}
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    ${({ isMobile }) =>
      isMobile
        ? css`
            width: 50px;
            line-height: 45px;
          `
        : css`
            width: 45px;
            line-height: 40px;
          `}
  }
`

interface AvailabilityFilterProps {
  availabilityFrom?: string | null | Date
  availabilityTo?: string | null | Date
  onChange: (payload: {
    availabilityFrom: string
    availabilityTo: string
  }) => void
}

interface AvailabilityFilterExtensions {
  identification: string
}

export const AvailabilityFilter: React.FC<AvailabilityFilterProps> &
  AvailabilityFilterExtensions = ({
  availabilityFrom,
  availabilityTo,
  onChange,
}) => {
  const media = useMedia()
  const { convertDateToTimezone, convertZonedDateToUTC } = useUserAuth()
  const [startDate, setStartDate] = useState(
    availabilityFrom ? new Date(availabilityFrom) : null
  )
  const [endDate, setEndDate] = useState(
    availabilityTo ? new Date(availabilityTo) : null
  )
  useEffect(() => {
    setStartDate(availabilityFrom ? new Date(availabilityFrom) : null)
  }, [availabilityFrom])

  const minimumDate = convertDateToTimezone(
    addHours(new Date(), ALLOWED_HOURS_TO_BOOK_SESSION)
  )
  return (
    <Wrapper isMobile={media.MOBILE}>
      <DatePicker
        inline
        selectsRange
        selected={startDate}
        onChange={(dates) => {
          const [start, end] = dates as Date[]
          setStartDate(
            convertZonedDateToUTC(
              isSameDay(start, minimumDate) ? minimumDate : start
            )
          )
          setEndDate(end ? convertZonedDateToUTC(end) : null)
          if (start && end) {
            onChange({
              availabilityFrom: convertZonedDateToUTC(start).toISOString(),
              availabilityTo: convertZonedDateToUTC(end).toISOString(),
            })
          }
        }}
        startDate={startDate}
        openToDate={
          startDate ?? convertDateToTimezone?.(new Date()) ?? new Date()
        }
        endDate={endDate}
        customHeader={(headerProps) => (
          <RangeHeader
            {...headerProps}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        isWithPortal={false}
        formatWeekDay={(weekday) => weekday[0]}
        minimumDate={minimumDate}
        maximumDate={addWeeks(
          convertDateToTimezone(),
          NUMBER_OF_AVAILABLE_WEEKS_IN_FUTURE
        )}
      />
    </Wrapper>
  )
}

AvailabilityFilter.identification = FILTER_VIEW_COMPONENT_NAMES.AVAILABILITY
