import { ReactQuery } from 'cms-api'
import { onAuthStateChanged } from 'firebase/auth'
import { get, intersectionBy, isEmpty, map, compact } from 'lodash'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-use'
import {
  CollectionWidgetCard,
  generateDummyProducts,
} from 'components/CollectionWidgetCard'
import { useComposedFiltersStateManagement } from 'components/ComposedFilters'
import { Flex, Grid } from 'components/Layout'
import { ListingWidgetLogic } from 'components/ListingWidget/ListingWidgetLogic'
import { CollectionWidgetFooter } from 'components/Widgets/MultiCollectionWidget/CollectionWidgetFooter'
import {
  COLLECTION_SORTER_FIELDS,
  COMPOSED_FILTERS_CONFIGURATION_NAMES,
  MULTI_COLLECTION_WIDGET_TYPE,
  PRODUCT_STATUSES,
  PRODUCT_VARIANT_STATUSES,
  SCHEMA_TYPES,
  SORTER_DIRECTION,
} from 'constants/common'
import { DYNAMIC_ROUTE } from 'constants/routes'
import { useUnloadPage } from 'hooks'
import { useItemsListViewGA4 } from 'providers/googleAnalytics/utils/ListView/useItemsListViewGA4'
import {
  setInputToCorrectPosition,
  useSharedOptions,
} from 'providers/sharedOptions'
import { PAGE_MAX_WIDTH, SPACE } from 'Theme'
import {
  type Brand,
  type CmsMultiCollectionWidget,
  type Collection,
} from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import auth from 'utils/firebase'
import { getProductRoute } from 'utils/getProductRoute'
import { klaviyoTrackViewedCollection } from 'utils/klaviyo'
import { useFetchMultiCollectionProducts } from '../hooks'
import { formatFilter, getAppliedFiltersOnly } from '../utils/collections'
import { WidgetTitle } from '../WidgetTitle'
import { MultiCollectionWidgetFilter } from './MultiCollectionWidgetFilter'
import { NoProducts } from './NoProducts'
import {
  formatCategoriesForCollapsibleTreeSelect,
  filterCollectionCategories,
  getPageSize,
  getRedundantItemsCount,
  getShowroomRouteFromSlug,
  parseSearchParamsComposedFilters,
  silentPushCmsPageUrl,
} from './utils'

const DEFAULT_FILTER_STATUSES = {
  statuses: [PRODUCT_STATUSES.PUBLISHED],
  variantStatuses: [PRODUCT_VARIANT_STATUSES.PUBLISHED],
}

const RELEVANCE_SORT_STATE = {
  field: COLLECTION_SORTER_FIELDS.POSITION_INDEX_WEIGHT,
  direction: SORTER_DIRECTION.ASC,
}

const EMPTY_ARRAY = []

const INITIAL_PRICE_FROM = 0

export interface MultiCollectionWidgetProps
  extends Omit<CmsMultiCollectionWidget, 'type'> {
  cmsPageSlug: string
  widgetId: number
  multiCollectionWidgetType: CmsMultiCollectionWidget['type']
  isSearchFilterParamsMode: boolean
}

const getCategories = ({ categoriesOne, categoriesTwo }) => {
  if (categoriesOne?.length > 1) {
    return categoriesOne
  }
  if (categoriesTwo?.length > 1) {
    return categoriesTwo
  }
  return []
}

export const MultiCollectionWidget: React.FC<
  React.PWC<MultiCollectionWidgetProps>
> = ({
  cmsPageSlug,
  widgetId,
  multiCollectionWidgetType,
  headline,
  isShopAllProductsButtonEnabled,
  cmsShowroomPage,
  isSortEnabled,
  defaultSortField,
  defaultSortDirection,
  cmsListingWidgets,
  cmsMultiCollectionWidgetItems,
  maxPrice,
  isSearchFilterParamsMode,
  expert,
  useBreakoutVariants,
}) => {
  useItemsListViewGA4()
  const [isRouteChanging, setIsRouteChanging] = useState(false)
  const [widgetInputs, setWidgetInputs] = useSharedOptions()
  const location = useLocation()
  const router = useRouter()
  const { MOBILE: isMobile, TABLET: isTablet } = useMedia()
  const searchParams = queryString.parse(location.search)
  const isInitialOnChange = useRef(true)
  const { route } = useRouter()
  const { data: blackFridayPromoCode } = ReactQuery.useGetPromoCodesBlackFriday(
    {
      query: {
        enabled: !(
          route === '/shop/[slug]' && cmsPageSlug === 'black-friday-sale'
        ),
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    }
  )
  // const { variant } = useExperiment()

  // FIXME: Hotfix because A/B test ended, need to do proper cleanup
  const variant = '1'

  const collectionFilterOptions = useMemo(
    () => [
      ...cmsMultiCollectionWidgetItems.map(({ caption, collection }) => ({
        label: caption || collection?.title,
        value: collection?.id,
      })),
    ],
    [cmsMultiCollectionWidgetItems]
  )
  useEffect(() => klaviyoTrackViewedCollection(cmsPageSlug), [cmsPageSlug])
  useUnloadPage(() => {
    setIsRouteChanging(true)
  })

  const cachedWidgetInputs = get(
    widgetInputs,
    [
      'cmsInputs',
      cmsPageSlug,
      widgetId,
      'cmsMultiCollectionProductsInput',
      'input',
    ],
    {}
  )

  const page: number = get(
    cachedWidgetInputs,
    ['pagination', 'page'],
    Number(get(searchParams, 'page', 1))
  )

  const handleSetWidgetInputsChange = useCallback(
    (input) => {
      setWidgetInputs((oldWidgetsInput) =>
        setInputToCorrectPosition({
          oldWidgetsInput,
          cmsPageSlug,
          widgetId,
          input: {
            cmsMultiCollectionProductsInput: {
              input: {
                ...input,
              },
            },
          },
        })
      )
    },
    [cmsPageSlug, setWidgetInputs, widgetId]
  )

  const defaultSortState =
    cachedWidgetInputs.sorter ??
    (isSortEnabled
      ? {
          field: get(searchParams, 'field', defaultSortField),
          direction: get(searchParams, 'direction', defaultSortDirection),
        }
      : RELEVANCE_SORT_STATE)

  const defaultFilterState = useMemo(
    () => ({
      ...(collectionFilterOptions.length > 1 && {
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.COLLECTIONS]: EMPTY_ARRAY,
      }),
      [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: {
        priceFrom: INITIAL_PRICE_FROM,
        priceTo: maxPrice.total,
      },
      ...cachedWidgetInputs.filter,
      ...(isSearchFilterParamsMode &&
        parseSearchParamsComposedFilters(searchParams)),
    }),
    [
      cachedWidgetInputs.filter,
      collectionFilterOptions.length,
      isSearchFilterParamsMode,
      maxPrice.total,
      searchParams,
    ]
  )

  const {
    values,
    onChange: composedFiltersStateOnChange,
    ...composedFilterStateManagementRest
  } = useComposedFiltersStateManagement({
    initialValues: {
      [COMPOSED_FILTERS_CONFIGURATION_NAMES.SORT]: defaultSortState,
      ...defaultFilterState,
    },
    ...(isSearchFilterParamsMode && {
      submit: (updatedParams) =>
        silentPushCmsPageUrl({
          router,
          cmsPageSlug,
          params: {
            ...parseSearchParamsComposedFilters({
              ...searchParams,
              ...updatedParams,
            }),
            page: 1,
          },
          maxPrice: maxPrice.total,
        }),
    }),
  })

  // Applies filter when user clicks on a brand option in search
  useEffect(() => {
    const handleRouteChange = (
      _: string,
      { shallow }: { shallow: boolean }
    ) => {
      // Url isn't pushed siletly on option select
      if (!shallow) {
        composedFiltersStateOnChange({
          updatedValues: parseSearchParamsComposedFilters(searchParams),
        })
        setIsRouteChanging(false)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [composedFiltersStateOnChange, defaultFilterState, router, searchParams])

  const { [COMPOSED_FILTERS_CONFIGURATION_NAMES.SORT]: sorter, ...filter } =
    values

  const formattedFilter = formatFilter({ filter, maxPrice: maxPrice.total })
  const isFilterApplied = !isEmpty(getAppliedFiltersOnly(formattedFilter))

  const pageSize = getPageSize({ isTablet })

  const redundantItemsCount = getRedundantItemsCount({
    isMobile,
    isTablet,
    pageSize,
    isFilterApplied,
    listingWidgetsCount: cmsListingWidgets?.length || 0,
  })

  const {
    isLoading,
    isMoreButtonVisible,
    totalProductCount,
    categoriesOne,
    categoriesTwo,
    categoriesThree,
    productsList,
    brands,
    fetchNextPage,
    refetch,
  } = useFetchMultiCollectionProducts({
    pageSize,
    currentPage: page,
    redundantItemsCount,
    variablesFactory: ({ page: newPage, limit }) => ({
      input: {
        widgetId,
        cmsMultiCollectionProductsInput: {
          input: {
            pagination: {
              page: newPage,
              limit,
            },
            sorter,
            filter: {
              ...DEFAULT_FILTER_STATUSES,
              ...formattedFilter,
            },
          },
        },
      },
    }),
  })

  // Trigger refetch when auth state changes to make sure that trade user sees correct prices rendered on the page
  useEffect(() => {
    const cleanup = onAuthStateChanged(auth, async () => {
      await refetch()
    })
    return cleanup
  }, [refetch])

  const isFeaturedType =
    multiCollectionWidgetType === MULTI_COLLECTION_WIDGET_TYPE.FEATURED

  const categoriesFilterOptions =
    variant === ('0' as any)
      ? map(
          getCategories({ categoriesOne, categoriesTwo }),
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        )
      : formatCategoriesForCollapsibleTreeSelect(
          filterCollectionCategories({
            categoriesOne,
            categoriesThree,
            categoriesTwo,
          })
        )

  const showroomPageSlug = get(cmsShowroomPage, ['cmsPage', 'slug'], null)
  const expertSlug = get(expert, ['slug'], null)

  const brandFilterOptions = map(
    compact<Brand>(brands),
    ({ id: brandName }) => ({
      label: brandName,
      value: brandName,
    })
  )

  const isBrandsFilterShown = route !== DYNAMIC_ROUTE.SHOWROOM_BRAND_PAGE()

  const shouldApplyThingSchema =
    !isFilterApplied &&
    productsList.length &&
    cmsListingWidgets?.length &&
    !isFeaturedType

  return (
    <Flex
      justifyContent="center"
      width="100%"
      mt={isFeaturedType && { MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
      pb={{ MOBILE: SPACE.PX_30, DESKTOP: SPACE.PX_60 }}
      px={SPACE.PX_15}
      {...(shouldApplyThingSchema
        ? {
            itemScope: true,
            itemType: SCHEMA_TYPES.THING,
          }
        : {})}
    >
      <Flex
        width={{ MOBILE: '100%', DESKTOP: PAGE_MAX_WIDTH.LAYOUT }}
        maxWidth="100%"
        flexDirection="column"
      >
        {isFeaturedType && headline && (
          <WidgetTitle
            mx="auto"
            mb={{ MOBILE: SPACE.PX_16, DESKTOP: SPACE.PX_18 }}
          >
            {headline}
          </WidgetTitle>
        )}
        {!isFeaturedType && (
          <MultiCollectionWidgetFilter
            isBrandsFilterShown={isBrandsFilterShown}
            // collectionFilterOptions={collectionFilterOptions}
            brandFilterOptions={brandFilterOptions}
            values={values}
            categoriesFilterOptions={categoriesFilterOptions}
            maxPrice={maxPrice.total}
            isSortEnabled={isSortEnabled}
            defaultSortState={defaultSortState}
            variant={variant}
            onChange={({ updatedValues, ...rest }) => {
              const { sort: newSorter, ...updatedFilterValues } = updatedValues
              const newFilter = {
                ...filter,
                ...updatedFilterValues,
              }
              composedFiltersStateOnChange({ updatedValues, ...rest })
              handleSetWidgetInputsChange({
                pagination: {
                  page: isInitialOnChange.current ? page : 1,
                  limit: pageSize,
                },
                sorter: newSorter ?? sorter,
                filter: newFilter,
              })
              isInitialOnChange.current = false
            }}
            {...composedFilterStateManagementRest}
          />
        )}
        {isLoading || productsList.length > 0 ? (
          <>
            <Grid
              width="100%"
              mb={SPACE.PX_40}
              gridGap={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_32 }}
              gridAutoRows={{ MOBILE: 'min-content', TABLET: '1fr' }}
              gridTemplateColumns={
                isFeaturedType
                  ? {
                      MOBILE: `repeat(auto-fit, calc(50% - (${SPACE.PX_32} * (1/2))))`,
                      TABLET: `repeat(auto-fit, calc((100% / 3) - (${SPACE.PX_32} * (2/3))))`,
                      DESKTOP: `repeat(auto-fit, calc(25% - (${SPACE.PX_32} * (3/4))))`,
                    }
                  : {
                      MOBILE: '1fr 1fr',
                      TABLET: 'repeat(3, 1fr)',
                      DESKTOP: 'repeat(4, 1fr)',
                    }
              }
              justifyContent={isFeaturedType ? 'center' : 'start'}
              {...(shouldApplyThingSchema
                ? {
                    itemScope: true,
                    itemType: SCHEMA_TYPES.ITEM_LIST,
                  }
                : {})}
            >
              {(isLoading && !productsList.length
                ? generateDummyProducts(pageSize)
                : productsList
              ).map(
                (
                  {
                    sku,
                    title: productTitle,
                    slug,
                    minPrice: minVariantPrice,
                    maxPrice: maxVariantPrice,
                    tradeMinPrice: minVariantTradePrice,
                    tradeMaxPrice: maxVariantTradePrice,
                    attachment,
                    images,
                    variants,
                    collections,
                    availability,
                    brand,
                    id,
                  },
                  index,
                  { length: totalProducts }
                ) => {
                  const partialCollections = intersectionBy<
                    Pick<Collection, 'id' | 'expert' | 'title'>
                  >(
                    collections?.data,
                    cmsMultiCollectionWidgetItems?.map(
                      ({ collection }) => collection
                    ),
                    'id'
                  )
                  const collectionExpertSlug = partialCollections?.find(
                    (collection) => !!collection.expert
                  )?.expert?.slug

                  const leadImage = variants?.find(
                    (v) => v.leadImage
                  )?.leadImage

                  const productImage = leadImage ?? attachment ?? images?.[0]

                  return (
                    <ListingWidgetLogic
                      key={`${get(variants, [0, 'sku'], sku)}-${index}`}
                      isLoading={isLoading}
                      position={index}
                      totalProducts={totalProducts}
                      cmsListingWidgets={cmsListingWidgets}
                      isFilterApplied={isFilterApplied}
                    >
                      <CollectionWidgetCard
                        index={index}
                        isLoading={isLoading}
                        title={productTitle}
                        brand={brand?.id}
                        productImageUrl={productImage?.url}
                        productImageAlt={productImage?.alt}
                        variants={variants}
                        minPrice={minVariantPrice}
                        maxPrice={maxVariantPrice}
                        tradeMinPrice={minVariantTradePrice}
                        tradeMaxPrice={maxVariantTradePrice}
                        displayOptions={!useBreakoutVariants}
                        collectionExpertSlug={collectionExpertSlug}
                        url={getProductRoute({
                          collections: partialCollections,
                          slug,
                          variantSlug: variants?.[0]?.slug,
                        })}
                        isReportingListItemGA
                        availability={availability}
                        productId={id}
                        blackFridayPromoCode={blackFridayPromoCode?.data}
                      />
                    </ListingWidgetLogic>
                  )
                }
              )}
            </Grid>
            <CollectionWidgetFooter
              isLoading={isLoading || isRouteChanging}
              isMoreButtonVisible={isMoreButtonVisible}
              isProductCountVisible={
                totalProductCount > pageSize && !isFeaturedType
              }
              currentProductCount={productsList.length}
              totalProductCount={totalProductCount}
              isShopAllProductsButtonEnabled={
                isShopAllProductsButtonEnabled && isFeaturedType
              }
              showroomRoute={
                getShowroomRouteFromSlug(showroomPageSlug) || showroomPageSlug
              }
              expertSlug={expertSlug}
              onMoreButtonClick={async () => {
                await fetchNextPage()
                silentPushCmsPageUrl({
                  router,
                  cmsPageSlug,
                  params: {
                    ...parseSearchParamsComposedFilters(searchParams),
                    page: page ? page + 1 : 2,
                  },
                  maxPrice: maxPrice.total,
                })
                handleSetWidgetInputsChange({
                  pagination: {
                    page: page ? page + 1 : 2,
                    limit: pageSize,
                  },
                  sorter,
                  filter,
                })
              }}
            />
          </>
        ) : (
          <NoProducts isLoading={isLoading} />
        )}
      </Flex>
    </Flex>
  )
}
