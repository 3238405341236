import PropTypes from 'prop-types'
import React from 'react'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import { ROUTE } from 'constants/routes'

export const GalleryItemWrapper = ({ children, slug }) => {
  if (slug) {
    return (
      <Link
        display="flex"
        flexDirection="column"
        width="100%"
        href={ROUTE.EXPERT_PROFILE({ slug })}
        aria-label="expert profile"
      >
        {children}
      </Link>
    )
  }
  return (
    <Flex flexDirection="column" width="100%">
      {children}
    </Flex>
  )
}

GalleryItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.string,
}
