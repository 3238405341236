import React from 'react'
import styled from 'styled-components'
import { grid, type GridProps } from 'styled-system'
import { Flex } from 'components/Layout'
import { LISTING_WIDGET_TYPES, SCHEMA_TYPES } from 'constants/common'
import { BORDER_WIDTH, BOX_SHADOW, COLOR_INTENT, RADIUS } from 'Theme'
import {
  type CmsConsultationListingWidget,
  type CmsInfoListingWidget,
  type CmsListingWidget,
} from 'types/graphql-generated'
import { ConsultationListingWidget } from './widgets/ConsultationListingWidget'
import { InfoListingWidget } from './widgets/InfoListingWidget'

const Wrapper = styled(Flex)`
  ${grid}
  overflow: hidden;
  border: ${BORDER_WIDTH.PX_1} solid
    ${COLOR_INTENT.COLLECTION_WIDGET_CARD.BORDER};
  border-radius: ${RADIUS.PX_10};
  box-shadow: ${BOX_SHADOW.ELEVATION_COLLECTION_CARD_1},
    ${BOX_SHADOW.ELEVATION_5};
`

interface ListingWidgetProps extends GridProps {
  isLoading: boolean
  cmsListingWidget: CmsListingWidget
  position: number
}

export const ListingWidget: React.FC<React.PWC<ListingWidgetProps>> = ({
  isLoading,
  cmsListingWidget: { type, content },
  position,
  ...rest
}) => (
  <Wrapper
    flexDirection="column"
    alignItems="center"
    height="100%"
    itemScope
    itemType={SCHEMA_TYPES.LIST_ITEM}
    {...rest}
  >
    {type === LISTING_WIDGET_TYPES.INFO && (
      <InfoListingWidget
        isLoading={isLoading}
        content={content as CmsInfoListingWidget}
        position={position}
      />
    )}
    {type === LISTING_WIDGET_TYPES.CONSULTATION && (
      <ConsultationListingWidget
        isLoading={isLoading}
        content={content as CmsConsultationListingWidget}
        position={position}
      />
    )}
    {/* TODO Other listing widget types to be implemented */}
  </Wrapper>
)
