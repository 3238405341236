import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image } from 'components/Image'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import { H6 } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'

const IMAGE_BASE_WIDTH = 300

export const DEFAULT_CARD_WIDTH = rem(178)

const StyledImage = styled(Image)`
  border-radius: 8px;
`

export const CardNavigationCard = ({
  index,
  title,
  alt,
  imageUrl,
  url,
  width = DEFAULT_CARD_WIDTH,
}) => (
  <article itemScope itemType={SCHEMA_TYPES.LIST_ITEM}>
    <Flex width={width} maxWidth={width ?? DEFAULT_CARD_WIDTH}>
      <Link
        href={url}
        aria-label={`${title} - ${index}`}
        itemProp={SCHEMA_PROPS.URL}
      >
        <Flex
          data-test-class={DATA_TEST_CLASS.SHOWROOM_PRODUCT_CARD}
          position="relative"
          flexDirection="column"
          height="100%"
          width="100%"
          flexShrink={0}
        >
          <StyledImage
            alt={alt || title}
            objectFit="cover"
            aspectRatio={ASPECT_RATIO.PRODUCTS}
            src={imageUrl}
            baseWidth={IMAGE_BASE_WIDTH}
            itemProp={SCHEMA_PROPS.IMAGE}
          />
          <H6
            mb={0}
            mt={SPACE.PX_12}
            textAlign="center"
            color={COLOR_INTENT.COLLECTION_WIDGET_CARD.TITLE}
            fontSize={FONT_SIZE.PX_15}
            fontWeight={FONT_WEIGHT.NORMAL}
            itemProp={SCHEMA_PROPS.NAME}
          >
            {title}
          </H6>
        </Flex>
      </Link>
    </Flex>
    <meta itemProp={SCHEMA_PROPS.POSITION} content={index + 1} />
  </article>
)
