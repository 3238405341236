import { get, isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { CollapsibleTreeSelect } from 'components/CollapsibleTreeSelect'
import { ComposedFilters } from 'components/ComposedFilters'
import {
  formatUpdatedPrices,
  PriceFilter,
  setPriceFilterStateOnExit,
  usePriceFilterStateManagement,
} from 'components/PriceFilter'
import { getSliderStepByMaxValue } from 'components/Slider'
import { Sort } from 'components/Sort'
import { TagFilter } from 'components/TagFilter'
import {
  COLLECTION_SORTER_OPTIONS,
  COMPOSED_FILTERS_CONFIGURATION_NAMES,
} from 'constants/common'
import { useCurrency } from 'providers/currency'
import { useGoogleAnalytics } from 'providers/googleAnalytics'
import { executePriceFilterEvent } from 'providers/googleAnalytics/utils/Filter/executePriceFilterEvent'
import { type ProductCategory } from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { AVAILABILITY_OPTIONS } from '../utils/collections'

// const COLLECTIONS_FILTER_COLUMNS_BREAKPOINT = 5

interface FilterOption {
  label?: string
  value?: number
}

interface MultiCollectionWidgetFilterProps {
  isSortEnabled?: boolean
  // collectionFilterOptions: FilterOption[]
  categoriesFilterOptions: FilterOption[] | ProductCategory[]
  brandFilterOptions: {
    label: string
    value: string
  }[]
  onClearAll(values: any): void
  onChange(values: any): void
  values?: any
  maxPrice: number
  defaultSortState: unknown
  variant?: string
  isBrandsFilterShown?: boolean
}

export const MultiCollectionWidgetFilter: React.FC<
  React.PWC<MultiCollectionWidgetFilterProps>
> = ({
  isSortEnabled,
  // collectionFilterOptions,
  onClearAll,
  onChange,
  values,
  maxPrice,
  categoriesFilterOptions,
  defaultSortState,
  brandFilterOptions,
  variant,
  isBrandsFilterShown = true,
}) => {
  const media = useMedia()
  const { currencyIsoCode } = useCurrency()
  const { executeDataToDataLayer } = useGoogleAnalytics()
  const { setPriceFrom, setPriceTo, ...priceFilterValue } =
    usePriceFilterStateManagement({
      onChange: (updatedValues) => {
        onChange({
          updatedValues: {
            [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: formatUpdatedPrices({
              ...updatedValues,
              maxPrice,
            }),
          },
          isImmediateSubmit: !media.MOBILE,
        })

        executePriceFilterEvent({
          isNotMobile: !media.MOBILE,
          updatedValues,
          previousValues: values.price,
          executeDataToDataLayer,
          maxPrice,
          currencyIsoCode,
        })
      },
      initialPriceFrom: values.price?.priceFrom ?? 0,
      initialPriceTo: values.price?.priceTo ?? maxPrice,
      debounceTimeout: 700,
    })

  const setComponentStateOnExit = useCallback(() => {
    setPriceFilterStateOnExit({
      state: priceFilterValue,
      setPriceFrom,
      setPriceTo,
      initialFrom: 0,
      initialTo: maxPrice,
    })
  }, [setPriceFrom, setPriceTo, priceFilterValue, maxPrice])

  const isCollapsibleTreeSelectCategoryFilterShown = useCallback(() => {
    if (variant === '0') {
      return false
    }

    if (categoriesFilterOptions.length === 1) {
      return (
        ((categoriesFilterOptions[0] as ProductCategory).children ?? [])
          .length > 1
      )
    }

    return categoriesFilterOptions.length > 1
  }, [categoriesFilterOptions, variant])

  return (
    <ComposedFilters
      configuration={{
        ...(isSortEnabled && {
          [COMPOSED_FILTERS_CONFIGURATION_NAMES.SORT]: {
            viewComponent: Sort,
            label: 'Sort by',
            viewComponentProps: {
              options: COLLECTION_SORTER_OPTIONS,
            },
          },
        }),
        // TODO: Uncomment when filter customization will be implemented
        // ...(collectionFilterOptions.length > 1 && {
        //   [COMPOSED_FILTERS_CONFIGURATION_NAMES.COLLECTIONS]: {
        //     viewComponent: TagFilter,
        //     label: 'Collections',
        //     isActive: (value) => !isEmpty(value),
        //     viewComponentProps: {
        //       options: collectionFilterOptions,
        //       columns:
        //         collectionFilterOptions.length >
        //         COLLECTIONS_FILTER_COLUMNS_BREAKPOINT
        //           ? 2
        //           : 1,
        //     },
        //   },
        // }),
        ...(categoriesFilterOptions.length &&
          variant === '0' && {
            [COMPOSED_FILTERS_CONFIGURATION_NAMES.CATEGORIES]: {
              viewComponent: TagFilter,
              label: 'Category',
              isActive: (value) => !isEmpty(value),
              viewComponentProps: {
                options: categoriesFilterOptions,
                isCollapsible: media.MOBILE,
              },
            },
          }),
        ...(isCollapsibleTreeSelectCategoryFilterShown() && {
          [COMPOSED_FILTERS_CONFIGURATION_NAMES.CATEGORIES]: {
            viewComponent: CollapsibleTreeSelect,
            label: 'Category',
            isActive: (value) => !isEmpty(value),
            viewComponentProps: {
              options: categoriesFilterOptions,
              isCollapsible: media.MOBILE,
            },
          },
        }),
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: {
          viewComponent: PriceFilter,
          label: 'Price',
          isActive: (price) =>
            !(
              get(price, 'priceFrom', 0) === 0 &&
              get(price, 'priceTo', maxPrice) === maxPrice
            ),
          setComponentStateOnExit,
          viewComponentProps: {
            maxPrice,
            ...priceFilterValue,
            setPriceFrom,
            setPriceTo,
            currencyIsoCode,
            isPriceUnlimited: false,
            sliderStep: getSliderStepByMaxValue(
              get(priceFilterValue, 'priceTo')
            ),
          },
        },
        [COMPOSED_FILTERS_CONFIGURATION_NAMES.AVAILABILITY]: {
          viewComponent: TagFilter,
          label: 'Availability',
          isActive: (value) => !isEmpty(value),
          viewComponentProps: {
            options: AVAILABILITY_OPTIONS,
          },
        },
        ...(isBrandsFilterShown && {
          [COMPOSED_FILTERS_CONFIGURATION_NAMES.BRANDS]: {
            viewComponent: TagFilter,
            label: 'Brand',
            isActive: (value) => !isEmpty(value),
            viewComponentProps: {
              options: brandFilterOptions,
              isCollapsible: media.MOBILE,
            },
          },
        }),
      }}
      onChange={onChange}
      values={values}
      onClearAll={() => {
        onClearAll({
          exceptions: [
            {
              key: COMPOSED_FILTERS_CONFIGURATION_NAMES.SORT,
              state: defaultSortState,
            },
          ],
        })
        setPriceFrom(0)
        setPriceTo(maxPrice)
      }}
    />
  )
}
