import React, { type ComponentType, type ReactNode } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import {
  type SpaceProps,
  type FlexboxProps,
  type LayoutProps,
} from 'styled-system'
import { ICON_SIZE, type IconProps } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { H5, Text } from 'components/Typography'
import { COLOR, SPACE } from 'Theme'

interface NoDataProps extends FlexboxProps, LayoutProps, SpaceProps {
  icon?: ComponentType<React.PWC<IconProps>>
  header?: string
  content?: string
  customHeader?: () => ReactNode
  customContent?: () => ReactNode
  button?: () => ReactNode
}

const NoData: React.FC<React.PWC<NoDataProps>> = ({
  icon: Icon,
  header,
  customHeader,
  content,
  customContent,
  button,
  ...props
}) => (
  <Flex
    data-test-id={DATA_TEST_ID.NO_DATA}
    flexDirection="column"
    alignItems="center"
    {...props}
  >
    {Icon && (
      <Box mb={SPACE.PX_20}>
        <Icon size={ICON_SIZE.PX_32} color={COLOR.GRAYSCALE_3} />
      </Box>
    )}
    {header && <H5 textAlign="center">{header}</H5>}
    {customHeader?.()}
    {content && <Text textAlign="center">{content}</Text>}
    {customContent?.()}
    {button && <Box mt={SPACE.PX_15}>{button()}</Box>}
  </Flex>
)

export default NoData
