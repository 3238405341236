export const getMaxPriceWithLimit = ({ limit: maxPrice, currentValue: max }) =>
  Math.max(Math.min(parseFloat(max), maxPrice), 0)

export const getMinPriceWithLimit = ({ limit: maxPrice, currentValue: min }) =>
  Math.min(Math.max(0, parseFloat(min)), maxPrice)

export const calculateOnMinPriceChange = ({ min, max, maxPrice }) => {
  const maxWithLimit = getMaxPriceWithLimit({
    limit: maxPrice,
    currentValue: max,
  })
  const minWithLimit = getMinPriceWithLimit({
    limit: maxPrice,
    currentValue: min,
  })
  if (min === '') {
    return { from: 0, to: maxWithLimit }
  }
  if (minWithLimit > maxWithLimit) {
    return { from: minWithLimit, to: minWithLimit }
  }
  return { from: minWithLimit, to: maxWithLimit }
}

export const calculateOnMaxPriceChange = ({ min, max, maxPrice }) => {
  const maxWithLimit = getMaxPriceWithLimit({
    limit: maxPrice,
    currentValue: max,
  })
  const minWithLimit = getMinPriceWithLimit({
    limit: maxPrice,
    currentValue: min,
  })
  if (max === '') {
    return { from: min, to: maxPrice }
  }
  if (maxWithLimit < minWithLimit) {
    return { from: maxWithLimit, to: maxWithLimit }
  }
  return { from: minWithLimit, to: maxWithLimit }
}

export const formatUpdatedPrices = ({ priceFrom, priceTo, maxPrice }) => ({
  ...(priceFrom === '' ? { priceFrom: 0 } : { priceFrom: Number(priceFrom) }),
  ...(!priceTo || priceTo === ''
    ? { priceTo: maxPrice }
    : { priceTo: Number(priceTo) }),
})

export const setPriceFilterStateOnExit = ({
  state: { priceFrom, priceTo },
  setPriceFrom,
  setPriceTo,
  initialFrom,
  initialTo,
}) => {
  if (priceFrom === '') {
    setPriceFrom(initialFrom)
  }
  if (priceTo === '') {
    setPriceTo(initialTo)
  }
}

export const formatPriceFilterStateToInput = ({
  from,
  to,
  maxPrice,
}: {
  from: number
  to: number
  maxPrice: number
}): { from?: number; to?: number } | null => {
  if (from !== 0 && to !== maxPrice) {
    return { from, to }
  }
  if (from !== 0) {
    return { from }
  }
  if (to !== maxPrice) {
    return { to }
  }
  return null
}
