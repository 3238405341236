import { get } from 'lodash'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { formatPrice } from 'shared-constants'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Input } from 'components/Input'
import { Flex } from 'components/Layout'
import {
  calculateOnMaxPriceChange,
  calculateOnMinPriceChange,
} from 'components/PriceFilter/utils'
import Slider from 'components/Slider'
import { Text } from 'components/Typography'
import { FILTER_VIEW_COMPONENT_NAMES } from 'constants/common'
import { COLOR_INTENT, FONT_SIZE, INPUT_SIZE, SPACE, Z_INDEX } from 'Theme'

const PRICE_FILTER_MIN_WIDTH = rem(360)

const CustomSeparator = styled(Flex)`
  background-color: ${COLOR_INTENT.GRAY_10};
`

const formatInputValue = ({ price, maxPrice, isPriceUnlimited }) => {
  if (price === '') {
    return ''
  }
  if (price === maxPrice && isPriceUnlimited) {
    return `${price}+`
  }
  return parseFloat(price)
}

const handlePriceIntervalEdges = ({ value, maxPrice, setPrice }) => {
  if (value < 0) {
    setPrice(0)
  } else if (value > maxPrice) {
    setPrice(maxPrice)
  } else {
    setPrice(value)
  }
}

export const PriceFilter = ({
  maxPrice,
  priceFrom,
  priceTo,
  setPriceFrom,
  setPriceTo,
  currencyIsoCode,
  isPriceUnlimited,
  sliderStep,
}) => (
  <Flex
    data-test-id={DATA_TEST_ID.PRICE_FILTER}
    flexDirection="column"
    minWidth={{
      MOBILE: '100%',
      TABLET: PRICE_FILTER_MIN_WIDTH,
    }}
  >
    <Flex flexDirection="column" p={{ MOBILE: 0, TABLET: SPACE.PX_20 }}>
      <Flex justifyContent="space-between" mb={SPACE.PX_10}>
        <Text fontSize={FONT_SIZE.PX_14} color={COLOR_INTENT.GRAY_90}>
          {formatPrice({
            price: 0,
            currency: currencyIsoCode,
          })}
        </Text>
        <Text fontSize={FONT_SIZE.PX_14} color={COLOR_INTENT.GRAY_90}>
          {`${formatPrice({
            price: maxPrice,
            currency: currencyIsoCode,
          })}${isPriceUnlimited ? '+' : ''}`}
        </Text>
      </Flex>
      <Slider
        step={sliderStep}
        minDistance={0}
        value={[priceFrom, priceTo].map(Number)}
        onChange={([from, to], thumbIndex) => {
          if (thumbIndex === 0) {
            setPriceFrom(from)
            setPriceTo((currentPriceTo) =>
              from >= priceTo ? from : currentPriceTo
            )
          } else if (thumbIndex === 1) {
            setPriceTo(to >= maxPrice ? maxPrice : to)
            setPriceFrom((currentPriceFrom) =>
              to <= priceFrom ? to : currentPriceFrom
            )
          }
        }}
        min={0}
        max={maxPrice}
        mb={SPACE.PX_20}
        zIndex={Z_INDEX.SLIDER_THUMB}
      />
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        mb={SPACE.PX_5}
      >
        <Input
          type={priceFrom === maxPrice ? 'string' : 'number'}
          value={formatInputValue({
            price: priceFrom,
            maxPrice,
            isPriceUnlimited,
          })}
          min={0}
          max={priceTo}
          label={`Min price (${currencyIsoCode})`}
          onChange={({ target }) => {
            handlePriceIntervalEdges({
              value: get(target, 'value'),
              setPrice: setPriceFrom,
              maxPrice: priceTo,
            })
          }}
          onBlur={() => {
            const { from, to } = calculateOnMinPriceChange({
              min: priceFrom,
              max: priceTo,
              maxPrice,
            })
            setPriceFrom(from)
            setPriceTo(to)
          }}
        />
        <Flex height={INPUT_SIZE.M} alignItems="center">
          <CustomSeparator
            mx={SPACE.PX_15}
            width="10px"
            height="1px"
            flexShrink={0}
          />
        </Flex>
        <Input
          type={priceTo === maxPrice ? 'string' : 'number'}
          min={priceFrom}
          max={maxPrice}
          label={`Max price (${currencyIsoCode})`}
          value={formatInputValue({
            price: priceTo,
            maxPrice,
            isPriceUnlimited,
          })}
          onBlur={() => {
            const { from, to } = calculateOnMaxPriceChange({
              min: priceFrom,
              max: priceTo,
              maxPrice,
            })
            setPriceFrom(from)
            setPriceTo(to)
          }}
          onChange={({ target }) => {
            handlePriceIntervalEdges({
              value: get(target, 'value'),
              setPrice: setPriceTo,
              maxPrice,
            })
          }}
        />
      </Flex>
    </Flex>
  </Flex>
)

PriceFilter.identification = FILTER_VIEW_COMPONENT_NAMES.PRICE

PriceFilter.defaultProps = {
  isPriceUnlimited: true,
  sliderStep: 10,
}

PriceFilter.propTypes = {
  maxPrice: PropTypes.number.isRequired,
  priceFrom: PropTypes.number.isRequired,
  priceTo: PropTypes.number.isRequired,
  setPriceFrom: PropTypes.func.isRequired,
  setPriceTo: PropTypes.func.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  isPriceUnlimited: PropTypes.bool,
  sliderStep: PropTypes.number,
}
