import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import { Toast } from 'components/Toast'
import { useUserAuth } from 'providers/userAuth'
import { COLOR_INTENT } from 'Theme'

const FAVORITE_MARKER_TOUCH_AREA = '40px'

const FavoriteWrapper = styled(Flex)`
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
`

interface FavoriteMarkerProps {
  color?: string
  isLoading: boolean
  onNotAuthorizedClick: () => void
  onAddToFavorite: () => void
  onRemoveFromFavorite: () => void
  isFavorite: boolean
  size?: string
}

export const FavoriteMarker: React.FC<React.PWC<FavoriteMarkerProps>> = ({
  color = COLOR_INTENT.FAVORITE_MARKER_DEFAULT_COLOR,
  isLoading,
  onNotAuthorizedClick,
  onAddToFavorite,
  onRemoveFromFavorite,
  isFavorite,
  size = ICON_SIZE.PX_20,
  ...rest
}) => {
  const { isClientSignedIn, user } = useUserAuth() ?? {}

  return (
    <FavoriteWrapper
      width={FAVORITE_MARKER_TOUCH_AREA}
      height={FAVORITE_MARKER_TOUCH_AREA}
      alignItems="center"
      justifyContent="center"
      data-test-class={DATA_TEST_CLASS.FAVORITE_MARKER}
      isLoading={isLoading}
      onClick={(event) => {
        event.preventDefault()
        if (isLoading) {
          return
        }
        if (!user) {
          onNotAuthorizedClick()
          return
        }
        if (isClientSignedIn) {
          if (isFavorite) {
            onRemoveFromFavorite()
          } else {
            onAddToFavorite()
          }
        } else {
          Toast.info('Only clients are able to save an Expert')
        }
      }}
      {...rest}
    >
      {isFavorite ? (
        <Flex data-test-class={DATA_TEST_CLASS.FAVORITE_MARKER_REMOVE}>
          <Icon.FilledBookmark size={size} color={color} />
        </Flex>
      ) : (
        <Flex data-test-class={DATA_TEST_CLASS.FAVORITE_MARKER_ADD}>
          <Icon.Bookmark size={size} color={color} />
        </Flex>
      )}
    </FavoriteWrapper>
  )
}
