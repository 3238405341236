import { gql } from '@apollo/client'
import { useFetchNextPageQuery } from 'hooks'

const FAVORITE_EXPERTS_LIMIT = 16

export const FAVORITE_EXPERTS_QUERY = gql`
  query clientMe($id: Int!, $favoriteExpertsInput: FavoriteExpertsInput) {
    clientMe(id: $id) {
      id
      favoriteExperts(input: $favoriteExpertsInput) {
        data {
          slug
          id
          profileImageAttachment {
            url
            alt
            id
          }
          profileName
          jobTitle
          primaryLocation
          city
          countryIsoCode
          categories {
            category {
              id
              title
            }
          }
          subCategories {
            category {
              id
              title
            }
          }
        }
        paginationInfo {
          totalCount
          nextPage
        }
      }
    }
  }
`

export const useFavoriteExperts = ({ userId }) => {
  const { data, loading, fetchNextPage, refetch } = useFetchNextPageQuery(
    FAVORITE_EXPERTS_QUERY,
    {
      fetchPolicy: 'cache-first',
      pageSize: FAVORITE_EXPERTS_LIMIT,
      variablesFactory: (pagination) => ({
        id: userId,
        favoriteExpertsInput: {
          pagination,
        },
      }),
    }
  )

  const {
    favoriteExperts: {
      data: favoriteExpertsData = [],
      paginationInfo: { nextPage = null, totalCount = 0 } = {},
    } = {},
  } = data?.clientMe ?? {}

  return {
    refetch,
    favoriteExperts: favoriteExpertsData,
    nextPage,
    totalCount,
    data,
    isLoading: loading,
    fetchNextPage,
  }
}
