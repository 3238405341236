import React, { useEffect, useRef } from 'react'
import { WIDGET_NAMES } from '@admin/routes/Cms/components/Widgets/constants'
import { Box, Container } from 'components/Layout'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { SPACE } from 'Theme'
import type {
  CmsSectionWidget,
  CmsWidgetItemHeadline,
} from 'types/graphql-generated'
import {
  ItemButton,
  ItemHeadline,
  ItemThreeColumnCard,
  ItemTwoColumnCard,
} from '../WidgetItems'

export const SectionWidget: React.FC<React.PWC<CmsSectionWidget>> = ({
  widgetItems,
}) => {
  const itemList = useRef<HTMLDivElement | null>(null)

  const mainWidgetHeadline = widgetItems.find(
    (item) => item.content.__typename === 'CMSWidgetItemHeadline'
  )
  const hasCards = widgetItems.some(
    (item) =>
      item.content.__typename === 'CMSWidgetItemTwoColumnCard' ||
      item.content.__typename === 'CMSWidgetItemThreeColumnCard'
  )

  useEffect(() => {
    if (hasCards && itemList) {
      const listItems = itemList.current.querySelectorAll(
        `[itemType="${SCHEMA_TYPES.LIST_ITEM}"]`
      )

      listItems.forEach((item, index) => {
        const positionMeta = document.createElement('meta')
        positionMeta.setAttribute('itemProp', SCHEMA_PROPS.POSITION)
        positionMeta.setAttribute('content', String(index + 1))

        item.appendChild(positionMeta)
      })
    }
  }, [hasCards, itemList])

  return (
    <section itemScope itemType={SCHEMA_TYPES.THING}>
      <meta
        itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
        content={WIDGET_NAMES.SECTION.name}
      />
      {hasCards && mainWidgetHeadline && (
        <meta
          itemProp={SCHEMA_PROPS.NAME}
          content={
            (mainWidgetHeadline.content as CmsWidgetItemHeadline).headline
          }
        />
      )}
      <Container>
        <Box
          ref={itemList}
          mt={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
          mb={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_32 }}
          {...(hasCards && {
            itemScope: true,
            itemType: SCHEMA_TYPES.ITEM_LIST,
          })}
        >
          {widgetItems.map(({ id: widgetItemId, content }) => {
            if (content.__typename === 'CMSWidgetItemButton') {
              return <ItemButton key={widgetItemId} {...content} />
            }
            if (content.__typename === 'CMSWidgetItemHeadline') {
              return <ItemHeadline key={widgetItemId} {...content} />
            }
            if (content.__typename === 'CMSWidgetItemTwoColumnCard') {
              return <ItemTwoColumnCard key={widgetItemId} {...content} />
            }
            if (content.__typename === 'CMSWidgetItemThreeColumnCard') {
              return <ItemThreeColumnCard key={widgetItemId} {...content} />
            }
            return null
          })}
        </Box>
      </Container>
    </section>
  )
}
