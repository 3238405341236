import React from 'react'
import { WIDGET_NAMES } from '@admin/routes/Cms/components/Widgets/constants'
import { Box } from 'components/Layout'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { type CmsHeroCarouselWidget } from 'types/graphql-generated'
import { HeroCarousel } from './HeroCarousel'
import { HeroCarouselSlide } from './HeroCarouselSlide'

type HeroCarouselWidgetProps = Pick<
  CmsHeroCarouselWidget,
  'isAutoRotationEnabled' | 'cmsHeroCarouselWidgetItems'
>

export const HeroCarouselWidget: React.FC<
  React.PWC<HeroCarouselWidgetProps>
> = ({ isAutoRotationEnabled, cmsHeroCarouselWidgetItems }) => (
  <Box itemScope itemType={SCHEMA_TYPES.THING}>
    <meta
      itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
      content={WIDGET_NAMES.HERO_CAROUSEL.name}
    />
    {cmsHeroCarouselWidgetItems.length === 1 ? (
      <Box height="100%" itemScope itemType={SCHEMA_TYPES.ITEM_LIST}>
        <HeroCarouselSlide {...cmsHeroCarouselWidgetItems[0]} position={1} />
      </Box>
    ) : (
      <HeroCarousel
        isAutoRotationEnabled={isAutoRotationEnabled}
        items={cmsHeroCarouselWidgetItems}
      />
    )}
  </Box>
)
