import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_ID, DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { SHARP_BUTTON_INTENT, SharpButton } from 'components/Button'
import { ExpertsList } from 'components/ExpertsList'
import { Flex } from 'components/Layout'
import { SharpStyledLink } from 'components/Link'
import { Loader, LOADER_SIZE } from 'components/Loader'
import { ROUTE } from 'constants/routes'
import { withGA4Provider } from 'hoc/withGoogleAnalytics'
import { GA4_ITEM_LIST_NAME } from 'providers/googleAnalytics'
import {
  useSharedOptions,
  setInputToCorrectPosition,
} from 'providers/sharedOptions'
import { SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { useFetchMoreByWidgetId } from '../hooks'
import { WidgetTitle } from '../WidgetTitle'

const ExpertsWidgetContent = ({
  title,
  cmsPageSlug,
  widgetId,
  isButtonHidden,
  mobilePageSize,
  desktopPageSize,
}) => {
  const [widgetInputs, setWidgetInputs] = useSharedOptions()
  const media = useMedia()

  const pageSize = media.MOBILE ? mobilePageSize : desktopPageSize

  const page = get(widgetInputs, [
    'cmsInputs',
    cmsPageSlug,
    widgetId,
    'cmsExpertsInput',
    'input',
    'page',
  ])

  const {
    data,
    fetchNextPage,
    loading: isLoadingMore,
  } = useFetchMoreByWidgetId({
    pageSize,
    currentPage: page,
    variablesFactory: ({ page: newPage, limit }) => ({
      input: {
        widgetId,
        cmsExpertsInput: {
          input: {
            page: newPage,
            limit,
          },
        },
      },
    }),
  })

  const expertsList = get(
    data,
    ['cmsWidgetById', 'content', 'cmsExpertsWidgetItems', 'data'],
    []
  )
  const { nextPage, totalCount } = get(
    data,
    ['cmsWidgetById', 'content', 'cmsExpertsWidgetItems', 'paginationInfo'],
    {}
  )

  const isFindMoreExpertsButton = totalCount === expertsList.length || !nextPage
  const experts = expertsList.map(({ expert }) => expert)
  return (
    <Flex
      mt={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
      px={{ MOBILE: SPACE.PX_30, TABLET: 0 }}
    >
      {expertsList.length > 0 && (
        <Flex
          flexDirection="column"
          width="100%"
          mb={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_48 }}
          alignItems="center"
        >
          {title && (
            <WidgetTitle
              m="0"
              mb={{ MOBILE: SPACE.PX_30, TABLET: SPACE.PX_40 }}
              textAlign="center"
              px={SPACE.PX_16}
              id="featuredExperts"
              data-test-class={DATA_TEST_CLASS.EXPERTS_WIDGET}
            >
              {title}
            </WidgetTitle>
          )}
          <ExpertsList
            route={ROUTE.FRONT_PAGE()}
            experts={experts}
            isWithLoading={false}
          >
            {isLoadingMore && (
              <Flex justifyContent="center">
                <Loader
                  size={LOADER_SIZE.S}
                  data-test-class={DATA_TEST_CLASS.LOADING_MORE_LOADER}
                />
              </Flex>
            )}
            {!isFindMoreExpertsButton && !isLoadingMore && (
              <Flex m="auto">
                <SharpButton
                  isLoading={isLoadingMore}
                  onClick={async () => {
                    await fetchNextPage()
                    setWidgetInputs((oldWidgetsInput) =>
                      setInputToCorrectPosition({
                        oldWidgetsInput,
                        cmsPageSlug,
                        widgetId,
                        input: {
                          cmsExpertsInput: {
                            input: {
                              page: page ? page + 1 : 2,
                              limit: pageSize,
                            },
                          },
                        },
                      })
                    )
                  }}
                  intent={SHARP_BUTTON_INTENT.PRIMARY}
                  data-test-id={
                    DATA_TEST_ID.FEATURED_EXPERTS_WIDGET_SEE_MORE_BTN
                  }
                >
                  See more
                </SharpButton>
              </Flex>
            )}
            {isFindMoreExpertsButton && !isButtonHidden && (
              <Flex m="auto">
                <SharpStyledLink
                  href={ROUTE.EXPERTS()}
                  intent={SHARP_BUTTON_INTENT.PRIMARY}
                  data-test-id={
                    DATA_TEST_ID.FEATURED_EXPERTS_WIDGET_FIND_EXPERT_BTN
                  }
                >
                  Find an Expert
                </SharpStyledLink>
              </Flex>
            )}
          </ExpertsList>
        </Flex>
      )}
    </Flex>
  )
}

ExpertsWidgetContent.propTypes = {
  title: PropTypes.string.isRequired,
  cmsPageSlug: PropTypes.string.isRequired,
  widgetId: PropTypes.number.isRequired,
  isButtonHidden: PropTypes.bool.isRequired,
  mobilePageSize: PropTypes.number.isRequired,
  desktopPageSize: PropTypes.number.isRequired,
}

export const ExpertsWidget = withGA4Provider({
  item_list_name: GA4_ITEM_LIST_NAME.FEATURED_EXPERTS,
})(ExpertsWidgetContent)
