import React from 'react'
import { Grid } from 'components/Layout'
import { SPACE } from 'Theme'
import { type Attachment } from 'types/graphql-generated'
import { ColumnCard } from './ColumnCard'

interface ItemTwoColumnCardProps {
  firstAttachment: Attachment
  secondAttachment: Attachment
  firstTitle: string
  firstLink?: string
  secondTitle: string
  secondLink?: string
}

export const ItemTwoColumnCard: React.FC<React.PWC<ItemTwoColumnCardProps>> = ({
  firstAttachment,
  secondAttachment,
  firstTitle,
  firstLink,
  secondTitle,
  secondLink,
}) => (
  <Grid
    pb={{ MOBILE: 0, TABLET: SPACE.PX_16 }}
    gridTemplateColumns={{ MOBILE: '1fr', TABLET: '1fr 1fr' }}
    gridColumnGap={SPACE.PX_16}
  >
    <ColumnCard title={firstTitle} link={firstLink} image={firstAttachment} />
    <ColumnCard
      title={secondTitle}
      link={secondLink}
      image={secondAttachment}
    />
  </Grid>
)
