import { groupBy } from 'lodash'
import React, { useMemo } from 'react'
import { Box, Container, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, SPACE } from 'Theme'
import { AlphabeticalItem } from './AlphabeticalItem'
import {
  type AlphabeticalItemType,
  type FormattedAlphabeticalItemType,
} from './types'

type AlphabeticalIndexProps = {
  data: AlphabeticalItemType[]
  title?: string
}

export const formatAlphabeticalData = (
  data: AlphabeticalItemType[]
): FormattedAlphabeticalItemType => {
  const { Others, ...rest } = groupBy(data, (item) => {
    const firstLetter = item.title.charAt(0).toUpperCase()
    return /^[A-Z]$/.test(firstLetter) ? firstLetter : 'Others'
  })
  return Others ? { ...rest, Others } : rest
}

export const AlphabeticalIndex = ({
  data = [],
  title,
}: AlphabeticalIndexProps) => {
  const formattedData = useMemo(() => formatAlphabeticalData(data), [data])
  return (
    <Box py={SPACE.PX_80}>
      <Container>
        {title && (
          <Box mb={SPACE.PX_48}>
            <Text
              color={COLOR_INTENT.WIDGETS.ALPHABETICAL_INDEX.TITLE}
              fontSize={FONT_SIZE.PX_36}
              fontFamily={FONT_STACK.SERIF}
            >
              {title}
            </Text>
          </Box>
        )}
        <Grid
          gridTemplateColumns={{
            MOBILE: '1fr 1fr',
            TABLET: '1fr 1fr 1fr',
            DESKTOP: '1fr 1fr 1fr 1fr',
          }}
        >
          {Object.entries(formattedData).map(([key, value]) => (
            <AlphabeticalItem title={key} items={value} key={key} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
