import { rem } from 'polished'
import React from 'react'
import { BUTTON_VARIANT } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { Text } from 'components/Typography'
import { SCHEMA_PROPS, SCHEMA_TYPES, WIDGET_PLACEMENT } from 'constants/common'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, FONT_WEIGHT, SPACE } from 'Theme'
import { type CmsHeroCarouselWidgetItem } from 'types/graphql-generated'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

export const HERO_CAROUSEL_HEIGHT = { MOBILE: rem(312), TABLET: rem(572) }

const HERO_IMAGE_NAME = 'Hero carousel image'

export const HeroCarouselSlide: React.FC<
  React.PWC<CmsHeroCarouselWidgetItem & { position?: number }>
> = ({
  attachment,
  buttonLink,
  buttonText,
  hasButton,
  headline,
  preTitle,
  subheadline,
  position,
}) => {
  const hasTextContent = preTitle || headline || subheadline || hasButton

  return (
    // padding bottom is set to PX 100 to prevent CTA button intersect with navigation
    <Flex
      position="relative"
      minHeight={HERO_CAROUSEL_HEIGHT}
      height="100%"
      itemScope
      itemType={SCHEMA_TYPES.LIST_ITEM}
      alignItems="center"
      justifyContent="center"
    >
      <Image
        alt={attachment.alt || HERO_IMAGE_NAME}
        src={attachment.url}
        layout={IMAGE_LAYOUT.FILL}
        objectFit="cover"
        itemProp={SCHEMA_PROPS.IMAGE}
      />
      {hasTextContent && (
        <Flex
          zIndex={1}
          position="relative"
          height="100%"
          alignItems={{ MOBILE: 'flex-start', DESKTOP: 'center' }}
          justifyContent="center"
          pt={{ MOBILE: SPACE.PX_24, DESKTOP: 0 }}
        >
          <Flex
            maxWidth={rem(752)}
            alignItems="center"
            flexDirection="column"
            height="fit-content"
          >
            {preTitle && (
              <Text
                fontFamily={FONT_STACK.SANS}
                color={COLOR_INTENT.WIDGETS.HERO_CAROUSEL.TEXT}
                textTransform="uppercase"
                fontSize={{ MOBILE: FONT_SIZE.PX_12, TABLET: FONT_SIZE.PX_14 }}
                mb={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_4 }}
                textAlign="center"
              >
                {preTitle}
              </Text>
            )}
            {headline && (
              <WidgetTitle
                color={COLOR_INTENT.WIDGETS.HERO_CAROUSEL.TEXT}
                placement={WIDGET_PLACEMENT.HERO}
                textAlign="center"
                itemProp={SCHEMA_PROPS.NAME}
              >
                {headline}
              </WidgetTitle>
            )}
            {subheadline && (
              <WidgetSubtitle
                color={COLOR_INTENT.WIDGETS.HERO_CAROUSEL.TEXT}
                placement={WIDGET_PLACEMENT.HERO}
                fontFamily={FONT_STACK.SANS}
                fontSize={{ MOBILE: FONT_SIZE.PX_14, TABLET: FONT_SIZE.PX_16 }}
                textAlign="center"
              >
                {subheadline}
              </WidgetSubtitle>
            )}
            {hasButton && (
              <StyledLink
                href={buttonLink}
                variant={BUTTON_VARIANT.OUTLINE}
                mt={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_24 }}
                fontFamily={FONT_STACK.SANS}
                fontWeight={FONT_WEIGHT.MEDIUM}
                aria-label={buttonText}
                itemProp={SCHEMA_PROPS.URL}
              >
                {buttonText}
              </StyledLink>
            )}
          </Flex>
        </Flex>
      )}
      {position && (
        <meta itemProp={SCHEMA_PROPS.POSITION} content={String(position)} />
      )}
    </Flex>
  )
}
