import { castArray, isArray } from 'lodash'
import { type FiltersStateValue } from 'components/ComposedFilters'
import { type SilentPushUrl, silentPushUrl } from 'utils/pushUrl'
import { FILTER_MAX_PRICE } from './hooks'

const ascendingSort = (a: number, b: number): number => a - b

interface SilentPushExpertsUrlInput extends Partial<SilentPushUrl> {
  params: {
    priceFrom?: number
    priceTo?: number
    availabilityFrom?: FiltersStateValue
    availabilityTo?: FiltersStateValue
    page?: number
    sessionLength?: FiltersStateValue
    tags?: FiltersStateValue
    regions?: FiltersStateValue
  }
  route: SilentPushUrl['route']
  router: SilentPushUrl['router']
}

export const SESSION_LENGTH_FILTER_OPTIONS = [
  {
    id: 1,
    label: '25 minutes only',
    value: '30',
  },
  {
    id: 2,
    label: '55 minutes only',
    value: '60',
  },
  {
    id: 3,
    label: '85 minutes only',
    value: '90',
  },
  {
    id: 4,
    label: '115 minutes only',
    value: '120',
  },
] as const

export const getTagFilterValueFromParams = (
  tagsParam?: string | string[]
): string[] => castArray(tagsParam)

export const buildParams = ({
  page = 1,
  priceFrom,
  priceTo,
  sessionLength,
  availabilityFrom,
  availabilityTo,
  tags,
  regions,
  ...rest
}) => ({
  ...(page && page !== 1 && { page }),
  availabilityFrom,
  availabilityTo,
  ...(priceFrom && priceFrom > 0 && { priceFrom: parseInt(priceFrom) }),
  ...(priceTo !== undefined &&
    priceTo < FILTER_MAX_PRICE && { priceTo: parseInt(priceTo) }),
  ...(sessionLength &&
    sessionLength.length && {
      sessionLength: isArray(sessionLength)
        ? sessionLength.sort(ascendingSort)
        : sessionLength,
    }),
  ...(tags && tags.length && { tags }),
  ...(regions && regions.length && { regions }),
  ...rest,
})

export const silentPushExpertsUrl = ({
  params = {},
  router,
  ...rest
}: SilentPushExpertsUrlInput): void =>
  silentPushUrl({
    params: buildParams(params as any),
    router,
    ...rest,
  })
