import React from 'react'
import { Text } from 'components/Typography'
import { FONT_SIZE, FONT_STACK, SPACE } from 'Theme'

export const SingleImageSubtitle: React.FC<React.PWC> = ({ children }) => (
  <Text
    fontFamily={FONT_STACK.SERIF}
    fontSize={FONT_SIZE.PX_16}
    textAlign={{ MOBILE: 'center', TABLET: 'start' }}
    mb={SPACE.PX_20}
  >
    {children}
  </Text>
)
