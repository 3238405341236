import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex, Grid } from 'components/Layout'
import { MarkdownRenderer } from 'components/MarkdownRenderer'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SPACE, FONT_SIZE } from 'Theme'
import { useMedia } from 'useMedia'

const FIRST_IMAGE_NAME = 'First image of an double image widget'
const SECOND_IMAGE_NAME = 'Second image of an double image widget'
const DOUBLE_IMAGE_REQUEST_SIZES =
  '(max-width: 768) 100vw, (max-width: 1024) 50vw, (max-width: 1920px) 40vw, 25vw'

export const DoubleImageWidget = ({
  firstAttachment,
  secondAttachment,
  firstCaption,
  secondCaption,
}) => {
  const media = useMedia()
  const bothCaptions = Boolean(firstCaption && secondCaption)
  const isDisplayUnderWidgetCaption = !media.MOBILE && !bothCaptions

  return (
    <Flex
      flexDirection="column"
      pt={SPACE.PX_20}
      pb={SPACE.PX_40}
      data-test-class={DATA_TEST_CLASS.DOUBLE_IMAGE_WIDGET}
    >
      <Grid
        gridGap={SPACE.PX_15}
        gridTemplateColumns={{
          MOBILE: '1fr',
          TABLET: '1fr 1fr',
        }}
      >
        <Grid gridGap={SPACE.PX_10} order={1}>
          <Image
            layout={IMAGE_LAYOUT.RESPONSIVE}
            aspectRatio={ASPECT_RATIO.DOUBLE_IMAGE}
            src={firstAttachment.url}
            alt={firstAttachment.alt || FIRST_IMAGE_NAME}
            sizes={DOUBLE_IMAGE_REQUEST_SIZES}
          />
        </Grid>
        <Grid gridGap={SPACE.PX_10} order={{ MOBILE: 3, TABLET: 2 }}>
          <Image
            layout={IMAGE_LAYOUT.RESPONSIVE}
            aspectRatio={ASPECT_RATIO.DOUBLE_IMAGE}
            src={secondAttachment.url}
            alt={secondAttachment.alt || SECOND_IMAGE_NAME}
            sizes={DOUBLE_IMAGE_REQUEST_SIZES}
          />
        </Grid>
        <Grid order={{ MOBILE: 2, TABLET: 3 }}>
          {!isDisplayUnderWidgetCaption && firstCaption && (
            <MarkdownRenderer
              markdownSource={firstCaption}
              fontSize={FONT_SIZE.PX_12}
            />
          )}
        </Grid>
        <Grid order={4}>
          {!isDisplayUnderWidgetCaption && secondCaption && (
            <MarkdownRenderer
              markdownSource={secondCaption}
              fontSize={FONT_SIZE.PX_12}
            />
          )}
        </Grid>
      </Grid>
      {isDisplayUnderWidgetCaption && (
        <MarkdownRenderer
          markdownSource={firstCaption || secondCaption}
          fontSize={FONT_SIZE.PX_12}
          mt={SPACE.PX_10}
        />
      )}
    </Flex>
  )
}

DoubleImageWidget.propTypes = {
  firstAttachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  secondAttachment: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  firstCaption: PropTypes.string,
  secondCaption: PropTypes.string,
}
