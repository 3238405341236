import { ASPECT_RATIO, ASPECT_RATIO_SIZE } from '../constants'

export const calculateOptimizedCrop = ({ crop = {}, image }) => {
  if (
    crop.height == null ||
    crop.width == null ||
    crop.x == null ||
    crop.y == null
  ) {
    return {}
  }
  const ratioSize = Object.values(ASPECT_RATIO_SIZE).find(
    (aspectRatioSize) => aspectRatioSize.ratio === crop.aspect
  )
  const width = Math.floor((crop.width / 100) * image.naturalWidth)

  // eslint-disable-next-line eqeqeq
  if (crop.aspect == ASPECT_RATIO.FREE) {
    const height = Math.floor((crop.height / 100) * image.naturalHeight)
    return {
      x: Math.floor((crop.x / 100) * image.naturalWidth),
      y: Math.floor((crop.y / 100) * image.naturalHeight),
      width,
      height,
    }
  }

  const normalizedWidth = Math.floor(width / ratioSize.width) * ratioSize.width

  return {
    x: Math.floor((crop.x / 100) * image.naturalWidth),
    y: Math.floor((crop.y / 100) * image.naturalHeight),
    width: normalizedWidth,
    height: (normalizedWidth / ratioSize.width) * ratioSize.height,
  }
}
