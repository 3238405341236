import React from 'react'
import styled from 'styled-components'
import { WIDGET_NAMES } from '@admin/routes/Cms/components/Widgets/constants'
import { Divider } from 'components/Divider'
import Icon from 'components/Icon'
import { Image } from 'components/Image'
import { Box, Container, Flex } from 'components/Layout'
import 'swiper/css/bundle'
import Link, { StyledLink } from 'components/Link'
import { H6 } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage'
import { SCHEMA_PROPS, SCHEMA_TYPES, WIDGET_PLACEMENT } from 'constants/common'
import { COLOR_INTENT, FONT_WEIGHT, RADIUS, SPACE } from 'Theme'
import { type CmsCardNavigationWidgetClientFieldsFragment } from 'types/graphql-generated'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'
import { CardNavigationCarousel } from './CardNavigationCarousel'

const cardNavigationWidgetSpacing = {
  [WIDGET_PLACEMENT.HERO]: {
    mt: {
      MOBILE: SPACE.PX_40,
      TABLET: SPACE.PX_80,
    },
    mb: {
      MOBILE: SPACE.PX_24,
      DESKTOP: SPACE.PX_48,
    },
  },
  [WIDGET_PLACEMENT.SECTION]: {
    mt: {
      MOBILE: SPACE.PX_24,
      TABLET: SPACE.PX_48,
    },
    mb: {
      MOBILE: SPACE.PX_24,
      DESKTOP: SPACE.PX_48,
    },
  },
}

const MAX_CAROUSEL_SLIDES = {
  TABLET: 4,
  DESKTOP: 6,
}

const StyledImage = styled(Image)`
  border-radius: ${RADIUS.PX_8};
`

export const CardNavigationWidget: React.FC<
  React.PWC<CmsCardNavigationWidgetClientFieldsFragment>
> = ({
  cardNavigationWidgetTitle: title,
  subtitle,
  cardNavigationWidgetPlacement: placement,
  cmsCardNavigationWidgetItems,
  cardNavigationWidgetButtonText: buttonText,
  cardNavigationWidgetButtonLink: buttonLink,
}) => (
  <section itemScope itemType={SCHEMA_TYPES.THING}>
    <meta
      itemProp={SCHEMA_PROPS.ALTERNATE_NAME}
      content={WIDGET_NAMES.CARD_NAVIGATION.name}
    />
    <Box
      mt={cardNavigationWidgetSpacing[placement].mt}
      mb={cardNavigationWidgetSpacing[placement].mb}
    >
      <Container mb={SPACE.PX_16}>
        <WidgetTitle
          placement={placement}
          textAlign="center"
          itemProp={SCHEMA_PROPS.NAME}
        >
          {title}
        </WidgetTitle>
        {subtitle && (
          <WidgetSubtitle textAlign="center">{subtitle}</WidgetSubtitle>
        )}
      </Container>
      {/* Mobile View */}
      <Flex
        flexDirection="column"
        m={SPACE.PX_15}
        display={{ MOBILE: 'flex', TABLET: 'none' }}
        itemScope
        itemType={SCHEMA_TYPES.ITEM_LIST}
      >
        {cmsCardNavigationWidgetItems.map(
          ({ title: itemTitle, attachment, cmsPage, id }, index) => {
            const isLast = index + 1 === cmsCardNavigationWidgetItems.length
            return (
              <Box key={id} itemScope itemType={SCHEMA_TYPES.LIST_ITEM}>
                <Link href={cmsPage.href} itemProp={SCHEMA_PROPS.URL}>
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    my={SPACE.PX_8}
                  >
                    <Flex alignItems="center">
                      <StyledImage
                        baseWidth={40}
                        aspectRatio={ASPECT_RATIO.CARD_NAVIGATION}
                        src={attachment.url}
                        alt={attachment.alt || itemTitle}
                        itemProp={SCHEMA_PROPS.IMAGE}
                      />
                      <H6
                        color={
                          COLOR_INTENT.WIDGETS.CARD_NAVIGATION.MOBILE_ITEM_TITLE
                        }
                        fontWeight={FONT_WEIGHT.NORMAL}
                        ml={SPACE.PX_12}
                        mb={0}
                        itemProp={SCHEMA_PROPS.NAME}
                      >
                        {itemTitle}
                      </H6>
                    </Flex>
                    <Icon.CarouselArrowRight />
                  </Flex>
                </Link>
                {!isLast && <Divider opacity={1} />}
                <meta
                  itemProp={SCHEMA_PROPS.POSITION}
                  content={String(index + 1)}
                />
              </Box>
            )
          }
        )}
      </Flex>
      {/* Tablet View */}
      <Box display={{ MOBILE: 'none', TABLET: 'block', DESKTOP: 'none' }}>
        <CardNavigationCarousel
          cmsCardNavigationWidgetItems={cmsCardNavigationWidgetItems}
          maxSlidesCount={MAX_CAROUSEL_SLIDES.TABLET}
        />
      </Box>
      {/* Desktop View */}
      <Box display={{ MOBILE: 'none', DESKTOP: 'block' }}>
        <CardNavigationCarousel
          cmsCardNavigationWidgetItems={cmsCardNavigationWidgetItems}
          maxSlidesCount={MAX_CAROUSEL_SLIDES.DESKTOP}
        />
      </Box>
      {buttonLink && buttonText && (
        <Flex width="100%" justifyContent="center" mt={SPACE.PX_16}>
          <StyledLink href={buttonLink}>{buttonText}</StyledLink>
        </Flex>
      )}
    </Box>
  </section>
)
