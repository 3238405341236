import React from 'react'
import Icon from 'components/Icon'
import { Grid } from 'components/Layout'
import { ROUTE } from 'constants/routes'
import { SPACE } from 'Theme'
import {
  ValuePropositionWidgetItem,
  type ValuePropositionWidgetItemProps,
} from './ValuePropositionWidgetItem'

const valuePropositionWidgetItems: ValuePropositionWidgetItemProps[] = [
  {
    title: 'Consult with a designer online',
    description: 'Browse Experts and schedule a video call',
    href: ROUTE.EXPERTS(),
    icon: Icon.Conversation,
  },
  {
    title: 'Shop the best in home',
    description: 'Discover exclusive products, trade-only brands, and more',
    href: ROUTE.SHOWROOM(),
    icon: Icon.Chair,
  },
  {
    title: 'Get inspired with stories',
    description: `Read free advice from the world's top interior designers`,
    href: ROUTE.BLOG_LIST(),
    icon: Icon.Lightbulb,
  },
]

export const ValuePropositionWidget: React.FC<React.PWC<unknown>> = () => (
  <Grid
    gridTemplateColumns={{
      MOBILE: '1fr',
      DESKTOP: 'repeat(3, 1fr)',
    }}
    gridGap={{
      MOBILE: SPACE.PX_8,
      DESKTOP: SPACE.PX_16,
    }}
    my={{
      MOBILE: SPACE.PX_24,
      DESKTOP: SPACE.PX_40,
    }}
  >
    {valuePropositionWidgetItems.map((item, index) => (
      <ValuePropositionWidgetItem key={index} {...item} />
    ))}
  </Grid>
)
