import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'components/Icon'
import { Flex } from 'components/Layout'
import { Loader, LOADER_SIZE } from 'components/Loader'
import NoData from 'components/NoData'
import { SPACE } from 'Theme'

export const NoProducts = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Flex justifyContent="center">
        <Loader size={LOADER_SIZE.L} />
      </Flex>
    )
  }
  return (
    <NoData
      mt={SPACE.PX_30}
      icon={Icon.Basket}
      header="No products in selected collection"
    />
  )
}

NoProducts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}
