import PropTypes from 'prop-types'
import { useState, useCallback, useRef } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import { Toast } from 'components/Toast'
import { ASPECT_RATIO } from './constants'
import { calculateOptimizedCrop } from './utils'

const IMAGE_VALID_TYPES = ['image/jpeg', 'image/jpg', 'image/png']

const defaultCrop = (aspectRatio) => ({
  unit: '%',
  width: 48,
  height: 48,
  ...(aspectRatio !== ASPECT_RATIO.FREE && {
    aspect: aspectRatio,
  }),
})

export const UploadImageProvider = ({ aspectRatio, children }) => {
  const fileInput = useRef(null)
  const imageRef = useRef(null)
  const [uploadedImage, setUploadedImage] = useState()
  const [uploadedBlob, setUploadedBlob] = useState(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [crop, setCrop] = useState(() => defaultCrop(aspectRatio))

  const onLoad = useCallback((image) => {
    imageRef.current = image
  }, [])

  const handleFileChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      setIsImageLoading(true)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          setUploadedBlob(files[0])
          setUploadedImage(reader.result)
          setIsImageLoading(false)
        },
        false
      )
      reader.readAsDataURL(files[0])
    }
  }

  const handleDrop = useCallback((event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file && IMAGE_VALID_TYPES.includes(file.type)) {
      setIsImageLoading(true)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          setUploadedBlob(file)
          setUploadedImage(reader.result)
          setIsImageLoading(false)
        },
        false
      )
      reader.readAsDataURL(file)
    } else {
      Toast.error('Wrong file type.')
    }
  }, [])

  const resetImage = useCallback(() => {
    setCrop(defaultCrop(aspectRatio))
    setUploadedImage(null)
  }, [aspectRatio])

  const saveImage = useCallback(
    async (onImageSaved) => {
      if (completedCrop?.width && completedCrop?.height && !isImageLoading) {
        setIsImageLoading(true)
        const image = imageRef.current
        await onImageSaved({
          blob: uploadedBlob,
          dataUrl: uploadedImage,
          crop: calculateOptimizedCrop({ image, crop: completedCrop }),
        })
        setIsImageLoading(false)
      }
    },
    [completedCrop, isImageLoading, uploadedBlob, uploadedImage]
  )
  return children({
    saveImage,
    resetImage,
    handleDrop,
    isImageLoading,
    uploadedImage,
    onLoad,
    crop,
    setCrop,
    setCompletedCrop,
    handleFileChange,
    fileInput,
  })
}

UploadImageProvider.defaultProps = {
  aspectRatio: ASPECT_RATIO.PROFILE,
}

UploadImageProvider.propTypes = {
  aspectRatio: PropTypes.oneOf(Object.values(ASPECT_RATIO)),
}
